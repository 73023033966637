import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Stack, Typography } from "@mui/material";
import ProjectsList from "../../components/ProjectsList";
import NewProjectComponent from "../../components/NewProjectComponent";
import { getGlobalProjects, getProjectDetails } from "../../api/project";
import { getProjectUsers } from "../../api/project";
import { getModelById } from "../../api/model";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CustomDialog from "../../utils/dialogbox";

function EditProjectPage(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});

  let location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(props?.loading || false);
  const [project, setProject] = useState({});
  const [projects, setProjects] = useState();
  const [defaultModel, setDefaultModel] = useState(null);
  const [users, setUsers] = useState([]);
  const { project_id } = useParams();

  const handleError = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setOnDialogClose(() => () => {
      navigate("/global_admin");
    });
  };

  const fetchProjectDetails = async () => {
    try {
      const projectDetailsResponse = await getProjectDetails(project_id);
      if (!projectDetailsResponse.success ) {
        handleError(
          "Error occured while getting project details. Please try again."
        );
      } else {
        setProject(projectDetailsResponse.data.project);
      }
      const modelResponse = await getModelById(
        projectDetailsResponse.data.project.defaultModel
      );
      if (!modelResponse.success) {
        setDefaultModel(null);
      } else {
        setDefaultModel(modelResponse.data);
      }
      const projectUsersResponse = await getProjectUsers(project_id);
      if (!projectUsersResponse.success) {
        handleError(
          "Error occured while getting project users. Please try again."
        );
      } else {
        const formattedUsers = projectUsersResponse.data
          .map((user) => ({
            _id: user.user._id,
            role: user.role,
            email: user.user.email,
            name: user.user.name,
          }))
          .filter((user) => user.role !== "creator");
        setUsers(formattedUsers);
      }
      const globalProjects = await getGlobalProjects(
        null,
        "time",
        "desc",
        1,
        2
      );
      if (globalProjects.success === false) {
        setProjects([]);
      } else {
        setProjects(globalProjects.data.projects);
      }
    } catch (err) {
      console.error("error: ", err);
      handleError("Error occured when getting projects. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProjectDetails();
  }, []);

  return (
    <>
      <Loader loading={loading}>
        {project && projects && !loading && (
          <Grid container spacing={2} p={4} pl={5} pr={5}>
            <Grid item md={3} xs={12}>
              <Stack spacing={2}>
                <Typography variant="title">Projects</Typography>
                <ProjectsList projects={projects} from="createProject" />
              </Stack>
            </Grid>

            <Grid item xs={12} md={9}>
              {location.state && (
                <NewProjectComponent
                  from={location.state.from}
                  createOrEdit="Edit"
                  projectName={project.projectName}
                  projectDescription={project.projectDescription}
                  topic={project.topic}
                  defaultModel={defaultModel}
                  addUser={users}
                />
              )} 
            </Grid>
          </Grid>
         )}
        <CustomDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
      </Loader>
    </>
  );
}

export default EditProjectPage;
