import React, { useState, useEffect,useMemo} from "react";
import {
  Typography,
  Avatar,
  IconButton,
  Stack,
  Tooltip,
  Button,
  Grid,
  Container,
  TextField,
  Drawer
} from "@mui/material";
import {
  getAllModels,
  deleteModel,
  addModel,
  updateModelById
} from "../../api/model";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SnackbarComponent from "../../components/SnackBar";
import AlertDialogBox from "../../components/AlertDailogBox";
import { useNavigate } from "react-router-dom";
import { TextFields } from "@mui/icons-material";

const Models = (props) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [modelInfo, setModelInfo] = useState({});
  const [modelError, setModelError] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [mode, setMode] = useState("edit");

  const navigate = useNavigate();

  //table state
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {     
      let params = await populateSearchParams()
      let res = await getAllModels();
      if(res.success) {
        setData(res.data ||[]);
        setRowCount(res?.data?.length || 0);
      } else {
        setSnackBarType('error');
        setSnackBarMessage("Error occured while fetching models");
        setShowSnackBar(true);
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while fetching models");
      setShowSnackBar(true);
    }
    setIsLoading(false);
    setIsRefetching(false);
  };


  
  useEffect(() => {
    fetchData();
  }, []);
 
  const populateSearchParams = async () => {    
    let params = {limit: pagination.pageSize, page: pagination.pageIndex+1, };
    await columnFilters.forEach(filter => {
        const { id, value } = filter;
        if(value) {
          params[id] = value;
        }        
    });
    return params;
  }

  const onDeleteModel = async () => {
    try {
      setIsLoading(true);
      if(deleteId) {
        let response = await deleteModel(deleteId);
        if(response.success) {     
          fetchData();       
          setDeleteDialogBox(false);     
          setSnackBarMessage("Model has been deleted successfully!" );
          setSnackBarType('success');
          setShowSnackBar(true);   
        } else {
          setSnackBarType('error');
          setSnackBarMessage("Error occured while deleting model. Please try again later.");
          setShowSnackBar(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while deleting model. Please try again later.");
      setShowSnackBar(true);
      setIsLoading(false);
    }
  };

  const handleEditModel = async(row) => {
    setModelInfo(row?.original);
    setModelError({});
    setShowDrawer(true);
    setMode("edit");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'modelName',
        header: 'Name',
        enableGlobalFilter: true,
        enableEditing: false, 
        Cell: ({renderedCellValue}) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: 'modelDescription',
        header: 'Description',
        enableGlobalFilter: true,
        enableEditing: false, 
        Cell: ({renderedCellValue}) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        enableColumnFilter: false,
        enableEditing: false,
        Cell: ({ renderedCellValue,row}) => (
         
          <Tooltip
          title={`Created at :- ${formatDate(row?.original?.createdAt)}`}
          placement="left"
        >
          
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <Avatar
                  src={renderedCellValue?.picture || "/broken-image.jpg"}
                  alt={renderedCellValue?.name}
                  aria-label="project"
                />
              <Typography variant="body1"> {renderedCellValue?.name}</Typography>
          </Stack>
        </Tooltip>)
      },
      {
        accessorKey: 'createdBy',
        header: 'Actions',
        enableEditing: false,         
        enableColumnFilter: false,
        Cell: ({renderedCellValue,row}) => (
         
              <Stack direction="row" gap={2}>

              <Tooltip title="Edit">
                <IconButton  onClick={ () => handleEditModel(row)} >
                  <EditIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="error" onClick={() => handleDeleteModel(row)} >
                  <DeleteIcon sx={{fontSize: { xs: "medium", sm: "large" } }}/>
                </IconButton>
              </Tooltip>
            </Stack>
        ),
      },
    ],
    [],
  );


const handleDeleteModel = async (row) => {
    setDeleteDialogBox(true);
    setDeleteId(row.id);
};
 
  const closeDeleteAlert = ()=>{
    setDeleteDialogBox(false);
  }

  const handleCloseSnackBar = () =>{
    setShowSnackBar(false);
    setSnackBarMessage('');
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true, 
    enableColumnActions: false,
    enableSorting: false,
    enableHiding:false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    positionActionsColumn: 'last',
    rowsPerPageOptions: [10, 20,50,100],
    icons: {
        SaveIcon: () => <CheckCircleIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />,
        FilterListIcon: () => <SearchIcon />,
        FilterListOffIcon : () => <SearchOffIcon />,
        CancelIcon: () => <CancelIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />
    }, 
    getRowId: (row) => row._id,
    initialState: { showColumnFilters: false },
    manualSorting: true,
    muiTableContainerProps: {
        sx: {
            maxHeight: '70vh',
        },
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    renderTopToolbarCustomActions: () => (      
      <Button
        variant="contained"
        onClick={handleCreateModel}
      >
        Add New Model
      </Button>
    ),
    rowCount,
    state: {
      columnFilters,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
    },
  });


  const handleCreateModel = () => {
    setModelInfo({});
    setModelError({});
    setMode("create");
    setShowDrawer(true);
  };

  const handleSave = async () => {
  
      if(mode == "edit") {
        await editModel();
      } 
      if (mode == "create") {
        await addModelData();
      }
    

  }

  const addModelData = async () => {

    try {
      setIsLoading(true);

      let validationError = {}
      if(!modelInfo.modelName || modelInfo.modelName?.trim() === "") {
        validationError.modelNameError = "Model Name is required"
      }
      if(!modelInfo.modelDescription || modelInfo.modelDescription?.trim() === "") {
        validationError.modelDescriptionError = "Model Description is required"
      }
      if(!modelInfo.url || modelInfo.url?.trim() === "") {
        validationError.urlError = "URL is required"
      }
      if(!modelInfo.apiKeys || modelInfo.apiKeys?.trim() === "") {
        validationError.urlError = "API Key is required"
      }
      if(Object.keys(validationError).length > 1) {
        setModelError(validationError);
        setIsLoading(false);
        return
      }
      
      let modelData = {
        modelName: modelInfo.modelName?.trim(),
        modelDescription : modelInfo.modelDescription?.trim(),
        url: modelInfo.url?.trim(),
        apiKeys : modelInfo.apiKeys?.trim()
      }

        let response = await addModel(modelData)
        if(response.success) {  
          if(response.exists) {            
            setModelError({ modelDescriptionError : "Model already exists"})
            setIsLoading(false);
          } else {
            fetchData();       
            setShowDrawer(false);     
            setSnackBarMessage("Model has been added successfully!" );
            setSnackBarType('success');
            setShowSnackBar(true); 
          }  
        } else {
          setSnackBarType('error');
          setSnackBarMessage("Error occured while adding model. Please try again later.");
          setShowSnackBar(true);
          setIsLoading(false);
        }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while adding model. Please try again later.");
      setShowSnackBar(true);
      setIsLoading(false);
    }
  }
  const editModel = async () => {
    try {
      setIsLoading(true);

      let modelId = modelInfo._id;
      let modelData = {
        modelName: modelInfo.modelName?.trim(),
        modelDescription : modelInfo.modelDescription?.trim(),
        url: modelInfo.url?.trim(),
        apiKeys : modelInfo.apiKeys?.trim()
      }

      let validationError = {}
      if(!modelInfo.modelName || modelInfo.modelName?.trim() == "") {
        validationError.modelNameError = "Model Name is required"
      }
      if(!modelInfo.modelDescription || modelInfo.modelDescription?.trim() == "") {
        validationError.modelDescriptionError = "Model Description is required"
      }
      if(!modelInfo.url || modelInfo.url?.trim() === "") {
        validationError.urlError = "URL is required"
      }
      if(!modelInfo.apiKeys || modelInfo.apiKeys?.trim() === "") {
        validationError.urlError = "API Key is required"
      }
      if(Object.keys(validationError).length > 0) {
        setModelError(validationError);
        setIsLoading(false);
        return
      }

      if(modelId) {
        let response = await updateModelById(modelId,modelData)
        if(response.success) {  
          if(response.exists) {            
            setModelError({ modelDescriptionError : "Model already exists"})
            setIsLoading(false);
          } else {
            fetchData();       
            setShowDrawer(false);     
            setSnackBarMessage("Model has been edited successfully!" );
            setSnackBarType('success');
            setShowSnackBar(true); 
          }  
        } else {
          setSnackBarType('error');
          setSnackBarMessage("Error occured while editing model. Please try again later.");
          setShowSnackBar(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while editing model. Please try again later.");
      setShowSnackBar(true);
      setIsLoading(false);
    }
  }

  const getAlertBody = () => {
    return (
      <>
      <Typography variant="body1">Are you sure you want to delete this model?</Typography>
          
      </>
    );
  }

  return (
    <>
      <MaterialReactTable table={table} />
      <SnackbarComponent
        severity={snackBarType}
        variant={"filled"}
        message={snackBarMessage}
        open={showSnackBar}
        handleClose={handleCloseSnackBar}
      />
      <AlertDialogBox
        openDialog={deleteDialogBox}
        title={"Delete Model"}
        body={getAlertBody()}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        handleConfirm={onDeleteModel}
        type={"info"}
        handleCancel={closeDeleteAlert}
      />
      <Drawer open={showDrawer} 
      anchor={"right"}>
        <Container sx={{ minWidth: "30vw" }} >
          <Stack spacing={2} p={2}>

          <Typography marginTop={"1%"} variant="title">
            {mode =="create" ? "Add Model" : "Edit Model"}
          </Typography>
          <Grid item xs={12} >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4} sm={6} xs={12}>
                <Typography variant="statsTitle">
                  Model Name*
                </Typography>
              </Grid>
              <Grid item md={8} sm={6} xs={12}>
                <TextField
                  value={modelInfo?.modelName || ""}
                  error={!!modelError.modelNameError}
                  helperText={modelError.modelNameError}
                  onChange={(e) => setModelInfo({...modelInfo,modelName:e.target.value})}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4} sm={6} xs={12}>
                <Typography variant="statsTitle">
                  Model Description*
                </Typography>
              </Grid>
              <Grid item md={8} sm={6} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  value={modelInfo?.modelDescription || ""}
                  error={!!modelError.modelDescriptionError}
                  helperText={modelError.modelDescriptionError}
                  onChange={(e) => setModelInfo({...modelInfo,modelDescription:e.target.value})}
                  // onChange={(e) => setModelDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4} sm={6} xs={12}>
                <Typography variant="statsTitle">
                  API Keys*
                </Typography>
              </Grid>
              <Grid item md={8} sm={6} xs={12}>
                <TextField
                  fullWidth
                  value={modelInfo?.apiKeys || ""}
                  error={!!modelError.apiKeysError}
                  helperText={modelError.apiKeysError}
                  onChange={(e) => setModelInfo({...modelInfo,apiKeys:e.target.value})}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* URL */}
          <Grid item xs={12} >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={4} sm={6} xs={12}>
                <Typography variant="statsTitle">
                  URL*
                </Typography>
              </Grid>
              <Grid item md={8} sm={6} xs={12}>
                <TextField
                  fullWidth
                  value={modelInfo?.url || ""}
                  error={!!modelError.urlError}
                  helperText={modelError.urlError}
                  onChange={(e) => setModelInfo({...modelInfo,url:e.target.value})}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent={"space-between"}>

              <Grid item >

              <Button
              variant="contained"
              onClick={() => {
                setModelInfo({});
                setShowDrawer(false);
                setModelError({})
              }}
              disabled={isLoading}
            >
              Cancel
            </Button>
              </Grid>
              <Grid item>

            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isLoading}
            >
              Save
            </Button>
              </Grid>
          </Grid>
          </Stack>
        </Container>
      </Drawer>
    </>
  );


}
export default Models;
