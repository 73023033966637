import React from "react";
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Tooltip,
 
} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { logOutUser } from "../../app/userSlice";
import styles from "./styles.module.css";
import ProjectSearchBar from "../../components/ProjectSearchBar";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import { useMsal } from "@azure/msal-react";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { alertsCount, accessRequestCount, sharedPromptCount } = useSelector(state => state.notifications);
  

  const handleSignIn = () => {
    navigate("/");
  };
  const totalNotificationsCount = alertsCount + accessRequestCount + sharedPromptCount;

  const { instance } = useMsal();
  const handleLogout = () => {
    if (user.loginMethod === "MicroSoft") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      window.location.href = "/";
    }
    dispatch(logOutUser());
  };

  const location = useLocation();

  const isActive = (path) =>
    location.pathname === path
      ? `${styles.link} ${styles.active}`
      : styles.link;

  const handleClickGlobalAdmin = () => {
    navigate("/global_admin");
  };

  const handleClickNotifications = () => {
    navigate("/notifications");
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          padding: { md: "0.5rem 3rem" },
          background: "#597697 !important",
        }}
      >
        {isAuthenticated ? (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: { xs: "space-between", sm: "flex-start" },
            }}
          >
            {/* Left section: Search Bar */}
            <IconButton
              edge="start"
              color="inherit"
              sx={{
                mx: "auto", // Centers the logo on mobile
                display: "block", // Always visible
              }}
            >
              <img
                src="/logo.png "
                width="160"
                height="60"
                className="d-inline-block align-top"
                alt="Target Arc Logo"
                onClick={() => {
                  navigate("/home");
                }}
              />
            </IconButton>

            {/* Middle Section: Search Bar */}

            <Box
              flexGrow={1}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}
            >
              <ProjectSearchBar />
            </Box>

            {/* Right Section: Home, Projects, Sign In/Log Out */}
            <Box
              sx={{
                display: { xs: "flex", sm: "flex" },
              }}
            >
              <Button
                color="inherit"
                sx={{
                  textTransform: "none !important",
                  fontFamily: "Myriad Pro ",
                  fontSize: "20px",
                  mr: 2,
                }}
                className={isActive("/home")}
                onClick={() => {
                  navigate("/home");
                }}
              >
                Home
              </Button>
              <Button
                color="inherit"
                sx={{
                  textTransform: "none !important",
                  fontFamily: "Myriad Pro",
                  fontSize: "20px",
                  mr: 2,
                }}
                className={isActive("/projects")}
                onClick={() => {
                  navigate("/projects");
                }}
              >
                Projects
              </Button>
              {user.role === "admin" && (
                <Tooltip title="Global Admin Console">
                  <IconButton
                    color="inherit"
                    sx={{ mr: 2 }}
                    onClick={handleClickGlobalAdmin}
                    className={isActive("/global_admin")}
                  >
                    <ManageAccountsIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Notifications">
                <IconButton
                key={totalNotificationsCount}
                  color="inherit"
                  sx={{ mr: 2 }}
                  onClick={handleClickNotifications}
                  className={isActive("/notifications")}
                >
                  <Badge badgeContent={totalNotificationsCount} color="error">
                    <NotificationsActiveIcon sx={{ color: "white" }} />
                  </Badge>
                </IconButton>
              </Tooltip>

              {!isAuthenticated ? (
                <Button color="inherit" onClick={handleSignIn}>
                  <AccountCircleIcon className={styles.icon_login_text} />
                  <div className={styles.icon_login_text}>
                    <Typography sx={{ fontFamily: "Myriad Pro" }}>
                      Sign In
                    </Typography>
                  </div>
                </Button>
              ) : (
                <>
                  {user && (
                    <Button
                      color="inherit"
                      onClick={handleLogout}
                      id={styles.buttonTransition}
                      sx={{ fontFamily: "Myriad Pro", textTransform: "unset" }}
                    >
                      {user.picture !== null && user.picture !== "" ? (
                        <Avatar
                          src={user.picture}
                          alt={user.name}
                          sx={{ width: 25, height: 25 }}
                        />
                      ) : (
                        <Avatar
                          alt={user.name}
                          src="/broken-image.jpg"
                          sx={{ width: 25, height: 25, fontSize: '0.9rem' }}
                        />
                      )}
                      &nbsp;
                      <Typography className={styles.icon_logout_text} >Logout</Typography>
                    </Button>
                  )}
                </>
              )}
            </Box>
          </Toolbar>
        ) : (
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between", // This will place items on opposite ends
            }}
          >
            {/* Left section: Logo */}
            <IconButton
              edge="start"
              color="inherit"
              sx={{
                // Removed the centering style
                display: "block", // Keeps it visible
              }}
            >
              <img
                src="/logo.png"
                width="160"
                height="60"
                className="d-inline-block align-top"
                alt="Target Arc Logo"
              />
            </IconButton>

            {/* Right section: Sign In Text */}
            <Typography variant="h5" sx={{ fontFamily: "Myriad Pro" }}>
              Prompt Manager
            </Typography>
          </Toolbar>
        )}
      </AppBar>
    </>
  );
}
