import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import promptSlice from "./promptSlice";
import notificationSlice from "./notificationSlice";
// import errorReducer from "./errorSlice";

export default configureStore({
  reducer: {
    user: userSlice,
    prompts: promptSlice,
    notifications: notificationSlice,
    // project: projectSlice,
    // error: errorReducer,
  },
  devTools: true,
});
