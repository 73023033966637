import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PromptCard from "../../components/PromptCard";
import Comment from "../../components/Comment";
import RunPromptModal from "../../components/RunPromptModal";
import EvaluatePromptPanel from "../../components/EvaluatePane";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProjectDetails } from "../../api/project";
import { getAllModelsForUser } from "../../api/model";
import { fetchPromptById, updatePrompt } from "../../api/prompt";
import Loader from "../../components/Loader";
import CustomDialog from "../../utils/dialogbox";

function PromptDetail() {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, []);

  const [openRunPanel, setOpenRunPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openEvaluatePanel, setOpenEvaluatePanel] = useState(false);
  const [models, setModels] = useState([]);
  const [promptDetails, setPromptDetails] = useState({});
  const { project_id, prompt_id } = useParams();
  const [promptAttributes, setPromptAttributes] = useState({});
  const [projectDetails, setProjectDetails] = useState({});
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => { });

  const handleApply = async (promptContent, contextStrength, contextReason, sentiment_) => {
    try {
      const updatedPromptData = {
        promptTitle: promptDetails.data.promptTitle,
        promptContent: promptContent,
        context: {
          strength: contextStrength,
          reason: contextReason
        },
        sentiment: sentiment_,
      };
      updatedPromptData.createdBy = user.id;
      await updatePrompt(project_id, prompt_id, updatedPromptData);

      setPromptDetails(prevPromptDetails => ({
        ...prevPromptDetails,
        data: {
          ...prevPromptDetails.data,
          promptContent: promptContent,
          context: {
            strength: contextStrength,
            reason: contextReason
          },
          sentiment: sentiment_,
        }
      }));
      setIsLoading(true);
      populateData();
    } catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error while applying prompt, Please try again!")
      setDialogAlertOpen(true);
      console.error("Error saving changes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const populateData = async () => {
    try {
      const model = await getAllModelsForUser();
      setModels(model.data);
      const prompts = await fetchPromptById(project_id, prompt_id);
      setPromptDetails(prompts);
      const projectData = await getProjectDetails(project_id);
      setProjectDetails(projectData.data);
    } catch (error) {
      setDialogMessage("Error while fetching Models, Please try again!")
      setDialogAlertOpen(true);
      console.error("error fetching the models", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    populateData();
  }, [project_id, prompt_id]);

  const handleRunPanelClose = () => {
    setOpenRunPanel(false);
  };

  const handleEvaluatePanelClose = () => {
    setOpenEvaluatePanel(false);
  };

  const handleRunPanelOpen = async () => {
    setOpenEvaluatePanel(false);
    setOpenRunPanel((prevState) => !prevState);
  };

  const handleEvaluatePanelOpen = () => {
    setOpenRunPanel(false);
    setOpenEvaluatePanel((prevState) => !prevState);
    setPromptAttributes({});
  };


  return (
    <Grid
      container
      columnSpacing={4}
      p={4}
      pl={5}
      pr={5}
      alignItems={"flex-start"}
    >
      <Loader loading={isLoading}>
        <>
          <Grid item sm={12} md={6} direction={"column"}>
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate(`/${project_id}/prompts/${prompt_id}/versions`);
                  }}
                >
                  Version History
                </Button>
              </Stack>
              <PromptCard
                openEvaluatePanel={handleEvaluatePanelOpen}
                closeEvaluatePanel={handleEvaluatePanelClose}
                openRunPanel={handleRunPanelOpen}
                closeRunPanel={handleRunPanelClose}
                promptAttributes={promptAttributes}
                prompts={promptDetails.data}
                prompt_id={prompt_id}
                project_id={project_id}
                project={projectDetails.project}
              />

              {!!promptDetails?.data?.version && (
                <Comment from="promptDetailPage" promptId={prompt_id} versionNumber={promptDetails.data.version} />
              )}
            </Stack>
          </Grid>
          <Grid item sm={12} md={6} direction={"column"}>
            {openRunPanel && (
              <RunPromptModal
                defaultModel={projectDetails?.project?.defaultModel || ""}
                openRunPanel={openRunPanel}
                promptContent={promptDetails.data.promptContent}
                handleRunPanelClose={handleRunPanelClose}
                allModels={models}
              />
            )}
            {openEvaluatePanel && (
              <EvaluatePromptPanel
                defaultModel={projectDetails?.project?.defaultModel || ""}
                onApply={handleApply}
                promptContent={promptDetails?.data?.promptContent}
                handleEvaluatePanelClose={handleEvaluatePanelClose}
                PromptAttributes={setPromptAttributes}
                allModels={models}
              />
            )}
          </Grid>
          <CustomDialog
            open={dialogAlertOpen}
            onClose={() => {
              setDialogAlertOpen(false);
              onDialogClose();
            }}
            title="Prompt Manager"
            onDialogClose={onDialogClose}
          >
            {dialogMessage}
          </CustomDialog>
        </>
      </Loader>
    </Grid>
  );
}

export default PromptDetail;
