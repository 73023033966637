import React from "react";
import { Typography, Avatar, Stack } from "@mui/material";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

const InfoCard = ({ user, role }) => {
  if (!user) return <React.Fragment></React.Fragment>;
  return (
    <React.Fragment>
      <Stack direction="row" spacing={2} alignItems={"center"}>
        <Avatar
          src={user?.picture || "/broken-image.jpg"}
          alt={user?.name}
          aria-label="project"
        />
        <Typography variant="body1Bold"> {user.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <MailOutlineOutlinedIcon style={{ color: "#2196F3" }} />
        <a
          href={`mailto:${user.email}`}
          style={{
            textDecoration: "none",
          }}
        >
          <Typography variant="body2Bold" color="#2196F3">
            {user.email}
          </Typography>
        </a>
      </Stack>

      {role && <Typography variant="body1Bold">Role: {role}</Typography>}
    </React.Fragment>
  );
};

export default InfoCard;
