import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Chip,
  Button
} from "@mui/material";
import React, { useState,useEffect} from "react";
import { NAME_MAX_CHAR_LEN } from "../../utils/constants";
import { updatePrompt } from "../../api/prompt";
import Loader from "../Loader"; 

const EditPromptDialog = (props) => {
  const { project_id, prompt_id } = props;
  const [prompt, setPrompt] = useState({});
  const [promptTitleError, setPromptTitleError] = useState("");
  const [promptDescriptionError, setPromptDescriptionError] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [loading,setLoading] = useState(false);

  useEffect(()=>{
    if(props.prompt){
        setPrompt(props.prompt)
    }
  },[])

  const handleTagDelete = (tagToDelete) => () => {
    const updatedTags = prompt?.promptTag?.filter(
      (tag) => tag !== tagToDelete
    );
    setPrompt({
      ...prompt,
      promptTag: updatedTags,
    });
  };

  const handleTagInputKeyDown = (event) => {
    if (event.key === "Enter" && tagInput) {
      let tags = new Set(prompt?.promptTag || []);
      tags.add(tagInput);
      setTagInput("");
      setPrompt({
        ...prompt,
        promptTag: Array.from(tags),
      });
    }
  };

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };
  
  const onSave =  async () => {
    try{
        setLoading(true);
        if(props?.prompt?.promptTitle !== prompt.promptTitle || props?.prompt?.promptContent !== prompt.promptContent ){
          prompt.context.strength =""
          prompt.context.reason=""
          prompt.sentiment=""
        } else {
          prompt.context_strength =prompt?.context?.strength
          prompt.context_reason=prompt?.context?.reason
          prompt.sentiment=prompt?.sentiment
        }
        let promptRequest =  {
          promptTitle: prompt.promptTitle ,
          promptContent : prompt.promptContent,
          promptTag: prompt.promptTag,
          context: {
            strength: prompt.context?.strength || "",
            reason: prompt.context?.reason || "",
          },
          sentiment: prompt.sentiment || "",
        }
        if(project_id && prompt_id) {
            let response = await updatePrompt(project_id,prompt_id, promptRequest)
            if(response?.duplicate) {
              setPromptTitleError("A prompt with the same title already exists in the project");
              setPromptDescriptionError("A prompt with the same prompt already exists in the project");
                return
            } else if (response?.titleExists) {
              setPromptTitleError("A prompt with the same title already exists in the project");
                return
            } else if (response?.contentExists) {
              setPromptDescriptionError("A prompt with the same prompt already exists in the project");
                return
            }
              else {
                if(props.onSave) {
                    props.onSave(response?.data?.updatedPrompt || {});
                }
        
            }
        }
        

    }catch(err) {
                resetState() // TODO:: Add Error Message
    }finally{

        setLoading(false);
    }
  }
  const closeDialog = () => {
    if(props.onClose) {
        props.onClose()
    }
    resetState();
  }
  const resetState =() => {
    setPrompt(props.prompt);
    setPromptTitleError("");
    setPromptDescriptionError("");
    setTagInput("");
    setLoading(false)
  }
  return (
    <>
      <Dialog open={props?.open || false} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack direction="column">
            <Typography variant="h6">Edit Prompt</Typography>
            <Typography variant="body2">
              Note: Title and prompt change will create a new version for this
              prompt
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
        <Loader loading={loading} >
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography variant="body1">
                Prompt Title <span style={{ color: "#595454" }}>*</span>
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                required
                value={prompt.promptTitle}
                onChange={(e) => {
                  if(promptTitleError) {
                    setPromptTitleError("")
                  }
                  setPrompt({
                    ...prompt,
                    promptTitle: e.target.value,
                  })
                }
                }
                error={!!promptTitleError}
                inputProps={{ maxLength: NAME_MAX_CHAR_LEN }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`${
                      prompt?.promptTitle?.length || 0
                    }/${NAME_MAX_CHAR_LEN}`}</InputAdornment>
                  ),
                }}
                helperText={promptTitleError}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body1">
                Prompt <span style={{ color: "#595454" }}>*</span>
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                multiline
                value={prompt.promptContent}
                rows={7}
                rowsMax={10}
                required
                onChange={(e) => {
                
                if(promptDescriptionError) {
                    setPromptDescriptionError("")
                    }
                  setPrompt({
                    ...prompt,
                    promptContent: e.target.value,
                  })
                }}
                error={!!promptDescriptionError}
                helperText={promptDescriptionError}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body1">Add Tags</Typography>

              <TextField
                label="#"
                variant="outlined"
                fullWidth
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleTagInputKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Press Enter to add
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container columnGap={2}>
                {prompt?.promptTag?.map((tag, index) => (
                  <Grid xs="auto" item key={index}>
                    <Chip label={tag} onDelete={handleTagDelete(tag)} />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
          </Loader>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto">
              <Button
                onClick={closeDialog}
                disabled={loading}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={onSave}
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditPromptDialog;
