import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/users"


// fetch users
export const fetchUsers = async (searchParams) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/`,{params:searchParams});
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// fetch users list
export const fetchUserList = async (searchText, limit) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/userList`, { params: { searchText, limit } });
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// fetch suggestions
export const fetchUserSuggesstions = async (userName) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/suggestions`, { params: { userName } });
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

//fetch User by id
export const fetchUserById = async (userId) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/${userId}`);
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};
// bulk update users
export const bulkUpdateUsers = async (requestData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/`, requestData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else if (status == responseConstants.NOT_FOUND) {
          return {data,success: false,error: data?.message}
        }else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// update user by id

export const updateUserById = async (userId, updatedData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${userId}`,  updatedData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else if (status == responseConstants.NOT_FOUND) {
          return { data,success: false,error: data?.message}
        }else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// create user
export const createUser = async (userData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/`, userData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,exists: false,success: true}
        } else if( status == responseConstants.ALREADY_EXIST) {
          return { data:data.data,exists:true,error: data?.message, success: true}
        } else {
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

//delete user
export const deleteUser = async (userIds) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/delete`,userIds);
        let status = response?.data?.code;
        let data = response?.data || {};
        if([responseConstants.OK,responseConstants.NOT_FOUND].includes(status) ) {
          return {data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};





