import React, { useEffect, useState } from 'react';

import { loginRequest } from './authConfig';
import { SignInButton } from './SignInButton';
import { callMsGraph } from './graph';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { setCurrentUser,logOutUser } from "../../app/userSlice";
import { useDispatch  } from "react-redux";
import { useNavigate} from "react-router-dom";
import { signIn } from '../../api/auth';

const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchProfile =  async () => {
        // Silently acquires an access token which is then attached to a request for MS Graph data
      try{
        let msResponse =  await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
        });
        let graphDataResponse = await callMsGraph(msResponse.accessToken);
        graphDataResponse.msAccessToken = msResponse.accessToken
        setGraphData(graphDataResponse);
      } catch(err) {
        dispatch(logOutUser());
      }
    }


    const loginUser = async (graphData) => {
        try{

            const { userPrincipalName: email, displayName: name,msAccessToken } = graphData
            let userInfo = await signIn(email,msAccessToken)
                if(userInfo.isAuthorised) {
                   await dispatch(setCurrentUser(userInfo.data))
                    navigate("/home");
                } else {
                    navigate("/?requestAccess=true");
                }
        }catch(err) {

            console.log(err)
            dispatch(logOutUser());
        }
    }

    useEffect(() => {
        fetchProfile();
    }, []);
    
    useEffect(() => {
        if (graphData) {
            loginUser(graphData)
        }
    }, [graphData])

    return (
        <>
           
        </>
    );
};


const MicrosoftLogIn = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <SignInButton />
            </UnauthenticatedTemplate>
        </div>
    );
};

export default MicrosoftLogIn;
