import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Typography } from "@mui/material";
import styles from "./style.module.css";

export const SignInButton = () => {

  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  
  return (
    <div className={styles.microsoft_container}>
      <button onClick={() => handleLogin("redirect")} className={styles.microsoft_button}>
      <img
          src="/logo3.png"
          alt="Microsoft Logo"
          className={styles.microsoft_logo}
        />
        <div className={styles.microsoft_text}><Typography sx={{ fontSize: "18px" }}>Continue with TargetArc</Typography></div>
      </button>

    </div>
  );
};