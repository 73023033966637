import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Button,
  InputAdornment,
  Container,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProjectDetails,
  deleteProjects,
  updateProject,
} from "../../api/project";

import CustomDialog from "../../utils/dialogbox";
import {
  NAME_MAX_CHAR_LEN,
  DESCRIPTION_MAX_CHAR_LEN,
} from "../../utils/constants";
import { getAllModelsForUser } from "../../api/model";
import Loader from "../../components/Loader";
import "./style.css";

import TopicAutoComplete from "../../components/TopicAutoComplete";
import AlertDialogBox from "../../components/AlertDailogBox";

function ProjectComponent() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});
  const { project_id } = useParams();
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [projectNameError, setProjectNameError] = useState("");
  const [projectDescriptionError, setProjectDescriptionError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [defaultModelError, setDefaultModelError] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [defaultModel, setDefaultModel] = useState("");
  const [topic, setTopic] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const openDeleteConfirmationDialog = () => {
    setOpenDeleteDialog(true);
  };
  const closeDeleteConfirmationDialog = () => {
    setOpenDeleteDialog(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllModels = async () => {
      setLoading(true);
      try {
        const response = await getAllModelsForUser();
        if (response.success) {
          setModels(response.data);
        } else {
          setModels([]);
        }
      } catch (error) {
        console.error("Error fetching the models", error);
        setDialogMessage("Error occured when fetching the models. Please try again. ");
        setDialogOpen(true);
        setOnDialogClose(() => () => {
          navigate(-1);
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAllModels();
  }, []);

  useEffect(() => {
    const fetchProjectAndTopics = async () => {
      setLoading(true);
      try {
        const response = await getProjectDetails(project_id);
        if (response.success) {
          const { project } = response.data;
          setProject(project);
          setProjectName(project.projectName);
          setProjectDescription(project.projectDescription);
          setDefaultModel(project.defaultModel);
          setTopic(project.topic);
        } else {
          setDialogMessage(
            "Error Occurred While Fetching Project Details, Please Try Again."
          );
          setDialogOpen(true);
          setOnDialogClose(() => () => {
            navigate("/home");
          });
        }
      } catch (error) {
        setDialogMessage(
          "Error Occurred While Fetching Project Details, Please Try Again."
        );
        setDialogOpen(true);
        setOnDialogClose(() => () => {
          navigate("/home");
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProjectAndTopics();
  }, []);

  const checkValid = () => {
    let isValid = true;
    if (!projectName) {
      setProjectNameError("Please enter Project Name.");
      isValid = false;
    } else {
      setProjectNameError("");
    }
    if (!projectDescription) {
      setProjectDescriptionError("Please enter Project Description.");
      isValid = false;
    } else {
      setProjectDescriptionError("");
    }
    if (!topic || topic?.length < 1) {
      setTopicError("Please select Topic.");
      isValid = false;
    } else {
      setTopicError("");
    }
    if (!defaultModel) {
      setDefaultModelError("Please select default Model.");
      isValid = false;
    } else {
      setDefaultModelError("");
    }
    return isValid;
  };

  const handleProjectNameChange = (event) => {
    const newValue = event.target.value;
    setProjectName(newValue);
    if (!newValue) {
      setProjectNameError("Project name is required");
    } else {
      setProjectNameError("");
    }
  };

  const handleProjectDescriptionChange = (event) => {
    const newValue = event.target.value;
    setProjectDescription(newValue);
    if (!newValue) {
      setProjectDescriptionError("Project description is required");
    } else {
      setProjectDescriptionError("");
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteProjects([project_id]);
      closeDeleteConfirmationDialog();
      setDialogMessage("Project Have Been Deleted Successfully!");
      setDialogOpen(true);
      setOnDialogClose(() => () => navigate("/projects"));
    } catch (error) {
      console.error("Error deleting project:", error);
      closeDeleteConfirmationDialog();
      setDialogMessage(
        "Error Occurred While Deleting The Project, Please Try Again."
      );
      setDialogOpen(true);
      setOnDialogClose(() => () => {});
    } finally {
      setLoading(false);
    }
  };

  const handleClickApplyChanges = async () => {
    setLoading(true);
    try {
      let isValid = checkValid();
      if (!isValid) return;
      const projectData = {
        projectName,
        projectDescription,
        defaultModel,
        topic: topic?.map((x) => x?._id),
        users: project.users,
      };
      const response = await updateProject(project_id, projectData);
      if (response.exists) {
        setProjectNameError("Project with the same name already exists.");
      } else {
        setDialogMessage(
          "Project Detail Changes Have Been Saved Successfully!"
        );
        setDialogOpen(true);
        setOnDialogClose(() => () => {});
      }
    } catch (error) {
      console.error("Error updating project:", error);
      setDialogMessage(
        "Error Occurred While Updating The Project, Please Try Again."
      );
      setDialogOpen(true);
      setOnDialogClose(() => () => {});
    } finally {
      setLoading(false);
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Loader loading={loading}>
        <Grid container spacing={2} xs={12}>
          <Grid container item xs={12}>
            <Grid item xs={12} className="row-style">
              <Container>
                <Grid
                  item
                  container
                  xs={12}
                  p={2}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item container sm={4} xs={12}>
                    <Grid item>
                      <Typography variant="statsTitle">
                        Project Title{" "}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item sm={8} xs={12}>
                    <TextField
                      fullWidth
                      value={projectName}
                      inputProps={{ maxLength: NAME_MAX_CHAR_LEN }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{`${projectName.length}/${NAME_MAX_CHAR_LEN}`}</InputAdornment>
                        ),
                      }}
                      onChange={handleProjectNameChange}
                      required
                      error={!!projectNameError}
                      helperText={projectNameError}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid
                  item
                  container
                  xs={12}
                  p={2}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item container sm={4} xs={12}>
                    <Grid item>
                      <Typography variant="statsTitle">
                        Project Description
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item sm={8} xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      value={projectDescription}
                      onChange={handleProjectDescriptionChange}
                      inputProps={{ maxLength: DESCRIPTION_MAX_CHAR_LEN }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className="char-count">{`${projectDescription.length}/${DESCRIPTION_MAX_CHAR_LEN}`}</InputAdornment>
                        ),
                      }}
                      required
                      error={!!projectDescriptionError}
                      helperText={projectDescriptionError}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12} className="row-style">
              <Container>
                <Grid
                  item
                  container
                  xs={12}
                  p={2}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item container sm={4} xs={12}>
                    <Grid item>
                      <Typography variant="statsTitle">
                        Default Model
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item sm={8} xs={12}>
                    <Autocomplete
                      options={models}
                      getOptionLabel={(option) => option.modelDescription || ""}
                      value={
                        models.find((model) => model._id === defaultModel) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setDefaultModel(newValue?._id || "");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option._id === value._id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!defaultModelError}
                          helperText={defaultModelError}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container>
                <Grid
                  item
                  container
                  xs={12}
                  p={2}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item container sm={4} xs={12}>
                    <Grid item>
                      <Typography variant="statsTitle">Topic</Typography>
                    </Grid>
                  </Grid>

                  <Grid item sm={8} xs={12}>
                    <TopicAutoComplete
                      value={topic}
                      setValue={setTopic}
                      inputTextFieldParams={{
                        error: !!topicError,
                        helperText: topicError,
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            mt={3}
          >
            <Grid item>
              <Button
                variant="contained"
                color="error"
                disabled={loading}
                onClick={openDeleteConfirmationDialog}
              >
                Delete Project
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickApplyChanges}
                disabled={loading}
                sx={{ mr: 2 }}
              >
                Save
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleClickBack}
                disabled={loading}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <AlertDialogBox
          openDialog={openDeleteDialog}
          title={"Delete Project"}
          body={
            <Typography variant="body1">
              Are you sure you want to delete this project?
            </Typography>
          }
          confirmText={"Confirm"}
          cancelText={"Cancel"}
          handleConfirm={handleDelete}
          type={"info"}
          handleCancel={closeDeleteConfirmationDialog}
        />

        <CustomDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
      </Loader>
    </>
  );
}

export default ProjectComponent;
