import React, { useState } from "react";
import { ListItem, ListItemText } from "@mui/material";
import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";

export default function ExportWord({promptTitle, promptDescription}) {
  const exportWord = () => {
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              heading: HeadingLevel.HEADING_1,
              children: [
                new TextRun({
                  text: "Prompt Manager",
                  color: "4F81BD", // Hex code for the desired color
                }),
              ],
            }),
            new Paragraph({
              // This creates a blank line
              children: [],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [new TextRun("Prompt Title")],
            }),
            new Paragraph({
              children: [new TextRun(promptTitle)],
            }),
            new Paragraph({
              // This creates a blank line
              children: [],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [new TextRun("Prompt")],
            }),
            new Paragraph({
              children: [new TextRun(promptDescription)],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Prompt-Manager.docx";
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <ListItem button onClick={exportWord}>
      <ListItemText primary="Export as word" />
    </ListItem>
  );
}
