import React, { useEffect, useState, useRef } from "react";
import { fetchAlerts, deleteAlert, updateAlert } from "../../api/alerts";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "./style.css";
import CustomDialog from "../../utils/dialogbox";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useDispatch } from 'react-redux';
import { decrementAlertsCount } from '../../app/notificationSlice';

export default function Alerts() {
  const [alerts, setAlerts] = useState([]);
  const [noAlertsFound, setNoAlertsFound] = useState(false); 
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [alertToDelete, setAlertToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [observerRef, setObserverRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const observer = useRef();

  const loadAlerts = async () => {
    try {
      setLoading(true);
      const response = await fetchAlerts(currentPage);
      if (response?.data?.totalPages <= currentPage) {
        if (observer.current) await observer.current.disconnect();
      
      setAlerts((prev) => [...prev, ...(response.data?.alerts || [])]);
      setNoAlertsFound(response.data.total === 0); 
      }
      else {
      setAlerts([]);
      setNoAlertsFound(true); 
      }
    } finally {
      setLoading(false);
    }
  };
  


  const handleObserver = (entries) => {
    const target = entries[0];

    if (target.isIntersecting) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver);
    if (observerRef) observer.current.observe(observerRef);
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [observerRef]);

  useEffect(() => {
    loadAlerts();
  }, [currentPage]);

  const handleOpenDeleteDialog = (alert) => {
    setAlertToDelete(alert);
    setDeleteDialogOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteAlert = async (alert) => {
    handleOpenDeleteDialog(alert);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteAlert(alertToDelete?.id);
      if (response.success) {
        let d = alerts.filter((alert) => alert._id !== alertToDelete?.id);
        setAlerts(d);
        if(!alert?.read) {
          dispatch(decrementAlertsCount());
        }
        setDeleteDialogOpen(false);
      } else {
        console.error("Error occurred while deleting alerts", response.error);
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while deleting alerts");
      }
    } catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while deleting alerts");
    } finally {
      setLoading(false);
      handleCloseDeleteDialog();
    }
  };

  const handleProjectClick = async (alert) => {
    try {
      if(!alert.read) {
        updateAlert(alert._id);
        dispatch(decrementAlertsCount());
      }      
    } catch (error) {
      console.error("Error marking alert as read:", error);
    }
  };

  const formatMessage = (alert) => {
    const projectLink = `/${alert.project}/detail`;


    switch (alert.action) {
      case "add":
        return (
          <>
            has Given you {alert.role} access to{" "}
            <Link to={`/${alert.project}/detail`} onClick={() => handleProjectClick(alert)}>
              {alert.projectName}
            </Link>
            .
          </>
        );
      case "delete":
        return (
          <>
            has Removed you from{" "}
            <Link to={`/${alert.project}/detail`} onClick={() => handleProjectClick(alert)}>
              {alert.projectName}
            </Link>
            .
          </>
        );
      case "accepted":
        return (
          <>
            Accepted your request to access{" "}
            <Link to={`/${alert.project}/detail`} onClick={() => handleProjectClick(alert)}>
              {alert.projectName}
            </Link>
            and has assigned you as a {alert.role}.
          </>
        );
      case "denied":
        return (
          <>
            Declined your request to access{" "}
            <Link to={`/${alert.project}/detail`} onClick={() => handleProjectClick(alert)}>
              {alert.projectName}
            </Link>
            .
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container className="alerts-container">
        <Loader loading={loading}>
          {alerts?.length > 0 ? (
            <List sx={{ width: "100%" }}>
              {alerts.map((alert, index) => (
                <ListItem
                  key={alert._id}
                  sx={{
                    padding: "20px 20px",
                  }}
                  className={`alerts-list-item ${
                    !alert.read ? "alerts-list-item-unread" : ""
                  }`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteAlert({id :alert._id,read: alert.read})}
                      className="alerts-icon-button"
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar className="alerts-avatar">
                      {alert["actionUsername"][0]}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <span className="alerts-primary-text">
                        <span className="alerts-action-username">
                          {alert["actionUsername"]}
                        </span>{" "}
                        {formatMessage(alert)}
                      </span>
                    }
                    primaryTypographyProps={{ noWrap: true }}
                    secondaryTypographyProps={{ noWrap: true }}
                  />
                </ListItem>
              ))}

<div ref={setObserverRef}></div>
            </List>
          ) : noAlertsFound && (
            <Typography variant="h4">
              No Alerts to Display
            </Typography>
          )}
          <Dialog open={deleteDialogOpen} fullWidth>
            <DialogTitle>
              <Typography variant="h6">Delete Alert</Typography>
            </DialogTitle>
            <DialogContent dividers spacing={2} alignItems={"flex-start"}>
              <Typography variant="body1">
                Are you sure you want to delete this alert?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs="auto" disabled={loading}>
                  <Button onClick={handleCloseDeleteDialog} variant="contained">
                    Close
                  </Button>
                </Grid>
                <Grid item xs="auto" disabled={loading}>
                  <Button onClick={handleConfirmDelete} variant="contained">
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <CustomDialog
            open={dialogAlertOpen}
            onClose={() => setDialogAlertOpen(false)}
            title="Prompt Manager"
          >
            {dialogMessage}
          </CustomDialog>
        </Loader>
      </Grid>
    </>
  );
}
