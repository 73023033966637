import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/auth"

export const signIn  = async (email,token) => {
   try {
    let response = await webApiService.post(`${BASE_URL}/signin`,{email},{
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    let status = response.status || 500;
    if(status == responseConstants.OK){
        return  { data : response.data?.data || {}, isAuthorised: true ,success:true}
    } else {
      return { success: false,error:response.data?.message,isAuthorised :false}
    }    
   } catch (err) {
        throw err;
   }
    
}

export const logout = async () => {
  try{
      await webApiService.get(`${BASE_URL}//logout`);
  } catch(err) {
    throw err;
  }
}
