import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/bookmark"

export const addBookmark = async (addData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}`, addData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { data: data.data, exists: false, success: true }
        } else if (status === responseConstants.ALREADY_EXIST) {
            return { data: data.data, exists: true, error: data?.message, success: true }
        } else {
            return { success: false, error: data?.message }
        }
    } catch (error) {
        throw error;
    }
};

export const deleteBookmark = async (promptId) => {
    try {
        const response = await webApiService.delete(`${BASE_URL}/${promptId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { success: true };
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};
