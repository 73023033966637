import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  InputAdornment,
  Tooltip,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchUserTopics } from "../../api/topics";
import { createTopic, updateTopic } from "../../api/topics";
import CustomDialog from "../../utils/dialogbox";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import {
  NAME_MAX_CHAR_LEN,
  DESCRIPTION_MAX_CHAR_LEN,
} from "../../utils/constants";
import Loader from "../Loader";
import { debounce } from "@mui/material/utils";
import { getProjectSuggestions } from "../../api/project";

export default function NewTopicComponent(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [onErrorDialogClose, setOnErrorDialogClose] = useState(() => () => {});

  let location = useLocation();

  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [topicName, setTopicName] = useState(props.topicName || "");
  const [topicDescription, setTopicDescription] = useState(
    props.topicDescription || ""
  );
  const [topicNameError, setTopicNameError] = useState("");
  const [topicDescriptionError, setTopicDescriptionError] = useState("");

  const [linkedProjects, setLinkedProjects] = useState(
    props.linkedProjects || []
  );

  const [myTopics, setMyTopics] = useState([]);

  const loadTopics = async () => {
    try {
      setLoading(true);
      let response = await fetchUserTopics();
      setMyTopics(response.data?.topics || []);
    } catch (error) {
      setMyTopics([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTopics();
  }, []);

  const handleRemoveOption = (option) => {
    setLinkedProjects((prev) => prev.filter((item) => item !== option));
  };

  const handleClickCancel = () => {
    if (
      location.state.from &&
      location.state.from === "createProjectPage" &&
      location.state.projectCache
    ) {
      navigate("/new_project", {
        state: {
          from: "createTopicPage",
          origin: location.state.from,
          projectCache: location.state.projectCache,
        },
      });
    } else if (
      location.state.from &&
      location.state.from === "editProjectPage" &&
      location.state.projectId &&
      location.state.projectCache
    ) {
      navigate(`/${location.state.projectId}/edit`, {
        state: {
          from: "createTopicPage",
          origin: location.state.from,
          rootFrom: location.state.rootFrom,
          projectCache: location.state.projectCache,
        },
      });
    } else if (location.state.from && location.state.from === "myProjectPage") {
      navigate("/home");
    } else if (location.state.from && location.state.from === "projectsPage") {
      navigate("/Projects");
    } else {
      navigate("/global_admin", { state: { from: "/editTopic" } });
    }
  };

  const handleTopicNameChange = (event) => {
    setTopicName(event.target.value);
    if (topicNameError && event.target.value) {
      setTopicNameError("");
    }
  };

  const handleTopicDescriptionChange = (event) => {
    setTopicDescription(event.target.value);
    if (topicDescriptionError && event.target.value) {
      setTopicDescriptionError("");
    }
  };

  const handleClickCreate = async () => {
    setLoading(true);
    try {
      let isValid = true;
      const projectIds = linkedProjects.map((option) => option._id);

      if (!topicName || topicName.trim() === "") {
        setTopicNameError("Topic name is required");
        isValid = false;
      }

      if (!topicDescription || topicDescription.trim() === "") {
        setTopicDescriptionError("Topic description is required");
        isValid = false;
      } else {
        setTopicDescriptionError("");
      }

      if (!isValid) {
        return;
      }

      // Continue with creating a new topic if it doesn't exist
      const formData = {
        topicName: topicName.trim(),
        topicDescription: topicDescription.trim(),
        projectIds,
        creator: user.id,
      };

      // Wait for the state to update before proceeding
      if (props.from === "create") {
        const result = await createTopic(formData);
        if (result.exists) {
          setTopicNameError("Topic with the same name already exists");
          return;
        } else if (!result.success) {
          setErrorDialogMessage(
            "Error Occurred While Creating The Topic, Please Try Again."
          );
          setErrorDialogOpen(true);
          setOnErrorDialogClose(() => () => {});
          return;
        } else {
          setDialogMessage("Topic has been Created Successfully!");
        }
      } else {
        const result = await updateTopic(props.topicId, formData);
        if (result.exists) {
          setTopicNameError("Topic with the same name already exists");
          return;
        } else if (result.error === "Topic not found") {
          setErrorDialogMessage("Topic not found.");
          setErrorDialogOpen(true);
          setOnErrorDialogClose(() => () => {});
          return;
        } else if (!result.success) {
          setErrorDialogMessage(
            "Error Occurred While Updating The Topic, Please Try Again."
          );
          setErrorDialogOpen(true);
          setOnErrorDialogClose(() => () => {});
          return;
        } else {
          setDialogMessage("Topic has been Edited Successfully!");
        }
      }

      // Handle the success scenario
      if (props.from === "create") {
        if (location?.state?.from) {
          if (location.state.from === "projectsPage") {
            setOnDialogClose(() => () => {
              navigate("/projects");
            });

            setDialogOpen(true);
          } else if (location.state.from === "createProjectPage") {
            setOnDialogClose(() => () => {
              navigate("/new_project", {
                state: {
                  from: "createTopicPage",
                  topicName: formData.topicName,
                  projectCache: location.state.projectCache,
                },
              });
            });
            setDialogOpen(true);
          } else if (location.state.from === "globalAdminConsolePage") {
            setOnDialogClose(() => () => {
              navigate("/global_admin", { state: { from: "/editTopic" } });
            });
            setDialogOpen(true);
          } else if (location.state.from === "myProjectPage") {
            setOnDialogClose(() => () => {
              navigate(`/${user.id}/projects`);
            });
            setDialogOpen(true);
          }
        }
      } else {
        setOnDialogClose(() => () => {
          navigate("/global_admin", { state: { from: "/editTopic" } });
        });
        setDialogOpen(true);
      }
    } catch (error) {
      // Handle the error scenario
      console.error("error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading}>
        <Grid container p={4} pl={5} pr={5}>
          <Grid container item alignItems={"flex-start"} spacing={6}>
            {/* left part*/}
            <Grid container item xs={6} sm={4} md={3} lg={2} columnSpacing={2}>
              <Grid
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                item
              >
                <Grid item>
                  <Typography variant="title">My Topics</Typography>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                item
                container
                mt={1}
                sx={{ maxHeight: "80vh", overflow: "auto" }}
              >
                {myTopics.length !== 0
                  ? myTopics.map((topic) => (
                      <Grid
                        item
                        xs={12}
                        button
                        key={topic._id}
                        className={"topic-item-list"}
                      >
                        <TruncatedText text={topic.topicName} />
                      </Grid>
                    ))
                  : !loading && (
                      <Grid item xs={12}>
                        <Typography variant="h6" className="helper-title">
                          No topics are created.
                        </Typography>
                      </Grid>
                    )}
              </Grid>
            </Grid>

            {/* right part*/}
            <Grid container item xs={6} sm={8} md={9} lg={10}>
              <Grid xs={12} p={2}>
                <Typography variant="h6" mb={2} color="#9B9898">
                  {props.from === "create" ? "New Topic" : "Edit Topic"}
                </Typography>

                <Stack spacing={2}>
                  {/* Topic Name */}
                  <TextField
                    label="Topic Name*"
                    value={topicName}
                    onChange={handleTopicNameChange}
                    error={!!topicNameError}
                    helperText={topicNameError}
                    inputProps={{ maxLength: NAME_MAX_CHAR_LEN }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{`${topicName.length}/${NAME_MAX_CHAR_LEN}`}</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />

                  {/* Topic Description */}
                  <TextField
                    label="Topic Description*"
                    value={topicDescription}
                    onChange={handleTopicDescriptionChange}
                    error={!!topicDescriptionError}
                    helperText={topicDescriptionError}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={7}
                    inputProps={{ maxLength: DESCRIPTION_MAX_CHAR_LEN }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ display: "flex", alignSelf: "end" }}
                        >{`${topicDescription.length}/${DESCRIPTION_MAX_CHAR_LEN}`}</InputAdornment>
                      ),
                    }}
                  />

                  {/* Link Projects */}
                  <Grid>
                    <ProjectSearchBar
                      data={linkedProjects}
                      setData={setLinkedProjects}
                    />
                    <Stack
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={1}
                      mt={1}
                    >
                      {linkedProjects.map((option) => (
                        <Button
                          variant="outlined"
                          key={option._id}
                          endIcon={
                            <CloseIcon
                              onClick={() => handleRemoveOption(option)}
                            />
                          }
                        >
                          {option.projectName}
                        </Button>
                      ))}
                    </Stack>
                  </Grid>

                  {/* Action Buttons */}
                  <Grid
                    container
                    display="flex"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Grid item>
                      <Button
                        disabled={loading}
                        variant="contained"
                        onClick={handleClickCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Tooltip
                        title={`${
                          props.from === "create"
                            ? "Create a new topic"
                            : "Edit Topic"
                        }`}
                      >
                        <Button
                          disabled={loading}
                          variant="contained"
                          onClick={handleClickCreate}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <CustomDialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              onDialogClose();
            }}
            title="Prompt Manager"
            onDialogClose={onDialogClose}
          >
            {dialogMessage}
          </CustomDialog>
          <CustomDialog
            open={errorDialogOpen}
            onClose={() => {
              setErrorDialogOpen(false);
              onErrorDialogClose();
            }}
            title="Prompt Manager"
            onDialogClose={onErrorDialogClose}
          >
            {errorDialogMessage}
          </CustomDialog>
        </Grid>
      </Loader>
    </>
  );
}

export function ProjectSearchBar(props) {
  const { data, setData } = props;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch = useMemo(
    () =>
      debounce((input, callback) => {
        setLoading(true);
        getProjectSuggestions(input, 1, 20).then((res) => {
          if (callback) {
            callback(res?.data?.projects || []);
            setLoading(false);
          }
        });
      }, 400),
    []
  );
  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return () => {
        active = false;
      };
    }

    fetch(inputValue, async (results) => {
      if (active) {
        let ids = await data.map((x) => x._id);
        results = results.filter((x) => !ids.includes(x._id));
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleItemClick = (selectedItem) => {
    setData((prevData) => [
      ...prevData,
      {
        _id: selectedItem._id,
        projectName: selectedItem.projectName,
        projectDescription: selectedItem.projectDescription,
      },
    ]);
    setInputValue("");
  };

  return (
    <Autocomplete
      userId="search-projects"
      fullWidth
      freeSolo
      getOptionLabel={(option) => option.projectName || option}
      filterOptions={(x) => x}
      options={options}
      value={inputValue}
      autoComplete
      loading={loading}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search Projects" fullWidth />
      )}
      renderOption={(props, option) => (
        <li {...props} onClick={() => handleItemClick(option)}>
          {option.projectName}
        </li>
      )}
    />
  );
}
