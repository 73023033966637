import React, { useState } from "react";
import { TextField, IconButton, Button, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

const SearchBar = ({ onSearch }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {
    onSearch(searchText);
  };

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <TextField
        variant="outlined"
        sx={{
          background: "#F6F6F6",
          Color: "#8E8E8E",
          width: "300px",
          fontSize: "18px",
          fontWeight: 400,
          fontFamily: "Myriad Pro",
          marginRight: "10px",
          borderRadius: "var(--borderRadius, 4px)",
        }}
        size="small"
        placeholder="Search Prompts..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
