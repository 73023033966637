// src/pages/LogoutComponent.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { logOutUser } from "../../app/userSlice";
import axios from 'axios';
import { logout } from '../../api/auth';
import Loading from '../../utils/Loading';

const LogoutComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const { instance } = useMsal();

  useEffect(() => {
    const logoutBackend = async () => {
      try {
        logout().then(() => {
          dispatch(logOutUser());
          navigate("/")
        })
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    logoutBackend();

  }, [])

  return <Loading loading={true}> </Loading>;
};

export default LogoutComponent;
