import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import ProjectsList from "../../components/ProjectsList";
import NewProjectComponent from "../../components/NewProjectComponent";
import { getGlobalProjects } from "../../api/project";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Loader from "../../components/Loader";
import CustomDialog from "../../utils/dialogbox";

function NewProject(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});

  let location = useLocation();
  const navigate = useNavigate();

  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(props?.loading || false);

  useEffect(() => {
    setLoading(true);
    getGlobalProjects(null, "time", "desc", 1, 2)
      .then((data) => {
        setProjects(data.data.projects);
      })
      .catch((error) => {
        setDialogMessage(
          "Error occured while getting projects. Please try again."
        );
        setDialogOpen(true);
        setOnDialogClose(() => () => {
          navigate(-1);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loader loading={loading}>
        {projects && (
          <Grid container spacing={2} p={4} pl={5} pr={5}>
            <Grid item md={3} xs={12}>
              <Stack spacing={2}>
                <Typography variant="title">Projects</Typography>
                <ProjectsList projects={projects} from="createProject" />
              </Stack>
            </Grid>
            <Grid item xs={12} md={9}>
              {location.state && (
                <NewProjectComponent
                  createOrEdit="Create"
                  from={location.state.from}
                />
              )}
            </Grid>
          </Grid>
        )}
        <CustomDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
      </Loader>
    </>
  );
}

export default NewProject;
