import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { fetchUserSuggesstions, createUser } from "../../api/users";
import SnackbarComponent from "../SnackBar";

export default function AddUserDailogBox(props) {
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");

  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userSuggestion, setUserSuggestion] = useState([]);
  const [userError, setUserError] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedUser(null);
    setUserError("");
    setUserRole("");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
    setUserSuggestion([]);
  };

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        fetchUserSuggesstions(request.input).then((res) => {
          if (res.success) {
            callback(res.data?.value || []);
          }
          setLoading(false);
        });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setUserSuggestion(selectedUser ? [selectedUser] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (selectedUser) {
          newOptions = [selectedUser];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setUserSuggestion(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedUser, inputValue, fetch]);

  const handleClickAdd = async () => {
    try {
      if (selectedUser && userRole) {
        let newUser = {};
        newUser.name = selectedUser.displayName;
        newUser.email = selectedUser.mail;
        newUser.role = userRole;
        const result = await createUser(newUser);
        if (result.success) {
          if (result.exists) {
            setUserError("User already exists.");
            return;
          } else {
            setOpen(false);
            setSnackBarMessage(
              `${selectedUser.displayName} has been added successfully!`
            );
            setSnackBarType("success");
            setSnackBarOpen(true);
            if (props.onClose) {
              props.onClose();
            }
          }
        } else {
          setSnackBarType("error");
          setSnackBarMessage("Error occurred, please try again.");
          setSnackBarOpen(true);
        }
      }
    } catch (err) {
      setSnackBarType("error");
      setSnackBarMessage("Error occurred, please try again.");
      setSnackBarOpen(true);
    }
  };
  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
    setSnackBarMessage("");
    setSnackBarType("success");
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          textTransform: "none",
          backgroundColor: "#597697",
          fontWeight: "bold",
          color: "white",
          marginBottom: "0.5rem",
        }}
      >
        Add New User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "90%",
            maxWidth: "600px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "rgba(89, 118, 151, 1)",
            color: "#fff",
            padding: "4px 24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="problem"
            >
              <ErrorOutlineIcon />
            </IconButton>
            <Typography variant="h6">Add User</Typography>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="search-prompts"
            fullWidth
            sx={{ fontFamily: "Myriad Pro", mt: 3 }}
            getOptionLabel={(option) =>
              typeof option === "object" ? option.displayName : option
            }
            filterOptions={(x) => x}
            options={userSuggestion}
            autoComplete
            filterSelectedOptions
            value={selectedUser}
            loading={loading}
            noOptionsText="Enter User Name"
            onChange={(event, newValue) => {
              setUserSuggestion(
                newValue ? [newValue, ...userSuggestion] : userSuggestion
              );
              setSelectedUser(newValue);
              if (userError) {
                setUserError("");
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              if (userError) {
                setUserError("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Users"
                error={!!userError}
                helperText={userError}
                fullWidth
                sx={{
                  color: "#8e8e8e",
                  fontFamily: "Myriad Pro",
                  borderRadius: "10px",
                  "& .MuiInputBase-input": {
                    padding: "6px 32px 6px 12px",
                  },
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  <Grid container alignItems="center">
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {option.displayName}
                      <Typography variant="body2" color="text.secondary">
                        {option.mail}
                      </Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />

          <FormControl fullWidth>
            <InputLabel id="user-role-label" sx={{ mt: 2, color: "#b8b8b8" }}>
              Role
            </InputLabel>
            <Select
              labelId="user-role-label"
              label="Role"
              value={userRole}
              onChange={(event) => setUserRole(event.target.value)}
              sx={{ mt: 2 }}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", padding: "16px" }}>
          <Button
            onClick={handleClickAdd}
            disabled={!selectedUser || !userRole}
            sx={{
              backgroundColor: "rgba(89, 118, 151, 1)",
              color: "white",
              ":hover": {
                bgcolor:
                  !selectedUser || !userRole
                    ? "rgba(89, 118, 151, 1)"
                    : "rgba(89, 118, 151, 0.8)",
              },
              ":disabled": {
                backgroundColor: "rgba(89, 118, 151, 1)",
                color: "white",
                ".MuiButton-endIcon": {
                  color: "white",
                },
              },
              margin: "auto",
              cursor: "pointer",
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        severity={snackBarType}
        variant={"filled"}
        message={snackBarMessage}
        open={snackBarOpen}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}
