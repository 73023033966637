import React, { useEffect,useState } from "react";
import {
  Typography,
  Tabs,
  Tab,
  Grid,
  Badge,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AccessRequests from "./AccessRequests";
import SharedPrompts from "./SharedPrompts";
import Alerts from "./Alerts";
import { setNotificationCount } from "../../app/notificationSlice";
import { fetchNotificationCounts } from "../../api/alerts";
import { useNavigate,useSearchParams } from "react-router-dom";

const Notifications = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [value,setValue] = useState(Number(searchParams.get("tab")) || 0);
  const navigate = useNavigate();
  const { alertsCount, accessRequestCount, sharedPromptCount } = useSelector(state => state.notifications);

  useEffect(() => {
    checkForNotification();
  }, []);

  let checkForNotification = async ()=> {
   
    let res = await fetchNotificationCounts();
    if(res.success) {
       dispatch(setNotificationCount({
          alertsCount: res.data?.alertsCount || 0,
          accessRequestCount: res.data?.accessRequestCount || 0,
          sharedPromptCount: res.data?.sharedPromptCount || 0,
       }))
    }
  }

  const handleChange = (event, newValue) => {    
    const params = new URLSearchParams();
    params.append("tab",newValue);
    navigate({
      search: `?${params.toString()}`,
    });
    setValue(newValue);
  };
  const renderProjectTabContent = () => {
    switch (value) {
      case 0:
        return <AccessRequests />;
      case 1:
        return <SharedPrompts />;
      case 2:
        return <Alerts />;
      default:
        return null;
    }
  };
  return (
    <Grid container p={4} pl={5} pr={5} spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Notifications</Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            scrollButtons="auto"
            variant="fullWidth"
          >
            <Tab
              label={
                <Badge badgeContent={accessRequestCount} color="error">
                  Access Requests
                </Badge>
              }
            />
            <Tab
              label={
                <Badge badgeContent={sharedPromptCount} color="error">
                  <Typography variant="inherit">Shared Prompts</Typography>
                </Badge>
              }
            />
            <Tab label={
            <Badge badgeContent={alertsCount} color="error">Alerts</Badge>} />
          </Tabs>
        </Grid>
        <Grid item xs={12} p={4}>
        {renderProjectTabContent()}
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default Notifications;
