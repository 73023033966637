import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  Chip,
  Button  
} from "@mui/material";
import React, { useState} from "react";
import { NAME_MAX_CHAR_LEN } from "../../utils/constants";
import { createPrompt} from "../../api/prompt";
import Loader from "../Loader"; 
import CustomDialog from "../../utils/dialogbox";

const CreatePromptDialog = (props) => {
  const [prompt, setPrompt] = useState({promptTitle:"",promptContent:"",promptTag:[]});
  const [promptTitleError, setPromptTitleError] = useState("");
  const [promptDescriptionError, setPromptDescriptionError] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [loading,setLoading] = useState(false);
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => { });
  
  const handleTagDelete = (tagToDelete) => () => {
    const updatedTags = prompt?.promptTag?.filter(
      (tag) => tag !== tagToDelete
    );
    setPrompt({
      ...prompt,
      promptTag: updatedTags,
    });
  };

  const handleTagInputKeyDown = (event) => {
    if (event.key === "Enter" && tagInput) {
      let tags = new Set(prompt?.promptTag || []);
      tags.add(tagInput);
      setTagInput("");
      setPrompt({
        ...prompt,
        promptTag: Array.from(tags),
      });
    }
  };

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };
  
  const onCreate =  async () => {
    try{
      let isValid = true;
      if(prompt?.promptTitle?.trim() == "" ) {
        setPromptTitleError("Please enter Prompt Title.");
        isValid = false;
      } else {

        setPromptTitleError("");

      }
      if(prompt?.promptContent?.trim() == "" ) {
        setPromptDescriptionError("Please enter Prompt Description.");
        isValid = false;
      } else {

        setPromptDescriptionError("");
      }
      if(!isValid) {
        return
      }
        setLoading(true);
        const { project_id } = props;
        let promptRequest =  {
          promptTitle: prompt.promptTitle.trim() ,
          promptContent : prompt.promptContent.trim(),
          promptTag: prompt.promptTag
        }
        if(project_id) {
            let response = await createPrompt(project_id,promptRequest)
            if(response?.duplicate) {
              setPromptTitleError("A prompt with the same title already exists in the project");
              setPromptDescriptionError("A prompt with the same prompt content already exists in the project");
              return
            } else if (response?.titleExists) {
              setPromptTitleError("A prompt with the same title already exists in the project");
              return
            } else if (response?.contentExists) {
              setPromptDescriptionError("A prompt with the same prompt content already exists in the project");
              return
            }
            else if (!response.success) {
              setDialogMessage('Error while creating a prompt, Please try again');
              setDialogAlertOpen(true);
              return
            }
              else {
                if(props.onCreate) {
                    props.onCreate(response?.data?.updatedPrompt || {});
                }
        
            }
        }
       resetState() 
    }catch(err) {
      console.error(err)
    }finally{
      setLoading(false);
    }
  }
  const closeDialog = () => {
    if(props.onClose) {
        props.onClose()
    }
    resetState();
  }
  const resetState =() => {
    setPrompt({promptTitle:"",promptContent:"",promptTag:[]});
    setPromptTitleError("");
    setPromptDescriptionError("");
    setTagInput("");
    setLoading(false)
  }
  return (
    <>
      <Dialog open={props?.open || false} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack direction="column">
            <Typography variant="h6">Create New Prompt</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
        <Loader loading={loading} >
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography variant="body1">
                Prompt Title <span style={{ color: "#595454" }}>*</span>
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                required
                value={prompt.promptTitle}
                onChange={(e) => {
                  if(promptTitleError) {
                    setPromptTitleError("")
                  }
                  setPrompt({
                    ...prompt,
                    promptTitle: e.target.value,
                  })
                }
                }
                error={!!promptTitleError}
                inputProps={{ maxLength: NAME_MAX_CHAR_LEN }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`${
                      prompt?.promptTitle?.length || 0
                    }/${NAME_MAX_CHAR_LEN}`}</InputAdornment>
                  ),
                }}
                helperText={promptTitleError}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body1">
                Prompt <span style={{ color: "#595454" }}>*</span>
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                multiline
                value={prompt.promptContent}
                rows={7}
                rowsMax={10}
                required
                onChange={(e) => {
                
                if(promptDescriptionError) {
                    setPromptDescriptionError("")
                }
                  setPrompt({
                    ...prompt,
                    promptContent: e.target.value,
                  })
                }}
                helperText={promptDescriptionError}
                error={!!promptDescriptionError}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="body1">Add Tags</Typography>

              <TextField
                label="#"
                variant="outlined"
                fullWidth
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleTagInputKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      Press Enter to add
                    </InputAdornment>
                  ),
                }}
              />
              <Grid container columnGap={2}>
                {prompt?.promptTag?.map((tag, index) => (
                  <Grid xs="auto" item key={index}>
                    <Chip label={tag} onDelete={handleTagDelete(tag)} />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Stack>
          </Loader>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto">
              <Button
                onClick={closeDialog}
                disabled={loading}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={onCreate}
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <CustomDialog
          open={dialogAlertOpen}
          onClose={() => {
            setDialogAlertOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
    </>
  );
};

export default CreatePromptDialog;
