import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Typography,
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  Stack,
  Rating
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams, useNavigate } from "react-router-dom";
import Comment from "../../components/Comment";
import { fetchPromptVersionsById } from "../../api/prompt";

export default function VersionHistory() {
  const { project_id, prompt_id } = useParams();
  const [versions, setVersions] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const navigate = useNavigate();

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  useEffect(() => {
    const fetchVersionDetails = async () => {
      try {
        const versionsInfo = await fetchPromptVersionsById(project_id,prompt_id )
        setVersions(versionsInfo?.data || []);
      } catch (error) {
        console.error("Error fetching prompt details:", error);
      }
    };

    fetchVersionDetails();
  }, [project_id, prompt_id]);

  const handleClickBack = () => {
    navigate(-1);
  };

  if (versions.length !== 0) {
    return (
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4, pl: 0, pr: 0 }}>
        <Box sx={{ width: "100%" }}>
          <Typography variant="h5" gutterBottom>
            Version History
          </Typography>

          {/* Header row for titles */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px",
              backgroundColor: "#f0f0f0",
              borderRadius: "4px",
              margin: "10px 0",
            }}
          >
            <Box sx={{ width: "1%" }}></Box>
            <Typography
              variant="subtitle1"
              sx={{ width: "30%", textAlign: "center", fontWeight: "bold" }}
            >
              Version
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ width: "33%", textAlign: "center", fontWeight: "bold" }}
            >
              Updated By
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ width: "33%", textAlign: "center", fontWeight: "bold" }}
            >
              Last Updated Time
            </Typography>
          </Box>

          {/* Accordion items */}
          {versions.map((item, index) => (
            <Accordion
              key={item._id}
              expanded={expanded === item._id}
              onChange={() => setExpanded(expanded === item._id ? null : item._id)}
              sx={
                {
                  //   backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'transparent',
                }
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{ flexDirection: "row-reverse" }} // Reverse the order of elements inside the summary to make icon first
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      flexShrink: 0,
                      textAlign: "center",
                      width: "30%",
                    }}
                  >
                    {`version ${item.version}.0`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30%",
                    }}
                  >
                    {item.createdBy?.picture !== null && item.createdBy?.picture !== "" ? (
                      <Avatar
                        src={item.createdBy?.picture}
                        alt={item.createdBy?.name}
                        sx={{
                          marginRight: "0.5rem",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    ) : (
                      <Avatar
                        alt={item.createdBy?.name}
                        src="/broken-image.jpg"
                        sx={{
                          marginRight: "0.5rem",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    )}

                    <Typography variant="body1">
                      {item.createdBy?.name}
                    </Typography>
                  </Box>
                  {/* <Typography
                    sx={{
                      color: "text.secondary",
                      textAlign: "center",
                      width: "25%",
                    }}
                  >
                    {formatDate(item.createdAt)}
                  </Typography> */}
                  <Typography
                    sx={{
                      color: "text.secondary",
                      textAlign: "center",
                      width: "30%",
                    }}
                  >
                    {formatDate(item.updatedAt)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ pl: 2, pr: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Prompt Title
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {item.promptTitle}
                  </Typography>
                </Box>

                <Box sx={{ pl: 2, pr: 2, mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Prompt
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {item.promptContent}
                  </Typography>
                </Box>
                
                { item?.originalCreator && (<Box sx={{ pl: 2, pr: 2,mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Original Creator
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"} >
                      <Avatar
                        src={item.originalCreator?.picture || "/broken-image.jpg"}
                        alt={item.originalCreator?.name || "Unkown User"}
                        sx={{
                          marginRight: "0.5rem",
                          width: "30px",
                          height: "30px",
                        }}
                      />

                    <Typography variant="body1">
                      {item?.originalCreator?.name}
                    </Typography>
                  </Stack>
                  </Typography>
                </Box> )}

                { item?.context?.strength && (<Box sx={{ pl: 2, pr: 2,mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Context Strength
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {item.context?.strength}
                    <Tooltip title={ item.context?.reason}>
                                    <IconButton
                                      size="small"
                                      style={{ fontSize: "20px" }}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                  </Typography>
                </Box> )}

                { item.sentiment ? ( <Box sx={{ pl: 2, pr: 2,mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Sentiment
                  </Typography>
                  <Typography variant="body1" sx={{ color: "text.secondary" }}>
                    {item.sentiment}
                  </Typography>
                </Box> ) : null}
                { item?.avgRating !=0 && ( <Box sx={{ pl: 2, pr: 2,mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ color: "rgba(89, 118, 151, 1)", fontWeight: "bold" }}
                  >
                    Rating
                  </Typography>
                    <Rating name="read-only" value={item?.avgRating} readOnly />
                </Box> ) }
                {expanded === item._id && (
                <Box sx={{ pl: 2, pr: 2, mt: 2 }}>
                  <Box sx={{ mt: 2, width: "70%" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{
                        color: "rgba(89, 118, 151, 1)",
                        fontWeight: "bold",
                      }}
                    >
                      Comment
                    </Typography>
                    <Comment from="versionPage" promptId={item.promptId} versionNumber={item.version} />
                  </Box>
                </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleClickBack}
            sx={{
              width: "10%",
              mt: 3,
              textTransform: "none",
              backgroundColor: "rgba(89, 118, 151, 1)",
              "&:hover": {
                backgroundColor: "rgba(89, 118, 151, 0.8)",
              },
            }}
          >
            Back
          </Button>
        </Box>
      </Container>
    );
  } else {
    return <></>;
  }
}
