import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Grid,
  CardActions,
  DialogTitle,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Star from '@mui/icons-material/Star';
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import Rating from '@mui/lab/Rating';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarBorderOutlined from "@mui/icons-material/StarBorderOutlined";
import "./index.css";
import ExportPDF from "../ExportPDF";
import ExportWord from "../ExportWord";
import SharePrompt from "../SharePrompt";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchPromptById, deletePrompt, ratePrompt } from "../../api/prompt";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { TruncatedText } from "../TruncatedText/TruncatedText";
import EditPromptDialog from "../EditPromptDialog";
import CustomDialog from "../../utils/dialogbox";
import { addBookmark, deleteBookmark } from "../../api/bookmark";
import Loader from "../../components/Loader";

const PromptCard = ({
  openEvaluatePanel,
  openRunPanel,
  promptAttributes,
  prompts,
  project_id,
  prompt_id,
  project,
}) => {
  const { user } = useSelector((state) => state.user);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [contextStrength, setContextStrength] = useState("");
  const navigate = useNavigate();
  const [sentiment, setSentiment] = useState("");
  const [versionNumber, setVersionNumber] = useState();
  const [infoText, setInfoText] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});
  const promptData = prompts || [];

  const handleShareOpen = () => setIsShareDialogOpen(true);
  const handleShareClose = () => setIsShareDialogOpen(false);
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleOpenSuccessDialog = () => setIsSuccessDialogOpen(true);
  const handleCloseSuccessDialog = () => setIsSuccessDialogOpen(false);
  const handleOpenErrorDialog = () => setIsErrorDialogOpen(true);
  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);

  useEffect(() => {
    if (promptData.context) {
      setContextStrength(promptData.context?.strength);
      setInfoText(promptData.context?.reason);
      setSentiment(promptData.sentiment);
      setRating(promptData.userRating);
    }
    if (promptData.version) {
      setVersionNumber(promptData.version);
    }
  }, [promptData]);

  useEffect(() => {
    if (promptAttributes.contextStrength) {
      setContextStrength(promptAttributes.contextStrength);
      setInfoText(promptAttributes.contextReason);
      setSentiment(promptAttributes.sentiment);
    }
  }, [promptAttributes]);

  useEffect(() => {
    const fetchUserRating = async () => {
      try {
        const response = await fetchPromptById(project_id, prompt_id);
        if (response.success) {
          setRating(response.data.userRating || 0);
        }
      } catch (error) {
        console.error("Error fetching user rating:", error);
      }
    };
    fetchUserRating();
  }, [project_id, prompt_id]);

  const isUser = project?.users?.some(
    (projectUser) => projectUser.id === user.id && projectUser.role === "user"
  );

  const openEditDialog = () => setIsEditDialogOpen(true);
  const closeEditDialog = () => setIsEditDialogOpen(false);
  const openDeleteConfirmationDialog = () => setOpenDeleteDialog(true);
  const closeDeleteConfirmationDialog = () => setOpenDeleteDialog(false);

  const saveChanges = async () => {
    try {
      navigate(0);
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleDeletePrompt = async () => {
    setIsDeleteLoading(true);
    try {
      await deletePrompt(project_id, prompt_id);
      navigate(`/${project_id}/prompts`, {
        state: { from: "promptDetailPage" },
      });
    } catch (error) {
      console.error("Error deleting prompt:", error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const onRatingChange = async (event, newRating) => {
    if (newRating != null) {
      try {
        const ratingData = { rating: newRating };
        const response = await ratePrompt(project_id, prompt_id, ratingData);
        if (response.success) {
          setRating(newRating);
        } else {
          setDialogMessage("Failed to update rating:");
          setDialogAlertOpen(true);

          console.error("Failed to update rating:", response.error);
        }
      } catch (error) {
       
        console.error("Error updating rating:", error);
        handleOpenErrorDialog();
      }
    }
  };

  const toggleBookmark = async (isBookmarked, promptId) => {
    setPromptLoading(true);
    if (isBookmarked) {
      try {
        let response = await deleteBookmark(promptId);
        if (!response?.success) {
          setDialogMessage("Error while bookmarking prompt, Please try again.");
          setDialogAlertOpen(true);
        } else {
          promptData.isBookmarked = !isBookmarked;
        }
      } catch (error) {
        setDialogMessage("Error while bookmarking prompt, Please try again.");
        setDialogAlertOpen(true);
        console.error("Error while bookmarking prompt, Please try again.");
      } finally {
        setPromptLoading(false);
      }
    } else {
      try {
        let response = await addBookmark({ promptId });
        if (!response?.success) {
          setDialogMessage("Error while bookmarking prompt, Please try again.");
          setDialogAlertOpen(true);
        } else {
          promptData.isBookmarked = !isBookmarked;
        }
      } catch (error) {
        setDialogMessage("Error while bookmarking prompt, Please try again.");
        setDialogAlertOpen(true);
        console.error("Error while bookmarking prompt, Please try again.");
      } finally {
        setPromptLoading(false);
      }
    }
  };

  return (
    <>
      <Loader loading={promptLoading}>
        {isShareDialogOpen && (
          <SharePrompt
            open={isShareDialogOpen}
            handleClose={handleShareClose}
            handleOpenSuccessDialog={handleOpenSuccessDialog}
            handleOpenErrorDialog={handleOpenErrorDialog}
          />
        )}
        {isSuccessDialogOpen && (
          <CustomDialog
            open={isSuccessDialogOpen}
            onClose={handleCloseSuccessDialog}
            title="Prompt Manager"
          >
            <Typography>
              Prompt was shared successfully to selected projects.
            </Typography>
          </CustomDialog>
        )}
        {isErrorDialogOpen && (
          <CustomDialog
            open={isErrorDialogOpen}
            onClose={handleCloseErrorDialog}
            title="Prompt Manager"
          >
            <Typography>
              An error occurred during sharing. Please try again.
            </Typography>
          </CustomDialog>
        )}
        <Stack direction="column" spacing={2}>
          {promptData && Object.keys(promptData).length > 0 && (
            <Card elevation={2} className="prompt-card">
              {versionNumber && (
                <CardContent>
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Grid
                      item
                      xs={3}
                      lg={3}
                      container
                      alignItems={"center"}
                      justifyContent={"stretch"}
                      spacing={2}
                    >
                      <Grid item xs={"auto"}>
                        <IconButton
                          aria-label="share"
                          onClick={handleShareOpen}
                        >
                          <ShareIcon
                            style={{ color: "#597697" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <Button
                          aria-label="Export"
                          aria-controls="export-word-pdf"
                          aria-button="true"
                          size="large"
                          variant="text"
                          onClick={handleMenuClick}
                          buttonType="iconbuttonText"
                        >
                          Export
                        </Button>
                        <Menu
                          id="export-word-pdf"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <MenuItem>
                            <ExportWord
                              promptTitle={promptData.promptTitle}
                              promptDescription={promptData.promptContent}
                            />
                          </MenuItem>
                          <MenuItem>
                            <ExportPDF
                              promptTitle={promptData.promptTitle}
                              promptDescription={promptData.promptContent}
                            />
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      lg={5}
                      container
                      justifyContent={"center"}
                    >
                      <TruncatedText
                        variant={"body1Bold"}
                        text={promptData?.promptTitle || ""}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      lg={4}
                      container
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      spacing={2}
                    >
                      {!isUser && (
                        <Grid item xs={"auto"}>
                          <Button
                            aria-label="Evaluate"
                            variant="text"
                            size="large"
                            alignItems="center"
                            onClick={() => {
                              openEvaluatePanel();
                            }}
                            buttonType="iconbuttonText"
                          >
                            Evaluate
                          </Button>
                        </Grid>
                      )}
                      <Grid item xs={"auto"}>
                        <Button
                          aria-label="Run"
                          size="large"
                          variant="text"
                          buttonType="iconbuttonText"
                          onClick={openRunPanel}
                        >
                          Run
                        </Button>
                      </Grid>
                      <Grid item xs={"auto"}>
                        <IconButton
                          onClick={() =>
                            toggleBookmark(
                              promptData.isBookmarked,
                              promptData._id
                            )
                          }
                          aria-label="bookmark"
                        >
                          {promptData.isBookmarked ? (
                            <BookmarkIcon color="primary" />
                          ) : (
                            <BookmarkBorderIcon />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              )}
              <CardContent className="prompt-content">
                <Typography variant="body1">
                  {promptData.promptContent}
                </Typography>
              </CardContent>
              <CardActions>
                <Grid container spacing={2}>
                  <Grid item xs={8} container>
                    {contextStrength && sentiment && (
                      <Stack direction="column">
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item xs="auto">
                            <Typography variant="body1" color="#597697">
                              <strong>Context:</strong> {contextStrength}
                            </Typography>
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip title={infoText}>
                              <IconButton fontSize="small">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item xs="auto">
                            <Typography variant="body1" color="#597697">
                              <strong>Sentiment:</strong> {sentiment}
                            </Typography>
                          </Grid>
                          <Grid item xs="auto" style={{ marginLeft: "80px" }}>
                            <Rating
                              name="user-rating"
                              icon={
                                <Star
                                  style={{
                                    color: "#FFD700",
                                    stroke: "#000",
                                    strokeWidth: "1",
                                  }}
                                />
                              }
                              emptyIcon={
                                <Star
                                  style={{
                                    color: "#FFF",
                                    stroke: "#000",
                                    strokeWidth: "1",
                                  }}
                                />
                              }
                              value={rating}
                              onChange={onRatingChange}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    container
                    spacing={2}
                    alignItems={"flex-end"}
                    justifyContent={"flex-end"}
                  >
                    <Grid item xs={"auto"}>
                      {!isUser && (
                        <IconButton
                          aria-label="delete"
                          style={{ color: "#597697" }}
                          variant="outlined"
                          onClick={openDeleteConfirmationDialog}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={"auto"}>
                      {!isUser && (
                        <IconButton
                          aria-label="edit"
                          onClick={openEditDialog}
                          style={{ color: "#597697" }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          )}
          {promptData && Object.keys(promptData).length > 0 && (
            <Grid container justifyContent={"space-between"}>
              <Grid item xs="auto">
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.26)",
                  }}
                  variant="body2"
                >
                  {promptData.promptTag &&
                    promptData.promptTag.map((tag, index) => (
                      <span key={index}>
                        {"#"}
                        {tag}
                        {index < promptData.promptTag.length - 1 && ",  "}
                      </span>
                    ))}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.26)",
                  }}
                  variant="body2"
                >
                  {`version: ${versionNumber}.0`}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Stack>
        {isEditDialogOpen && (
          <EditPromptDialog
            prompt={promptData}
            open={isEditDialogOpen}
            onClose={closeEditDialog}
            project_id={project_id}
            prompt_id={prompt_id}
            onSave={saveChanges}
          />
        )}
        <Dialog open={openDeleteDialog} fullWidth>
          <DialogTitle>
            <Typography variant="h6">Delete Prompt</Typography>
          </DialogTitle>
          <DialogContent dividers spacing={2} alignItems={"flex-start"}>
            <Typography variant="body1">
              Are you sure you want to delete this prompt?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs="auto" disabled={isDeleteLoading}>
                <Button
                  onClick={closeDeleteConfirmationDialog}
                  variant="contained"
                >
                  Close
                </Button>
              </Grid>
              <Grid item xs="auto" disabled={isDeleteLoading}>
                <Button onClick={handleDeletePrompt} variant="contained">
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </Loader>
    </>
  );
};

export default PromptCard;
