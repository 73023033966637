import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Stack,
  DialogTitle
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InfoIcon from "@mui/icons-material/Info";
import { getResponse } from "../../api/model";
import { updateContext } from "../../api/prompt";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "./index.css"

const EvaluatePromptPanel = ({
  defaultModel,
  promptContent,
  onApply,
  handleEvaluatePanelClose,
  PromptAttributes,
  allModels,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [openApplyDialog, setOpenApplyDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState();
  const { project_id, prompt_id } = useParams();
  const [suggestedPromptIndex, setSuggestedPromptIndex] = useState(null);
  const navigate = useNavigate();

  const handleSelect = (model_) => {
    if (model_?.id != model?._id) {
      setSuggestions([]);
      setModel(model_);
      setIsLoading(true);
    }
  };
  const openApplyConfirmationDialog = (index) => {
    setOpenApplyDialog(true);
    setSuggestedPromptIndex(index);
  };
  const closeApplyConfirmationDialog = () => {
    setOpenApplyDialog(false);
  };
  const handleApplyPrompt = async () => {
    setIsLoading(true)
    onApply(
      suggestions[suggestedPromptIndex].suggestion,
      suggestions[suggestedPromptIndex].contextStrength,
      suggestions[suggestedPromptIndex].contextReason,
      suggestions[suggestedPromptIndex].sentiment
    );
    setSuggestions([]); // Resetting suggestions state
    handleEvaluatePanelClose();
    // navigate(0);
  };

  const evaluate = `\n Your role is to be an Prompt Engineering assistant that suggests new and better prompts for the input prompt. \n
   Considering the above input prompt that I gave provide me a better response by suggesting 7 prompts. Follow the rules listed below. \n

   Rules
   1. Please Strictly follow the output format mentioned in rule number 2
   2. The output should be in the form of an array of JSON objects. Each object should contain specific fields such as \`suggestion\`, \`contextStrength\`, \`contextReason\`, and \`sentiment\`.
   3. Detailed Fields Description:
        \`suggestion\`: The actual text of the suggested prompt.
        \`contextStrength\`: Categorize as "High", "Medium", or "Low", indicating how well the suggestion addresses the initial request. By default it should be Low.
        \`contextReason\`: A brief explanation for the assigned \`contextStrength\`, providing insight into its relevance and applicability.
        \`sentiment\`: Categorized as "Positive", "Negative", or "Neutral", reflecting the overall tone or emotional impact of the suggestion.
   4. The array should be sorted by  \`contextStrength\`, with suggestions having a "High" context strength appearing first followed by "Medium".   
   5. Include the original prompt as one of the JSON objects in the end of the array, following the above rule for the original prompt.
   
   Output Example
   [{
    "suggestion": "Enhance your prompt engineering skills with tips for crafting more effective and engaging prompts. Learn how to balance specificity with creativity to captivate your audience.",
    "contextStrength": "High",
    "contextReason": "This suggestion directly addresses the goal of improving prompt engineering skills with actionable advice, making it highly relevant to the user's request.",
    "sentiment": "Positive"
    }]
    `;
  const inputText =
    promptContent + "\n The above is my input prompt." + evaluate;


  const formatResponse = async (selectedModel) => {
    setIsLoading(true);
    // Create a Promise to wrap the asynchronous call_LLM function

    try {
      let res = await getResponse(selectedModel._id, inputText);
      const result = res.data;
      let newSuggestions = JSON.parse(result) || [];
      let lastElement = newSuggestions.pop();
      PromptAttributes(lastElement);
      const data = {
        context: {
            strength: lastElement.contextStrength,
            reason: lastElement.contextReason
        },
        sentiment: lastElement.sentiment,
        promptContent: lastElement.promptContent
    };
     updateContext(project_id, prompt_id, data);    
      setSuggestions(newSuggestions);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in formatResponse:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let initialize = async () => {
      let currentModel = await allModels.find(
        (m) => m._id === defaultModel
      );
      if (!currentModel && allModels.length > 0) {
        currentModel = allModels[0]._id;
      }

      setModel(currentModel);
    };
    setIsLoading(true);
    initialize();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await formatResponse(model);
      } catch (error) {
        console.error("Error in useEffect:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [model]);

  const RenderEvals = ({ suggestions, handleOnclickApply }) => {
    return (
      <>
        {suggestions.map((_suggestion, index) => (
          <Stack spacing={1} key={index} >
            <Card type="infocard">
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="body1Bold">Rank {index + 1}</Typography>

                  <Typography variant="body1">
                    {_suggestion.suggestion}
                  </Typography>

                  <Grid container>
                    <Grid item xs={8} container>
                      <Stack direction="column">
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item xs="auto">
                            <Typography variant="body1">
                              <strong>Context:</strong>{" "}
                              {_suggestion.contextStrength}
                            </Typography>
                          </Grid>
                          <Grid item xs="auto">
                            <Tooltip title={_suggestion.contextReason}>
                              <IconButton fontSize="small">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item xs="auto">
                            <Typography variant="body1">
                              <strong>Sentiment:</strong>{" "}
                              {_suggestion.sentiment}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      container
                      spacing={2}
                      alignItems={"flex-end"}
                      justifyContent={"flex-end"}
                    >
                      <Grid item xs={"auto"}>
                        <Button
                          variant="outlined"
                          onClick={() => handleOnclickApply(index)}
                          className="suggestion-apply-btn"
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        ))}
      </>
    );
  };

  return (
    <>
      <Card elevation={0} >
        <CardHeader
          title={<Typography variant="h6">Evaluation</Typography>}
          action={
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "white", // Label color
                  "&.Mui-focused": {
                    color: "white", // Keep label color white when focused
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Change border color when focused to white
                  },
                  mb: "10px",
                }}
              >
                Model
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={defaultModel}
                label="model"
                disabled={isLoading}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        display: "block", // Ensures each menu item is in a separate row
                        textAlign: "center",
                        color: "black",
                        borderColor: "white",
                      },
                    },
                  },
                }}
                sx={{
                  height: "50px", // Adjust the height of the select box
                  lineHeight: "30px", // Adjust line height for vertical centering
                  color: "white", // Change text color inside the select box to black
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Change border color to white
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline:hover ": {
                    color: "white", // Change border color on hover to white
                  },

                  "& .MuiSelect-select": {
                    color: model ? "white" : "", // Change text color when an item is selected
                  },
                  "& .MuiOutlinedInput-inputAdornedEnd": {
                    color: "white", // Change adornment color
                  },
                  // Ensure border stays white when the Select component is focused
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  // Ensure border stays white when the dropdown is open
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "white", // Changing the color of the default icon
                  },
                }}
              >
                {allModels.map((model_, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSelect(model_)}
                    value={model_?._id}
                  >
                    {model_?.modelDescription}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className="evaluation-card-header"
        />
        <CardContent>
          <Loader loading={isLoading}>
            <Stack spacing={2} className="suggestion-container">
              <Typography variant="h6">Suggestions</Typography>
              {suggestions?.length !== 0 ? (
                <RenderEvals
                  suggestions={suggestions}
                  handleOnclickApply={openApplyConfirmationDialog}
                />
              ) : (
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Typography variant="h6">
                    {isLoading ? `Evaluating Prompt ...` : `No Suggestions to display.`}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Loader>
        </CardContent>
      </Card>
      <Dialog 
        open={openApplyDialog}
        fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6">Apply Prompt</Typography>
        </DialogTitle>
        <DialogContent dividers spacing={2} alignItems={"flex-start"}>
          <Typography variant="body1">
            
            This will create a new version for this prompt. Would you like to do
            that?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto" disabled={isLoading}>
              <Button onClick={closeApplyConfirmationDialog} variant="contained">Close</Button>
            </Grid>
            <Grid item xs="auto" disabled={isLoading}>
              <Button onClick={handleApplyPrompt} variant="contained">
                
                  Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EvaluatePromptPanel;
