import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    alertsCount: 0,
    accessRequestCount: 0,
    sharedPromptCount: 0,
  },
  reducers: {
    setAlertsCount: (state, action) => {
      state.alertsCount = action.payload;
    },
    setAccessRequestCount: (state, action) => {
      state.accessRequestCount = action.payload;
    },
    setSharedPromptCount: (state, action) => {
      state.sharedPromptCount = action.payload;
    },
    setNotificationCount : (state,action) => {
      return action.payload
    },
    decrementAlertsCount: (state) => {
        if(state.alertsCount > 0) {
          state.alertsCount -= 1;
        }
        
      },

  },
});


export const { setAlertsCount, decrementAlertsCount, setAccessRequestCount, setSharedPromptCount,setNotificationCount } = notificationSlice.actions;

export default notificationSlice.reducer;
