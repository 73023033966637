import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/projects"

// Create a new project
export const createProject = async (projectData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/`, projectData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,exists: false,success: true}
        } else if( status == responseConstants.ALREADY_EXIST) {
          return { data:data.data,exists:true,error: data?.message, success: true}
        } else {
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch global projects
export const getGlobalProjects = async ( topic, sortObject, sortOrder,page = 1, limit = 50,filterParams) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/globalProjects`, {
            params: {  page, limit, topic, sortObject, sortOrder,filterParams }
        }); 
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch projects created by the current user
export const getMyProjects = async (topic, sortObject, sortOrder,page = 1, limit = 50 ) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/myProjects`, {
            params: { page, limit, topic, sortObject, sortOrder }
        });
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch project suggestions based on the project name
export const getProjectSuggestions = async (projectName, page = 1, limit = 10) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/suggestions`, {
            params: { projectName, page, limit }
        });
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch details of a specific project by ID
export const getProjectDetails = async (projectId) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/${projectId}`);        
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Delete projects by IDs
export const deleteProjects = async (projectIds) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/delete`, { projectIds });        
        let status = response?.data?.code;
        let data = response?.data || {};
        if([responseConstants.OK,responseConstants.NOT_FOUND].includes(status) ) {
          return {data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Update a project by ID
export const updateProject = async (projectId, projectData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${projectId}`, projectData);
        let status = response?.data?.code;
        let data = response?.data || {};        
        if(status == responseConstants.OK) {
            return {data:data.data,success: true,exists:false}
          }else if( status == responseConstants.ALREADY_EXIST) {
            return { data:data.data,exists:true,error: data?.message, success: true}
          }  else {          
            return { success: false,error: data?.message}
          }
    } catch (error) {
        throw error;
    }
};

// Update roles for a project
export const updateProjectRoles = async (projectId, roles) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${projectId}/roles`, roles);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};



// Fetch details of a specific project by ID
export const getProjectUsers = async (projectId) => {
  try {
      const response = await webApiService.get(`${BASE_URL}/${projectId}/users`);        
      let status = response?.data?.code;
      let data = response?.data || {};
      if(status == responseConstants.OK) {
        return {data:data.data,success: true}
      } else {          
        return { success: false,error: data?.message}
      }
  } catch (error) {
      throw error;
  }
};



// Fetch details of a specific project by ID
export const getProjectRoleForUser = async (projectId) => {
  try {
      const response = await webApiService.get(`${BASE_URL}/${projectId}/role`);        
      let status = response?.data?.code;
      let data = response?.data || {};
      if(status == responseConstants.OK) {
        return {data:data.data,success: true}
      } else {          
        return { success: false,error: data?.message}
      }
  } catch (error) {
      throw error;
  }
};
