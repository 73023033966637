import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Avatar,
  Tooltip,
  Stack,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getProjectDetails } from "../../api/project";
import { createAccessRequest } from "../../api/projectAccess";
import CustomDialog from "../../utils/dialogbox";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import "./index.css";
import InfoCard from "../../components/InfoCard";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import { getUserInfo } from "../../app/userSlice";
import RequestAccessDialog from "../../components/RequestAccessDialog";

const StatsDetail = (props) => {
  return (
    <>
      <Stack gap={1}>
        <Typography variant="statsTitle">{props.title}</Typography>
        <Typography variant="body1">{props.data}</Typography>
      </Stack>
    </>
  );
};

export default function ProjectDetail() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const navigate = useNavigate();
  const { project_id } = useParams();
  const  user = useSelector(getUserInfo);
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [openRequestAccessForm, setOpenRequestAccessForm] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [project_id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getProjectDetails(project_id);
      if(response.success) {
        let details = response?.data?.project || {}
        setProjectDetails(details);
        setIsPending(details?.isRequestPending || false);
        setLoading(false);
      } else {
        setDialogMessage("Error occured while loading the project.");
        setLoading(false);
        setDialogOpen(true);
      }
    } catch (error) {
      setDialogMessage("Error occured while loading the project.");
      setLoading(false);
      setDialogOpen(true);
      setLoading(false);
    }
  };

  const isAdmin = projectDetails?.userRole?.role === "admin";

  const handleClickViewPrompts = () => {
    navigate(`/${project_id}/prompts`, {
      state: { from: "projectDetailPage" },
    });
  };

  const handleClickPrompt = (_id) => {
    navigate(`/${project_id}/prompts/${_id}`);
  };

const handleRequestAccessForm = () => {
  setOpenRequestAccessForm(true);
}

const handleCancelRequestAccessForm = () => {
  setOpenRequestAccessForm(false);
}
  const handleAccessRequest = async (requestInput) => {
    setLoading(true);
    try {
      let response  = await createAccessRequest( {
        projectId : requestInput?.projectId || project_id,
        role: requestInput?.role,
        description : requestInput?.description,
      });
      if(response.success) {
        setIsPending(true);
        setDialogMessage("Access Request Sent !");
        setLoading(false);
        handleCancelRequestAccessForm();
        setDialogOpen(true);
      } else {
        setDialogMessage("Error occured while requesting access for the project. Please contact the admin");
        setLoading(false);
        setDialogOpen(true);
      }
    } catch (err) {
      setDialogMessage("Error occured while requesting access for the project. Please contact the admin");
      setLoading(false);
      setDialogOpen(true);
    }
  };

  function formatDate(isoDateString) {
    if (isoDateString) {
      const date = new Date(isoDateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
  }

  const handleAdminClick = () => {
    if( projectDetails?.creator?.email) {
      window.location.href = `mailto:${projectDetails?.creator?.email}`;
    }
  };

  const handleClickBack = () => {
    navigate(-1);
  };

  const notUserOfProject = !projectDetails?.isUser;
  return (
    <>
      <Grid container p={4} pl={5} pr={5}>
        <Loader loading={loading}>
          {openRequestAccessForm && <RequestAccessDialog  openDialog={openRequestAccessForm} user={user} projectDetails={{...projectDetails,_id:project_id}} onRequest={handleAccessRequest} handleCancel={handleCancelRequestAccessForm}/>}
          {!loading && (
            <Grid container spacing={3} item>
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                container
                spacing={2}
                className="project-left-panel"
              >
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    <StatsDetail
                      title={"Total Members"}
                      data={projectDetails?.totalMembers}
                    />
                    <StatsDetail
                      title={"Total Number of Prompts"}
                      data={projectDetails?.totalPrompts}
                    />
                    <StatsDetail
                      title={"Project Last Updated On"}
                      data={formatDate(projectDetails?.updatedAt)}
                    />
                    {projectDetails?.latestPrompts?.length !== 0 && (
                      <>
                        <StatsDetail
                          title={"Prompt Last Created On"}
                          data={formatDate(
                            projectDetails?.promptLastCreated
                          )}
                        />
                        <StatsDetail
                          title={"Prompt Last Updated On"}
                          data={formatDate(
                            projectDetails?.promptLastUpdated
                          )}
                        />
                      </>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={2}>
                    {!isAdmin && (
                      <Tooltip
                        title={`Created by: ${
                          projectDetails?.creator?.name || ""
                        } (${
                          projectDetails?.creator?.email || ""
                        })`}
                        placement="top"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={handleAdminClick}
                        >
                          Contact Admin
                        </Button>
                      </Tooltip>
                    )}
                    {isPending && (
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled
                      >
                        Request Pending
                      </Button>
                    )}
                    {!isPending && notUserOfProject && (
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleRequestAccessForm}
                      >
                        Request Access
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClickBack}
                    >
                      Back
                    </Button>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={5} md={6}>
                <Card elevation={0}>
                  <CardHeader
                    title={
                      <Typography variant="h6">Project Details</Typography>
                    }
                    className="project-card-header"
                  />
                  <CardContent>
                  { projectDetails?.projectName && <Stack>
                      <TextField
                        label="Project Name"
                        value={projectDetails?.projectName || " "}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                        multiline
                        margin="normal"
                        type="readOnly"
                        disabled
                      />
                      {/* Project Description */}
                      <TextField
                        label="Project Description"
                        value={
                          projectDetails?.projectDescription || " "
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="readOnly"
                        multiline
                        rows={7}
                        disabled
                      />
                    </Stack> }
                  </CardContent>
                </Card>
              </Grid>
              {!notUserOfProject && (
                <Grid item xs={12} sm={3} md={3}>
                  <Stack spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleClickViewPrompts}
                    >
                      View All Prompts
                    </Button>
                    {projectDetails?.latestPrompts?.length > 0 && (
                      <>

                    <Typography variant="h6">Recent Prompts</Typography>
                    <Stack spacing={2}>
                      {projectDetails?.latestPrompts?.map((prompt) => {
                        console.log(prompt)
                        return (
                            <Card type="infocard" key={prompt._id}>
                              <CardHeader
                                type="infocard"
                                onClick={() => handleClickPrompt(prompt._id)}
                                avatar={
                                  <>
                                    <Tooltip
                                      title={
                                        <InfoCard user={prompt.createdBy} />
                                      }
                                      placement="bottom-start"
                                      type="infocard"
                                    >
                                      <Avatar
                                        src={
                                          prompt?.createdBy?.picture ||
                                          "/broken-image.jpg"
                                        }
                                        alt={prompt?.createdBy?.name}
                                        aria-label="project"
                                      />
                                    </Tooltip>
                                  </>
                                }
                                title={
                                  <Grid
                                    container
                                    alignItems={"center"}
                                    spacing={2}
                                  >
                                    <Grid item xs={10}>
                                      <TruncatedText
                                        variant={"body1"}
                                        text={prompt.promptTitle}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                              />
                              <CardContent type="infocard">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {prompt.promptContent}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Typography variant="body3Primary">
                                  Last Updated {formatDate(prompt.updatedAt)}
                                </Typography>
                              </CardActions>
                            </Card>
                        );
                      })}
                    </Stack>
                      </>
                    )}
                  </Stack>
                </Grid>
              )}
            </Grid>
          )}

          <CustomDialog
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
            }}
            title="Prompt Manager"
          >
            {dialogMessage}
          </CustomDialog>
        </Loader>
      </Grid>
    </>
  );
}
