import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/shareRequests"

// Create Share requests
export const createShareRequest = async (prompts) => {
  try {
    const response = await webApiService.post(`${BASE_URL}/`, prompts);
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true }
    } else {
      return { success: false, error: data?.message }
    }

  } catch (error) {
    throw error;
  }
};

// Get All share requests
export const fetchAllShareRequests = async () => {
  try {
    const response = await webApiService.get(`${BASE_URL}/`);

    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data, success: true }
    } else {
      return { success: false, error: data?.message }
    }
  } catch (error) {
    throw error;
  }
};

// get share requests by projectId
export const fetchShareRequestsByProjectId = async (projectId) => {
  try {
    const response = await webApiService.get(`${BASE_URL}/${projectId}`);

    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true }
    } else {
      return { success: false, error: data?.message }
    }
  } catch (error) {
    throw error;
  }
};

// update share request
export const processShareRequest = async (requests) => {
  try {
    const response = await webApiService.put(`${BASE_URL}/`, requests);
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true }
    } else if (status == responseConstants.NOT_FOUND) {
      return { status, success: false, error: data?.message }
    } else if (status == responseConstants.ALREADY_EXIST) {
      return { status, success: false, duplicate: true }
    } else if (status == responseConstants.PROMPT_TITLE_EXIST) {
      return { status, success: false, titleExists: true }
    } else if (status == responseConstants.PROMPT_CONTENT_EXIST) {
      return { status, success: false, contentExists: true }
    } else {
      return { status, success: false, error: data?.message }
    }
  } catch (error) {
    throw error;
  }
};

export const processShareRequestWithNewTitle = async (requests) => {
  try {
    const response = await webApiService.put(`${BASE_URL}/replaceTitle`, requests);
    let status = response?.data?.code;
    let data = response?.data || {};
    if(status == responseConstants.OK) {
      return {data:data.data,exists: false,success: true}
    } else if( status == responseConstants.ALREADY_EXIST) {
      return { data:data.data,duplicate:true,error: data?.message, success: true}
    } else if( status == responseConstants.PROMPT_TITLE_EXIST) {
      return { data:data.data,titleExists:true,error: data?.message, success: true}
    } else if( status == responseConstants.PROMPT_CONTENT_EXIST) {
      return { data:data.data,contentExists:true,error: data?.message, success: true}
    } else {
      return { success: false,error: data?.message}
    }
  } catch (error) {
    throw error;
  }
};

export const mergeByTitle = async (requestId) => {
  try {
    const response = await webApiService.put(`${BASE_URL}/mergeByTitle/${requestId}`);
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true }
    } else if (status == responseConstants.PROMPT_CONTENT_EXIST) {
      return { status, success: false, error: data?.message, contentExists: true  }
    } else if (status == responseConstants.NOT_FOUND) {
      return { status, success: false, error: data?.message }
    } else {
      return { status, success: false, error: data?.message }
    }
  } catch (error) {
    throw error;
  }
};


export const mergeByContent = async (requestId) => {
  try {
    const response = await webApiService.put(`${BASE_URL}/mergeByContent/${requestId}`);
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true }
    } else if (status == responseConstants.PROMPT_TITLE_EXIST) {
      return { status, success: false, error: data?.message, titleExists: true  }
    }else if (status == responseConstants.NOT_FOUND) {
      return { status, success: false, error: data?.message }
    } else {
      return { status, success: false, error: data?.message }
    }
  } catch (error) {
    throw error;
  }
};