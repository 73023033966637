import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Stack
} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getResponse } from "../../api/model";

function RunPromptModal({ defaultModel, promptContent, allModels }) {
  const [isLoading, setIsLoading] = useState(true);
  const [progOnResLoading, setProgOnResLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const containerRef = useRef(null); // Ref for the container
  const [prompt, setPrompt] = useState("");
  const inputText = promptContent;
  const [model, setModel] = useState();

  useEffect(() => {
    let initialize = async () => {
      let currentModel = await allModels.find(
        (m) => m.modelDescription === defaultModel
      );
      if (!currentModel && allModels.length > 0) {
        currentModel = allModels[0]._id;
      }
      setModel(currentModel);
    };
    setIsLoading(true);
    initialize();
  }, []);

  const formatResponse = async (selectedModel) => {
    setIsLoading(true);
    // Create a Promise to wrap the asynchronous getResponse function
    try {
      let res = await getResponse(selectedModel, inputText);
      const result = res?.data || "";
      const response = result.replace(/["']/g, "");
      setConversations([
        ...conversations,
        {
          prompt: "",
          response: response,
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in formatResponse:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!model) return;
      try {
        await formatResponse(model);
      } catch (error) {
        console.error("Error in useEffect:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [model]);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleSubmit = async () => {
    setProgOnResLoading(true);

    try {
      let input = `${
        conversations[conversations.length - 1].response
      } \n \n Now Consider the above given text as the context. ${prompt}`;
      const res = await getResponse(model._id, input);
      const result = res?.data || "";
      const response = result.replace(/["']/g, "");

      setConversations([
        ...conversations,
        {
          prompt: prompt,
          response: response,
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    setPrompt("");
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    setProgOnResLoading(false);
  };

  const handleSelect = (model_) => {
    if (model_?._id != model?._id) {
      setConversations([]);
      setModel(model_);
      setIsLoading(true);
    }
  };

  return (
      <Card elevation={0}>
        <CardHeader
          title={<Typography variant="h6">Response</Typography>}
          action={
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "white", // Label color
                  "&.Mui-focused": {
                    color: "white", // Keep label color white when focused
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Change border color when focused to white
                  },

                  mb: "10px",
                }}
              >
                Model
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={defaultModel}
                label="model"
                disabled={isLoading}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root": {
                        display: "block", // Ensures each menu item is in a separate row
                        textAlign: "center",
                        color: "black",
                        borderColor: "white",
                      },
                    },
                  },
                }}
                sx={{
                  height: "50px", // Adjust the height of the select box
                  lineHeight: "20px", // Adjust line height for vertical centering
                  color: "white", // Change text color inside the select box to black
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white", // Change border color to white
                  },
                  "& .Mui-focused .MuiOutlinedInput-notchedOutline:hover ": {
                    color: "white", // Change border color on hover to white
                  },

                  "& .MuiSelect-select": {
                    color: model ? "white" : "", // Change text color when an item is selected
                  },
                  "& .MuiOutlinedInput-inputAdornedEnd": {
                    color: "white", // Change adornment color
                  },
                  // Ensure border stays white when the Select component is focused
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  // Ensure border stays white when the dropdown is open
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  },
                  "& .MuiSelect-icon": {
                    color: "white", // Changing the color of the default icon
                  },
                }}
              >
                {allModels.map((model_, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleSelect(model_)}
                    value={model_?._id}
                  >
                    {model_?.modelDescription}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          className="evaluation-card-header"
        />
        <CardContent>
          {isLoading && 
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="400px"
            >
              <CircularProgress />
            </Box>}
          {!isLoading && (
            <Stack spacing={2}>
              <TextField
                id="runPromptInput"
                variant="outlined"
                multiline
                fullWidth
                value={prompt}
                onChange={handlePromptChange}
                placeholder="Type your prompt here"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!progOnResLoading && (
                        <IconButton onClick={handleSubmit}>
                          <ReplyIcon sx={{ color: "#597697" }} />
                        </IconButton>
                      )}
                      {progOnResLoading === true && <CircularProgress />}
                    </InputAdornment>
                  ),
                }}
              />
              <Stack
                ref={containerRef}
                className="suggestion-container"
                p={2}
                spacing={2}
              >
                {conversations?.map((conversation, index) => (
                  <React.Fragment key={index}>
                    {conversation.prompt && (
                      <TextField
                        id={`userPrompt${index}`}
                        variant="outlined"
                        multiline
                        fullWidth
                        sx={{ fontWeight: 500 }}
                        value={"Prompt:\n" + conversation.prompt}
                        InputProps={{
                          readOnly: true,
                          sx: { letterSpacing: "0.5px" },
                        }}
                      />
                    )}
                    <TextField
                      id={`gptResponse${index}`}
                      variant="outlined"
                      multiline
                      fullWidth
                      sx={{ fontWeight: 500 }}
                      value={"Response:\n" + conversation.response}
                      InputProps={{
                        readOnly: true,
                        sx: { letterSpacing: "0.6px" },
                      }}
                    />
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          )}
        </CardContent>
      </Card>
  );
}
export default RunPromptModal;
