/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { removeError, addError } from "./errorSlice";
import { signIn } from "../services/auth";

const initialState = {
  isAuthenticated: false,
  user: {}
};

// export const signInAction = createAsyncThunk(
//   "user/signIn",
//   async (data, thunkAPI) => {
//     try {
//       const { email, name, picture, loginMethod } = data;
//       const user = await signIn(email, name, picture, loginMethod);

//       if (user.email && user.token) localStorage.setItem("token", user.token);

//       return user;
//     } catch (error) {
//       const { message } = error;
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      let payload = action.payload
      if(!(payload instanceof Object)){
        payload = JSON.parse(payload)
      }
      state.isAuthenticated = !!Object.keys(payload).length;
      state.user = payload;
      sessionStorage.setItem("userInfo",JSON.stringify(state.user));
      localStorage.setItem("token",state.user?.token);
      return state
    },
    logOutUser: (state, action) => {
      state.isAuthenticated = false;
      state.user = {};

      sessionStorage.clear();
      return state
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(signInAction.fulfilled, (state, action) => {
  //     state.isAuthenticated = !!Object.keys(action.payload).length;
  //     state.user = action.payload;
  //     state.status = "Sign in succeeded";
  //   });
  //   builder.addCase(signInAction.rejected, (state, action) => {
  //     state.isAuthenticated = false;
  //     state.user = {};
  //     state.status = action.payload;
  //   });
  //   builder.addCase(signInAction.pending, (state, action) => {
  //     state.status = "Sign in pending";
  //   });
  // },
});

export const getUserInfo = (state) => state.user.user;
export const isUserAuthenticated = (state) => state.user.isAuthenticated;

export const { setCurrentUser, logOutUser } = userSlice.actions;
export default userSlice.reducer;
