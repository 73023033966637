import React, { useState, useEffect, useMemo } from "react";
import {
  Autocomplete,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
  Menu,
  MenuItem,
  Dialog,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Avatar,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Grid,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
  Rating,
} from "@mui/material";
import {
  Add as AddIcon,
  FilterAlt,
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  StarBorder as StarBorderIcon,
} from "@mui/icons-material";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { Pagination } from "@mui/lab";
import CustomDialog from "../../utils/dialogbox";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import InfoCard from "../../components/InfoCard";
import Loader from "../../components/Loader";
import CreatePromptDialog from "../../components/CreatePromptDialog";
import ImportPromptDialog from "../../components/ImportPromptDialog";
import { fetchAllPrompts } from "../../api/prompt";
import { createShareRequest } from "../../api/shareprompts";
import { getProjectSuggestions, getProjectDetails } from "../../api/project";
import { debounce } from "@mui/material/utils";
import { addBookmark, deleteBookmark } from "../../api/bookmark";

function formatDate(isoDateString) {
  const date = new Date(isoDateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export const PromptsPage = (props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState([]);
  const { project_id } = useParams();
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [disableExportCsv, setDisableExportCsv] = useState(false);
  const [disableConfirmSharePrompts, setDisableConfirmSharePrompts] = useState(false);
  const [isTopRated, setIsTopRated] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [sortOption, setSortOption] = useState("");
  const [totalPrompts, setTotalPrompts] = useState(0);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [selectAll, setSelectAll] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [promptsLoading, setPromptsLoading] = useState(false);

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    let i = isSmScreen
      ? 6
      : isMdScreen
      ? 6
      : isLgScreen
      ? 9
      : isXlScreen
      ? 12
      : itemsPerPage;
    setItemsPerPage(i);
  }, [isSmScreen, isLgScreen, isMdScreen, isXlScreen]);

  const handleMenuOpen = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElSort(null);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const closeImportDialog = () => {
    handleCancelSelection();
    setDialogOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (searchText) => {
    const filteredPrompts = prompts.filter((prompt) => {
      const titleMatch = prompt.promptTitle
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const tagMatch = prompt.promptTag.some((tag) =>
        tag.toLowerCase().includes(searchText.toLowerCase())
      );
      return titleMatch || tagMatch;
    });
    setFilteredPrompts(filteredPrompts);
    setAnchorEl(document.activeElement);
  };

  useEffect(() => {
    if (selectedPrompts.length < prompts.length) {
      setSelectAll(false);
    } else if (
      selectedPrompts.length > 0 &&
      prompts.length > 0 &&
      selectedPrompts.length === prompts.length
    ) {
      setSelectAll(true);
    }
  }, [selectedPrompts]);

  const handleRemoveOption = (option) => {
    setSelectedOptions((prev) => prev.filter((item) => item !== option));
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (!newValue || newValue === null) return;
    setSelectedOptions((prevSelectedOptions) => [
      ...prevSelectedOptions,
      newValue,
    ]);
    setInputValue("");
  };

  const togglePromptSelection = (promptId) => {
    if (selectedPrompts.includes(promptId)) {
      setSelectedPrompts(selectedPrompts.filter((id) => id !== promptId));
    } else {
      setSelectedPrompts([...selectedPrompts, promptId]);
    }
  };

  const openShareDialog = async () => {
    setPromptsLoading(true);
    setIsShareDialogOpen(true);
    setPromptsLoading(false);
  };

  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
  };

  const closeCreateDialog = () => {
    setIsCreateDialogOpen(false);
  };

  const closeShareDialog = () => {
    setIsShareDialogOpen(false);
  };

  const handleClickPrompt = (_id) => {
    navigate(`/${project_id}/prompts/${_id}`);
  };

  const handleShareClick = async () => {
    try {
      setPromptsLoading(true);
      setDisableConfirmSharePrompts(true);
      const destProjectIds = selectedOptions.map((option) => option._id);
      const sharePromptsData = [];

      for (const promptId of selectedPrompts) {
        for (const destProjectId of destProjectIds) {
          const prompt = prompts.find((prompt) => prompt._id === promptId);
          if (prompt) {
            sharePromptsData.push({
              promptId: prompt._id,
              promptVersion: prompt.version,
              destProjectId: destProjectId,
            });
          }
        }
      }
      const requestBody = {
        prompts: sharePromptsData,
      };

      if (sharePromptsData.length > 0) {
        const response = await createShareRequest(requestBody);
        if (response?.success) {
          setSelectedPrompts([]);
          handleCloseAndClear();
          setIsShareDialogOpen(false);
          setDialogMessage("Share Request for Selected prompts was Successful");
          setDialogAlertOpen(true);
          setOnDialogClose(() => () => {
            setIsShareDialogOpen(false);
          });
        } else {
          setDialogAlertOpen(true);
          setDialogMessage("Error while sharing prompts, Please try again!");
        }
      }
    } catch (error) {
      setDialogMessage(
        "Error while sharing the selected prompts, Please try again."
      );
      setDialogAlertOpen(true);
      console.error("Error sharing prompts:", error);
    } finally {
      setDisableConfirmSharePrompts(false);
      setPromptsLoading(false);
    }
  };

  const handleSortPrompts = (sortBy) => {
    let sortedPrompts = [...prompts];

    if (sortBy === "Newest First") {
      sortedPrompts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortBy === "Oldest First") {
      sortedPrompts.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    } else if (sortBy === "Top Rated") {
      sortedPrompts = prompts.filter((prompt) => prompt.avgRating > 0)
                           .sort((a, b) => b.avgRating - a.avgRating);
    } else if (sortBy === "bookmarked") {
      sortedPrompts = prompts.filter((prompt) => prompt.isBookmarked);
    }

    setFilteredPrompts(sortedPrompts);
    handleMenuClose();
  };
  

  useEffect(() => {
    let sortObject = searchParams.get("sortObject");
    let sortOrder = searchParams.get("sortOrder");
    fetchPrompts(project_id, sortObject, sortOrder);
  }, [currentPage, itemsPerPage, sortOption]);

  const handleSort = (sortOption) => {
    let sortObject = "";
    let sortOrder = "";
    setSortOption(sortOption);
    switch (sortOption) {
      case "alphabet":
        sortObject = "alphabet";
        sortOrder = "asc";
        break;
      case "latest":
        sortObject = "latest";
        sortOrder = "desc";
        break;
      case "oldest":
        sortObject = "oldest";
        sortOrder = "desc";
        break;
      case "bookmarked":
        sortObject = "bookmarked";
        sortOrder = "desc";
        break;
      case "ratedprompts":
        sortObject = "ratedprompts";
        sortOrder = "desc";
        break;
      default:
        sortObject = "latest";
        sortOrder = "desc";
        break;
    }
    const params = new URLSearchParams();
    params.append("sortObject", sortObject);
    if (sortOrder !== "") {
      params.append("sortOrder", sortOrder);
    }
    navigate({
      search: `?${params.toString()}`,
    });
    setCurrentPage(1);
    handleMenuClose();
  };

  const fetchPrompts = async (project_id, sortObject, sortOrder) => {
    try {
      if (itemsPerPage < 1) return;
      setPromptsLoading(true);
      let response = await fetchAllPrompts(
        project_id,
        sortObject || "time",
        sortOrder || "desc",
        currentPage,
        itemsPerPage
      );
      if (!response?.success) {
        setDialogMessage("Error while fetching the Prompts, Please try again.");
        setDialogAlertOpen(true);
        setPrompts([]);
      } else {
        setPrompts(response.data.prompts);
        setFilteredPrompts(response.data.prompts);
        setTotalPrompts(response?.data?.totalCount || prompts.length);
      }
    } catch (error) {
      setPrompts([]);
      setTotalPrompts(0);
      setDialogMessage("Error while fetching the prompts, Please try again.");
      setDialogAlertOpen(true);
      console.error("Error while fetching the Prompts, Please try again.");
    } finally {
      setPromptsLoading(false);
    }
  };

  const handleCloseAndClear = () => {
    setSelectedOptions([]);
    closeShareDialog();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProjectName("");
        const promises = [
          fetchPrompts(project_id),
          getProjectDetails(project_id),
        ];
        const [_, currentProjectResponse] = await Promise.all(promises);
        const projectName = currentProjectResponse.data.project.projectName;
        setProjectName(projectName);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [project_id]);

  useEffect(() => {
    if (selectedPrompts.length < prompts.length) {
      setSelectAll(false);
    } else if (
      selectedPrompts.length > 0 &&
      prompts.length > 0 &&
      selectedPrompts.length === prompts.length
    ) {
      setSelectAll(true);
    }
  }, [selectedPrompts]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        getProjectSuggestions(request.input, 1, 20).then((res) => {
          if (callback) {
            callback(res?.data?.projects || []);
          }
          setLoading(false);
        });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const copyToClipBoard = (content, promptId) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMessage((prevMessages) => ({
          ...prevMessages,
          [promptId]: "Copied!",
        }));
        setTimeout(() => {
          setCopiedMessage((prevMessages) => ({
            ...prevMessages,
            [promptId]: "",
          }));
        }, 1000);
      })
      .catch((error) => {
        setCopiedMessage((prevMessages) => ({
          ...prevMessages,
          [promptId]: "Copy failed",
        }));
        console.error("Unable to copy text: ", error);
        setTimeout(() => {
          setCopiedMessage((prevMessages) => ({
            ...prevMessages,
            [promptId]: "",
          }));
        }, 1000);
      });
  };

  const handleExportCSV = () => {
    try {
      setDisableExportCsv(true);
      if (!Array.isArray(selectedPrompts) || selectedPrompts.length === 0) {
        console.error("Invalid or empty selectedPrompts array.");
        return;
      }

      const promptDataArray = prompts.filter((prompt) =>
        selectedPrompts.includes(prompt._id)
      );

      const allTags = promptDataArray.flatMap(
        (prompt) => prompt.promptTag || []
      );

      const maxTagCount = Math.max(
        ...promptDataArray.map((prompt) => prompt.promptTag?.length || 0)
      );

      const tagHeaders = Array.from(
        { length: maxTagCount },
        (_, index) => `promptTag${index + 1}`
      );

      const headers = ["promptTitle", "promptContent", ...tagHeaders];

      const csvData = [
        headers,
        ...promptDataArray.map((prompt) => [
          prompt.promptTitle,
          `"${(prompt.promptContent || "").replace(/"/g, '""')}"`,
          ...(prompt.promptTag || []).map((tag) => `"${tag}"`),
        ]),
      ];

      const csvContent =
        "data:text/csv;charset=utf-8," +
        csvData.map((row) => row.join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${projectName}_${new Date().toISOString()}.csv`
      );
      document.body.appendChild(link);
      link.click();
      setSelectedPrompts([]);
      setDisableExportCsv(false);
    } catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error while Exporting Prompts, Please try again.");
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedPrompts(prompts.map((prompt) => prompt._id));
    } else {
      setSelectedPrompts([]);
    }
    setSelectAll(!selectAll);
  };

  const handleCancelSelection = () => {
    setSelectedPrompts([]);
    setSelectAll(false);
  };

  const handleClickBack = () => {
    if (location.state.from === "promptDetailPage") {
      navigate("/projects");
    } else {
      navigate(-1);
    }
  };

  const onCreate = () => {
    closeCreateDialog();
    let sortObject = searchParams.get("sortObject");
    let sortOrder = searchParams.get("sortOrder");
    fetchPrompts(project_id, sortObject, sortOrder);
  };

  const onImport = () => {
    closeImportDialog();
    let sortObject = searchParams.get("sortObject");
    let sortOrder = searchParams.get("sortOrder");
    fetchPrompts(project_id, sortObject, sortOrder);
  };

  const filteredOptions = options.filter((option) => {
    if (option._id === project_id) {
      return false;
    }
    return !selectedOptions.find(
      (selectedOption) => selectedOption._id === option._id
    );
  });

  const toggleBookmark = async (isBookmarked, promptId) => {
    setPromptsLoading(true);
    if (isBookmarked) {
      try {
        let response = await deleteBookmark(promptId);
        if (!response?.success) {
          setDialogMessage(
            "Error while bookmarking prompt, Please try again."
          );
          setDialogAlertOpen(true);
        }
        let sortObject = searchParams.get("sortObject");
        let sortOrder = searchParams.get("sortOrder");
        fetchPrompts(project_id, sortObject, sortOrder);
      } catch (error) {
        setDialogMessage("Error while bookmarking prompt, Please try again.");
        setDialogAlertOpen(true);
        console.error("Error while bookmarking prompt, Please try again.");
      } finally {
        setPromptsLoading(false);
      }
    } else {
      try {
        let response = await addBookmark({ promptId });
        if (!response?.success) {
          setDialogMessage(
            "Error while bookmarking prompt, Please try again."
          );
          setDialogAlertOpen(true);
          setOnDialogClose(() => () => {});
        }
        let sortObject = searchParams.get("sortObject");
        let sortOrder = searchParams.get("sortOrder");
        fetchPrompts(project_id, sortObject, sortOrder);
      } catch (error) {
        setDialogMessage("Error while bookmarking prompt, Please try again.");
        setDialogAlertOpen(true);
        setOnDialogClose(() => () => {});
        console.error("Error while bookmarking prompt, Please try again.");
      } finally {
        setPromptsLoading(false);
      }
    }
  };

  return (
    <div>
      <Loader loading={promptsLoading}>
        <Grid container p={4} pl={5} pr={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} spacing={2} container alignItems={"center"}>
              <Grid item xs={12} md={3}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="subtitle" color="#597697">
                      <strong>Add Prompt</strong>
                    </Typography>
                    <Button
                      variant="outlined"
                      buttonType="iconbutton"
                      onClick={openCreateDialog}
                    >
                      <AddIcon fontSize="small" />
                    </Button>
                  </Stack>
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Typography variant="subtitle" color="#597697">
                      <strong>Import</strong>
                    </Typography>
                    <Button
                      variant="outlined"
                      buttonType="iconbutton"
                      onClick={handleDialogOpen}
                    >
                      <AddIcon fontSize="small" />
                    </Button>
                  </Stack>
                  <Stack></Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} md={5} container justifyContent={"center"}>
                <TruncatedText variant="title" text={projectName} />
              </Grid>
              <Grid item xs={6} md={4} container alignItems={"center"}>
                <Grid item xs={11}>
                  <SearchBar onSearch={handleSearch} />
                  {filteredPrompts.length > 0 ? (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      {filteredPrompts.map((prompt, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            setAnchorEl(null);
                            handleClickPrompt(prompt._id);
                          }}
                        >
                          {prompt.promptTitle}
                        </MenuItem>
                      ))}
                    </Menu>
                  ) : (
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem>No results found .....</MenuItem>
                    </Menu>
                  )}
                </Grid>
                <Grid item xs={1}>
                  <Button
                    variant="outlined"
                    buttonType="iconbutton"
                    onClick={handleMenuOpen}
                  >
                    <FilterAlt />
                  </Button>
                  <Menu
                    anchorEl={anchorElSort}
                    open={Boolean(anchorElSort)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleSortPrompts("alphabet")}>
                      Sort A-Z
                    </MenuItem>
                    <MenuItem onClick={() => handleSortPrompts("Newest First")}>
                      Sort by newest first
                    </MenuItem>
                    <MenuItem onClick={() => handleSortPrompts("Oldest First")}>
                      Sort by oldest first
                    </MenuItem>
                    <MenuItem onClick={() => handleSortPrompts("bookmarked")}>
                      Show only bookmarked
                    </MenuItem>
                    <MenuItem onClick={() => handleSortPrompts("Top Rated")}>
                      Show only top-rated
                    </MenuItem>
                    
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                {selectedPrompts.length > 0 && (
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          color="primary"
                        />
                      }
                      label="Select all"
                    />
                    <Button
                      disabled={selectedPrompts.length === 0}
                      variant="outlined"
                      onClick={openShareDialog}
                    >
                      Share
                    </Button>
                    <Button
                      disabled={
                        selectedPrompts.length === 0 || disableExportCsv
                      }
                      variant="outlined"
                      onClick={() => handleExportCSV(selectedPrompts)}
                    >
                      Export
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {filteredPrompts.length > 0 ? (
                <Stack spacing={2}>
                  <Typography variant="title">Prompts</Typography>
                  <Grid container spacing={2} className="prompts-container">
                    {filteredPrompts.map((prompt, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={prompt._id}>
                        <Card type="infocard">
                          <CardHeader
                            type="infocard"
                            avatar={
                              <Tooltip
                                title={
                                  <InfoCard
                                    user={prompt.createdBy}
                                    role={"Prompt Creator"}
                                  />
                                }
                                placement="bottom-start"
                                type="infocard"
                              >
                                <Avatar
                                  src={
                                    prompt?.createdBy?.picture ||
                                    "/broken-image.jpg"
                                  }
                                  alt={prompt?.createdBy?.name}
                                  aria-label="project"
                                />
                              </Tooltip>
                            }
                            title={
                              <Grid container alignItems={"center"}>
                                <Grid
                                  item
                                  xs={8}
                                  onClick={() => handleClickPrompt(prompt._id)}
                                >
                                  <TruncatedText
                                    variant={"body1"}
                                    text={prompt.promptTitle}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  container
                                  justifyContent={"flex-end"}
                                  wrap="nowrap"
                                >
                                  <Grid item xs="auto">
                                    <Checkbox
                                      onClick={(event) => {
                                        event.stopPropagation();
                                      }}
                                      checked={selectedPrompts.includes(
                                        prompt._id
                                      )}
                                      onChange={() =>
                                        togglePromptSelection(prompt._id)
                                      }
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    <IconButton
                                      onClick={() =>
                                        toggleBookmark(
                                          prompt.isBookmarked,
                                          prompt._id
                                        )
                                      }
                                      aria-label="bookmark"
                                    >
                                      {prompt.isBookmarked ? (
                                        <BookmarkIcon color="primary" />
                                      ) : (
                                        <BookmarkBorderIcon />
                                      )}
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                            titleTypographyProps={{
                              noWrap: true,
                            }}
                          />
                          <CardContent type="infocard">
                            <Typography variant="body2">
                              {prompt.promptContent}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xs="auto">
                                <Typography variant="body3Primary">
                                  Last Updated {formatDate(prompt.updatedAt)}
                                </Typography>
                              </Grid>
                              <Grid item xs="auto" borderColor="transparent">
                                <Rating
                                  name={`rating-${prompt._id}`}
                                  value={prompt.avgRating || 0}
                                  precision={0.1}
                                  readOnly
                                  emptyIcon={
                                    <StarBorderIcon style={{ color: "#cfcfcf" }} />
                                  }
                                />
                              </Grid>
                              <Grid item xs="auto">
                                <IconButton
                                  aria-label="copy"
                                  style={{ color: "#A9A9A9" }}
                                  onClick={() =>
                                    copyToClipBoard(
                                      prompt.promptContent,
                                      prompt._id
                                    )
                                  }
                                >
                                  <ContentCopyIcon />
                                  <Snackbar
                                    open={!!copiedMessage[prompt._id]}
                                    autoHideDuration={3000}
                                    onClose={() =>
                                      setCopiedMessage((prevMessages) => ({
                                        ...prevMessages,
                                        [prompt._id]: "",
                                      }))
                                    }
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "center",
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%, -50%)",
                                    }}
                                  >
                                    <Alert
                                      sx={{ width: "100%" }}
                                      onClose={handleSnackbarClose}
                                    >
                                      {copiedMessage[prompt._id]}
                                    </Alert>
                                  </Snackbar>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>

                  {Math.ceil(totalPrompts / itemsPerPage) > 1 && (
                    <Pagination
                      count={Math.ceil(totalPrompts / itemsPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  )}
                </Stack>
              ) : (
                <Grid container justifyContent="center">
                  {!promptsLoading && (
                    <Typography variant="h6" className="helper-title">
                      No Prompts have been created for this project
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6} md={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClickBack}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <CreatePromptDialog
          open={isCreateDialogOpen}
          onClose={closeCreateDialog}
          project_id={project_id}
          onCreate={onCreate}
        />

        <ImportPromptDialog
          open={dialogOpen}
          onClose={closeImportDialog}
          project_id={project_id}
          onImport={onImport}
        />

        <Dialog
          open={isShareDialogOpen}
          onClose={closeShareDialog}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle sx={{ backgroundColor: "#597697", color: "white" }}>
            Share Prompts to Projects
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} mt={2}>
              <Autocomplete
                id="share-prompts"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option?.projectName
                }
                disabled={selectedOptions.length === 5}
                filterOptions={(x) => x}
                options={filteredOptions}
                filterSelectedOptions
                freeSolo
                loading={loading}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Project Name"
                    helperText="Select maximum of 5 projects"
                  />
                )}
              />
              <Stack
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={1}
                mt={1}
              >
                {selectedOptions.map((option) => (
                  <React.Fragment key={option._id}>
                    <Button
                      variant="outlined"
                      endIcon={
                        <CloseIcon onClick={() => handleRemoveOption(option)} />
                      }
                    >
                      {option.projectName}
                    </Button>
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Button onClick={handleCloseAndClear} variant="contained">
                Cancel
              </Button>
              <Button
                disabled={selectedOptions.length === 0}
                onClick={handleShareClick}
                variant="contained"
              >
                Confirm
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <CustomDialog
          open={dialogAlertOpen}
          onClose={() => {
            setDialogAlertOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
      </Loader>
    </div>
  );
};
