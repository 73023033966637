import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export const getUserProfilePic = async (accessToken) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    // blob method
    const photoResponse = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options);
    const blob = await photoResponse.blob();
    return URL.createObjectURL(blob);

    // fetch method
    // return fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
    //     .then(response => response.json())
    //     .catch(error => console.log(error));
};
