import React, { useState, useEffect } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomDialog from "../../utils/dialogbox";
import "./style.css";
import { createComment, likeComment, replyComment,editComment, deleteComment,getCommentsByVersionNumber } from "../../api/comments";

const Comment = ({ from, promptId, versionNumber }) => {
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [comments, setComments] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [newComment, setNewComment] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [replyText, setReplyText] = useState("");
  const [editCommentText, setEditCommentText] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const multiline = true;

  useEffect(() => {
    fetchUpdatedComments();
  }, [promptId, versionNumber]);

  const fetchUpdatedComments = async () => {
    setLoading(true); 
    try {
      const response = await getCommentsByVersionNumber(promptId, versionNumber);
      if (response.success) {
        
        setComments(response?.data?.reverse()); 
      } else {
        setComments([]);
        
      }
    } catch (error) {
      console.error("Error occurred while fetching comments");
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while fetching comments");
      
    } finally {
      setLoading(false);
    }
};


  const openReplyField = (commentId) => {
    setReplyTo(commentId);
  };

  const closeReplyField = () => {
    setReplyTo(null);
    setReplyText("");
  };

  const handleReplyTextChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleEditComment = async (commentId) => {
    const commentToEdit = comments.find((comment) => comment._id === commentId);
    setEditingCommentId(commentId);
    setEditCommentText(commentToEdit.comment);
  };
  const handleOpenDeleteDialog = (commentId) => {
    setCommentToDelete(commentId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setCommentToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDeleteComment = async (commentId) => {
    handleOpenDeleteDialog(commentId);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      const response = await deleteComment(promptId, commentToDelete);
      if (response.success) {
        setComments((prevComments) =>
          prevComments.filter((comment) => comment._id !== commentToDelete)
        );
      } else {
        console.error("Error occurred while deleting comment",response.error);
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while deleting comment");
      }
    } catch (error) {
   
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while deleting comment");
    } finally {
      setLoading(false);
      handleCloseDeleteDialog();
    }
  };

  

  const handleLike = async (commentId) => {
    try {
      const response = await likeComment(promptId, commentId);
  
      if (response && response.success) {
        const updatedComments = comments.map(comment => {
          if (comment._id === commentId) {
            
            const hasLiked = comment.likes.includes(user.id);
            return {
              ...comment,
              likesCount: response.data.likesCount, 
              likes: hasLiked
                ? comment.likes.filter(like => like !== user.id) 
                : [...comment.likes, user.id] 
            };
          }
          return comment;
        });
  
        setComments(updatedComments);
  
      } else {
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while liking/disliking comment");
      }
    } catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while liking/disliking comment");
    }
  };
  
  

  const handleReplySubmit = async (commentId) => {
    try {
      if (replyText.trim().length > 0) {
         
          const response = await replyComment(
            promptId,
            commentId,
            replyText,
            user
          );
        if(response.success){
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === replyTo
              ? { ...comment, replies: [...comment.replies, response.data] }
              : comment
          )
        );
        
        closeReplyField();
      }

      else{
        console.error("Error occurred while replying to comment");
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while replying to comment");

      
    }
  }
    } catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while replying to comment");
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const isCommentValid = newComment.trim().length > 0;

  const formatDate = (timestamp) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(timestamp).toLocaleDateString(undefined, options);
  };

  const handleSendComment = async () => {
    try {
      if (isCommentValid) {
        const commentData = {
          comment: newComment,
        };
        const response = await createComment(promptId, commentData);
       if(response.success){
        fetchUpdatedComments();
       }
        setNewComment("");
      }
      else{
        console.error("Error occurred while sending comment");
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while sending comment");
      }
      
    }catch (error) {
      setDialogAlertOpen(true);
      setDialogMessage("Error occurred while sending comment");
      }
    
  
  };

  const handleSaveEdit = async (commentId) => {
    try {
      const response = await editComment(
        promptId,
        commentId,
        editCommentText
      );
      if (response.success) {
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === commentId
              ? { ...comment, comment: editCommentText }
              : comment
          )
        );
        setEditingCommentId(null);
        setEditCommentText("");
      } else {
        console.error("Error occurred while editig comment");
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while editing comment");
      }
    } catch (error) {
      
        setDialogAlertOpen(true);
        setDialogMessage("Error occurred while editing comment");
    }
  };

  return (
    <Grid xs={12}>
      {from === "promptDetailPage" && (
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Avatar
            src={user?.picture || "/broken-image.jpg"}
            alt={user?.name}
            className="commentUserAvatar"
          />
          <TextField
            fullWidth
            variant="standard"
            value={newComment}
            onChange={handleCommentChange}
            multiline={multiline}
            InputProps={{
              placeholder: "Add a comment...",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleSendComment}
                    color={isCommentValid ? "primary" : "default"}
                    disabled={!isCommentValid}
                  >
                    <SendIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      )}

      <Grid className="commentsBody">
        <List>
          {comments.map((comment) => (
            <React.Fragment key={comment._id}>
              <ListItem className="commentHeaderLineContainer">
                <ListItemAvatar className="commentUserAvatarContainer">
                  <Avatar
                    src={comment?.user?.picture || "/broken-image.jpg"}
                    alt={comment?.user?.name}
                    className="commentUserAvatar"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="body1DeepBlue">
                      {comment.user?.name}
                    </Typography>
                  }
                  secondary={formatDate(comment.createdAt)}
                />
                <ListItemSecondaryAction>
                  <Stack direction="row" gap={0}>
                    <Stack direction="row">
                      <IconButton
                        color="rgba(0, 0, 0, 0.26)"
                        variant="outlined"
                        edge="end"
                        disabled={from === "versionPage"}
                        onClick={() => handleLike(comment._id)}
                      >
                        {comment?.likes?.includes(user.id) ? (
                          <ThumbUpAltIcon fontSize="small" />
                        ) : (
                          <ThumbUpIcon fontSize="small" />
                        )}
                      </IconButton>
                      <Typography
                        variant="body3Primary"
                        color="rgba(0, 0, 0, 0.54)"
                      >
                        {comment.likes.length}
                      </Typography>
                    </Stack>
                    {from === "promptDetailPage" && (
                      <>
                        {editingCommentId !== comment._id && (
                          <IconButton
                            variant="standard"
                            onClick={() => openReplyField(comment._id)}
                          >
                            <Typography variant="body1Bold">Reply</Typography>
                          </IconButton>
                        )}
                        {user.id === comment.user?._id && (
                          <>
                            {editingCommentId === comment._id ? (
                              <>
                                <IconButton
                                  color="rgba(0, 0, 0, 0.26)"
                                  variant="outlined"
                                  onClick={() => handleSaveEdit(comment._id)}
                                >
                                  <SendIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  className="replayCancelButton"
                                  color="rgba(0, 0, 0, 0.26)"
                                  variant="outlined"
                                  onClick={() => {
                                    setEditingCommentId(null);
                                    setEditCommentText("");
                                  }}
                                >
                                  <Typography variant="body1Bold">
                                    Cancel
                                  </Typography>
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  color="rgba(0, 0, 0, 0.26)"
                                  variant="outlined"
                                  onClick={() => handleEditComment(comment._id)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  color="rgba(0, 0, 0, 0.26)"
                                  variant="outlined"
                                  onClick={() =>
                                    handleDeleteComment(comment._id)
                                  }
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem className="commentContentListItem">
                {editingCommentId === comment._id ? (
                  <TextField
                    fullWidth
                    multiline={multiline}
                    value={editCommentText}
                    onChange={(e) => setEditCommentText(e.target.value)}
                    className="CommentTextField"
                  />
                ) : (
                  <Typography variant="body1" className="commenttypography">
                    {comment.comment}
                  </Typography>
                )}
              </ListItem>

              {replyTo === comment._id && (
                <ListItem>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    gap={1}
                    className="replyRowStack"
                  >
                    <Avatar
                      src={comment?.user?.picture || "/broken-image.jpg"}
                      alt={comment?.user?.name}
                      className="commentUserAvatar"
                    />
                    <TextField
                      placeholder="Add a Reply..."
                      fullWidth
                      value={replyText}
                      onChange={handleReplyTextChange}
                    />
                    <IconButton color="primary" onClick={() => handleReplySubmit(comment._id)}>
                      <SendIcon fontSize="small" />
                    </IconButton>
                    <IconButton color="default" onClick={closeReplyField}>
                      <Typography variant="body1Bold">Cancel</Typography>
                    </IconButton>
                  </Stack>
                </ListItem>
              )}

              {comment.replies.map((reply) => (
                <ListItem
                  key={reply._id}
                  className="commentHeaderLineContainer"
                >
                  <ListItemAvatar className="commentUserAvatarContainer">
                    <Avatar
                      src={reply?.user?.picture || "/broken-image.jpg"}
                      alt={reply?.user?.name}
                      className="commentUserAvatar"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="body1DeepBlue">
                        {reply.user.name}
                      </Typography>
                    }
                  />
                  <ListItem>
                    <TextField
                      value={reply.comment}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      multiline={multiline}
                      className="CommentTextField"
                    />
                  </ListItem>
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} fullWidth>
        <DialogTitle>
          <Typography variant="h6">Delete Comment</Typography>
        </DialogTitle>
        <DialogContent dividers spacing={2} alignItems={"flex-start"}>
          <Typography variant="body1">
            Are you sure you want to delete this comment?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto" disabled={loading}>
              <Button onClick={handleCloseDeleteDialog} variant="contained">
                Close
              </Button>
            </Grid>
            <Grid item xs="auto" disabled={loading}>
              <Button onClick={handleConfirmDelete} variant="contained">
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={dialogAlertOpen}
        onClose={() => setDialogAlertOpen(false)}
        title="Prompt Manager"
      >
        {dialogMessage}
      </CustomDialog>
    </Grid>
  );
};

export default Comment;
