import { Stack,Typography } from "@mui/material";
import ProjectCard from "../ProjectCard/";

export default function ProjectList({ projects, from }) {
  let itemsPerPage = 6;
  if (from === "createProject") {
    itemsPerPage = 2;
  }

  const currentProjects = projects?.slice(0, itemsPerPage) || [];

  return (
    <>
      {currentProjects.length !== 0 ? (
        <Stack spacing={2}>
          {currentProjects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </Stack>
      ) : (
        <Typography variant="h6" className="helper-title">
          No projects created
        </Typography>
      )}
    </>
  );
}
