import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";

// create a new comment
export const createComment = async (promptId, comment) => {
  try {
    const response = await webApiService.post(`${promptId}/comments`, comment);
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// get comments by version number
export const getCommentsByVersionNumber = async (promptId, versionNumber) => {
  try {
    const response = await webApiService.get(
      `${promptId}/comments/${versionNumber}`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// like a comment
export const likeComment = async (promptId, commentId) => {
  try {
    const response = await webApiService.post(
      `${promptId}/comments/${commentId}/like`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return {data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// reply a comment
export const replyComment = async (promptId, commentId, reply) => {
  try {
    const response = await webApiService.post(
      `${promptId}/comments/${commentId}/reply`,
      { reply }
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return {data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// update a request
export const editComment = async (promptId, commentId, comment) => {
  try {
    const response = await webApiService.put(
      `${promptId}/comments/${commentId}`,
      { comment }
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// delete a comment
export const deleteComment = async (promptId, commentId) => {
  try {
    const response = await webApiService.delete(
      `${promptId}/comments/${commentId}`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if ([responseConstants.OK, responseConstants.NOT_FOUND].includes(status)) {
      return { data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};
