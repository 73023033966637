import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function SnackbarComponent({message, severity, variant, open, handleClose,hideDuration = 3000}) {
  const onClose = (event) => {
    if(handleClose) {
      handleClose(event)
    }
  }
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={hideDuration} onClose={onClose}>
    <Alert
        onClose={handleClose}
        severity={severity}
        variant={variant}
        sx={{ width: '100%' }}
    >
        {message}
    </Alert>
    </Snackbar>
  );
}