import axios from "axios";
import { SERVICE_URL } from "../utils/constants";
const apiUrl = SERVICE_URL;

export function getPromptsByProjectId(project_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/${project_id}/prompts`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function sharePrompt(project_id, prompt_id, projectIds) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiUrl}/${project_id}/prompts/${prompt_id}/share`,
        { projectIds },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function shareMultiplePrompt(project_id, promptIds, projectIds) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiUrl}/${project_id}/prompts/share`,
        {
          promptIds: promptIds,
          projectIds: projectIds,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        resolve(response);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
export function getPromptByPromptId(project_id, prompt_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/${project_id}/prompts/${prompt_id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function importPrompt(project_id, prompt) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiUrl}/csvUpload/${project_id}/import`,
        prompt,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        
        if (response.status == 200) {
          resolve({ success: true, data: response.data });
        } else {
          resolve({ success: false, data: {},message: response?.response?.data });
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
export function createNewPrompt(project_id, prompt) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${apiUrl}/${project_id}/prompts`,
        prompt,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        
        if (response.status == 200) {
          resolve({ exists: false, data: response.data });
        } else {
          resolve({ exists: true, data: response?.response?.data });
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export function updatePromptByPromptId({ project_id, prompt_id, prompt }) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${apiUrl}/${project_id}/prompts/${prompt_id}`,
        { ...prompt },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          resolve({ exists: false, data: response.data });
        } else {
          resolve({ exists: true, data: response?.response?.data });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function deletePromptById({ project_id, prompt_id }) {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${apiUrl}/${project_id}/prompts/${prompt_id}`,

        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getRecentPromptsByProjectId(project_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/${project_id}/prompts/recent`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        //   console.log("response.data in service: ", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}

export async function callLLM(model_id, inputText) {
  try {
    const res = await axios.post(`${apiUrl}/models/callLLM`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      timeout: 10000,
      model_id,
      inputText,
    });

    return res;
  } catch (err) {
    throw new Error(err);
  }
}


export async function updatePromptAndItsVersionWithContextSentiment (prompt,project_id,prompt_id,promptContent) {
  try {

      await axios.put(`${apiUrl}/${project_id}/prompts/${prompt_id}/updateContextSent`, {
        prompt_content: promptContent, contextStrength: prompt.contextStrength, sentiment: prompt.sentiment , contextReason: prompt.contextReason
      })

    } 
    catch (error) {
      console.error("Error: Failed to update Context and Sentiment for the Prompt and its Version", error);
    }
  
  } 
