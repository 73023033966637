import { CircularProgress, Stack, Typography, Backdrop } from "@mui/material";
export default function Loader(props) {
  const isLoading = props.loading || false;
  return (
    <>
     {props.children || <></>}
     <Backdrop open={isLoading} invisible={props?.invisible || true } transitionDuration={{exit:700}}>
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
            width={"100%"}
            height={"100%"}
            sx={{
              zIndex: 9999,
            }}
          >
            <CircularProgress color={"primary"} disableShrink />

            <Typography varaint="subtitle">
              {props.text || "Loading"}
            </Typography>
          </Stack>
        </Backdrop>
    </>
  );
}
