import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";

// Create Prompt
export const createPrompt = async (projectId, promptsData) => {
  try {
    const response = await webApiService.post(
      `/${projectId}/prompts/`,
      promptsData
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, exists: false, success: true };
    } else if (status == responseConstants.ALREADY_EXIST) {
      return {
        data: data.data,
        duplicate: true,
        error: data?.message,
        success: true,
      };
    } else if (status == responseConstants.PROMPT_TITLE_EXIST) {
      return {
        data: data.data,
        titleExists: true,
        error: data?.message,
        success: true,
      };
    } else if (status == responseConstants.PROMPT_CONTENT_EXIST) {
      return {
        data: data.data,
        contentExists: true,
        error: data?.message,
        success: true,
      };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Update Prompt
export const updatePrompt = async (projectId, promptId, promptsData) => {
  try {
    const response = await webApiService.put(
      `/${projectId}/prompts/${promptId}`,
      promptsData
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, exists: false, success: true };
    } else if (status == responseConstants.ALREADY_EXIST) {
      return {
        data: data.data,
        duplicate: true,
        error: data?.message,
        success: true,
      };
    } else if (status == responseConstants.PROMPT_TITLE_EXIST) {
      return {
        data: data.data,
        titleExists: true,
        error: data?.message,
        success: true,
      };
    } else if (status == responseConstants.PROMPT_CONTENT_EXIST) {
      return {
        data: data.data,
        contentExists: true,
        error: data?.message,
        success: true,
      };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

//update Context

export const updateContext = async (projectId, promptId, promptsData) => {
  try {
    const response = await webApiService.put(
      `/${projectId}/prompts/${promptId}/context`,
      promptsData
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true };
    } else if (status == responseConstants.ALREADY_EXIST) {
      return {
        data: data.data,
        exists: true,
        error: data?.message,
        success: true,
      };
    } else if (status == responseConstants.NOT_FOUND) {
      return { data, success: false, error: data?.message };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Delete Prompt
export const deletePrompt = async (projectId, promptId) => {
  try {
    const response = await webApiService.delete(
      `/${projectId}/prompts/${promptId}`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if ([responseConstants.OK, responseConstants.NOT_FOUND].includes(status)) {
      return { data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Fetch Single Prompt by ID
export const fetchPromptById = async (projectId, promptId) => {
  try {
    const response = await webApiService.get(
      `/${projectId}/prompts/${promptId}`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data?.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Fetch All Prompts in project
export const fetchAllPrompts = async (
  projectId,
  sortObject,
  sortOrder,
  page = 1,
  limit = 50
) => {
  try {
    const response = await webApiService.get(`/${projectId}/prompts/`, {
      params: { page, limit, sortObject, sortOrder },
    });
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data?.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Fetch Prompt version
export const fetchPromptVersionsById = async (projectId, promptId) => {
  try {
    const response = await webApiService.get(
      `/${projectId}/prompts/${promptId}/versions`
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data?.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

// Import Prompt File
export const importPrompt = async (projectId, csvFile) => {
  try {
    const formData = new FormData();
    formData.append("file", csvFile);

    const response = await webApiService.postForm(
      `/${projectId}/prompts/import`,
      formData
    );

    let status = response?.data?.code;
    let data = response?.data || {};

    if (status === responseConstants.OK) {
      return { data: data.data, exists: false, success: true };
    } else if (status === responseConstants.ALREADY_EXIST) {
      return {
        data: data.data,
        exists: true,
        error: data?.message,
        success: true,
      };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};

//update Context
export const ratePrompt = async (projectId, promptId, ratingData) => {
  try {
    const response = await webApiService.put(
      `/${projectId}/prompts/${promptId}/rate`,
      ratingData
    );
    let status = response?.data?.code;
    let data = response?.data || {};
    if (status == responseConstants.OK) {
      return { data: data.data, success: true };
    } else {
      return { success: false, error: data?.message };
    }
  } catch (error) {
    throw error;
  }
};
