import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Grid,
  Button,
  Alert,
  Input
} from "@mui/material";
import React, { useState } from "react";
import {importPrompt} from "../../api/prompt";
import Loader from "../Loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";


const ImportPromptDialog = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");

  const onImport = async () => {
    try {
      if (!selectedFile) {
        setFileUploadError("Please select a CSV file.");
        return;
      }
      setFileUploadError("");
      setLoading(true);
      const result = await importPrompt(props.project_id, selectedFile);
      console.log(result)
      if (result?.success) {
        if(props.onImport) {
          props.onImport(result?.data || [])
        }
        resetState()
      } else {
        setFileUploadError(
          result?.error || "Error while importing prompt. Please Try again"
        );
      }
    } catch (err) {
      setFileUploadError("Error while importing prompt. Please Try again");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFileUploadError("");
    const file = event.target.files[0];

    if (file && file.type === "text/csv") {
      setSelectedFile(file);
    } else {
      setFileUploadError("Select .csv file");
      setSelectedFile(null);
    }
  };

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
    resetState();
  };
  const resetState = () => {
    setSelectedFile(null);
    setLoading(false);
    setFileUploadError("")
  };
  return (
    <>
      <Dialog open={props?.open || false} fullWidth maxWidth="md">
        <DialogTitle>
          <Stack direction="row"  alignItems={"center"} spacing={1}>
            <CloudUploadIcon />
            <Typography variant="h6">
              Select a CSV file to import prompts
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Loader loading={loading}>
            <Stack spacing={2}>
              <Alert severity="warning">
                Note: Duplicate prompts will not be imported
              </Alert>

              <Input
                type="file"
                inputProps={{ accept: ".csv" }}
                onChange={handleFileChange}
                required
                error={!!fileUploadError}
                helperText={fileUploadError}
                fullWidth
              />
              
                <Typography variant="body2" style={{ color: "red" }}>
                  {fileUploadError || ""}
                </Typography>
             
            </Stack>
          </Loader>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto">
              <Button
                onClick={closeDialog}
                disabled={loading}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={onImport}
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportPromptDialog;
