import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
  Grid,
  Icon,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
export default function RequestAccessDialog(props) {

  let { user,projectDetails,openDialog,handleCancel, onRequest} = props;
  const [isActionProcessing, setIsActionProcessing] = useState(false);
  const [requestFormInput, setRequestFormInput] = useState({description:"",role:""});
  
  const handleCancelClick = async (event) => {
    if(handleCancel) {
        setIsActionProcessing(true);
        await handleCancel(event);
        setIsActionProcessing(false);
    }
  }

   const onRequestCallback = async (event) => {
    if(onRequest) {
        setIsActionProcessing(true);
        let request = {
          user : user?.id,
          projectId: projectDetails?._id,
          ...requestFormInput
        }
        await onRequest(request);
        setIsActionProcessing(false);
    }
  }
  return (
    <div>
      <Dialog
        open={openDialog}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`alertDialogBox`}
      >
        <DialogTitle sx={{ bgcolor: "primary.main" }}>
          <Stack direction="row" spacing={1} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h6" sx={{ color: "text.navbar" }}>
              Request Access
            </Typography>
            <IconButton
                  color="inherit"
                  onClick={handleCancelClick}
                >
                    <CloseIcon sx={{ color: "white" }}  fontSize="small"/>
                </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mt: 2 }}>
            <Stack spacing={2}>
              <Stack>
                <Typography variant="body1">Name:</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={user?.name}
                  inputProps={{ readOnly: true }}
                />
              </Stack>
              <Stack>
                <Typography variant="body1">Access To:</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={projectDetails?.projectName}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="body1">Request Description:</Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={requestFormInput?.description || ""}                  
                  onChange={(e) => setRequestFormInput({...requestFormInput, description : e.target.value})}
                  required
                />
              </Stack>

              <Stack>
                <Typography variant="body1">Desired Role:</Typography>
                <FormControl fullWidth>
                  <Select
                  value={requestFormInput?.role}
                  defaultValue=""
                  onChange={(e) => setRequestFormInput({...requestFormInput, role : e.target.value})}
                  >
                    <MenuItem value="admin">Project Admin</MenuItem>
                    <MenuItem value="poweruser">Power User</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <Divider orientation="horizontal" variant="middle" color="divider" />
        <DialogActions>
          <Grid container justifyContent={"flex-end"}>
            <Grid item xs="auto">
              <Button
                onClick={onRequestCallback}
                color="primary"
                variant="contained"
                disabled={isActionProcessing}
                autoFocus
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}