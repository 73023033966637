const NAME_MAX_CHAR_LEN = 100
const DESCRIPTION_MAX_CHAR_LEN = 400

export { NAME_MAX_CHAR_LEN, DESCRIPTION_MAX_CHAR_LEN }
export const SERVICE_URL = process.env.REACT_APP_BACKEND_PROXY;

export const responseConstants = {
    OK: 200,
    INTERNAL_SERVER_ERROR: 500,
    NOT_FOUND: 404,
    CREATED: 201,
    ALREADY_EXIST: 409,
    PROMPT_TITLE_EXIST: 410,
    PROMPT_CONTENT_EXIST: 411,
    INVALID_REQUEST: 400,
    SERVICE_UNAVAILABLE: 503,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401
}
export const APP = process.env.REACT_APP_NAME || 'prompt-manager';
