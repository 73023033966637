import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function CustomDialog({ open, onClose, title, children, onDialogClose,  }) {
  const handleClose = () => {
    onClose(); // This will close the dialog
    if (onDialogClose) onDialogClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "90%", // Increase width here
          maxWidth: "600px", // Adjust maxWidth as needed
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "#597697", color: "white" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        {" "}
        {/* Adjust padding as needed */}
        <DialogContentText sx={{ padding: "20px 0" }}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center", backgroundColor: "#f0f0f0" }}
      >
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#597697",
            color: "white", textTransform: "unset",
            "&:hover": { backgroundColor: "#486580" },
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
