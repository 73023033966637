import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import SignIn from "./pages/SignIn";
import NewProjects from "./pages/NewProjectPage";
import NewPrompt from "./pages/NewPrompt";
import EditProjectPage from "./pages/EditProjectPage";
import styles from "./App.module.css";
import Projects from "./pages/Projects/index";
import MyProjects from "./pages/MyProjects";
import PromptDetail from "./pages/PromptDetail";
import Versions from "./pages/Versions";
import ProjectAdminConsole from "./pages/ProjectAdminConsole";
import GlobalAdminConsole from "./pages/GlobalAdminConsole";
import ProjectDetail from "./pages/ProjectDetailPage/";
import CreateTopicPage from "./pages/CreateTopicPage/CreateTopicPage";
import ProjectAuth from "./components/ProjectAuth/";
import EditTopicPage from "./pages/EditTopicPage";
import LogoutComponent from "./components/Logout/Logout";
import {  ThemeProvider } from "@mui/material/styles";
import { PromptsPage } from "./pages/PromptsPage/";
import { productTheme } from "./theme";
import RequestAccess from "./pages/RequestAccessPage/RequestAccessPage";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./components/MicrosoftLogIn/authConfig";
import Notifications from "./pages/Notifications";
import { useSelector,useDispatch } from "react-redux";
import { getUserInfo, isUserAuthenticated } from "./app/userSlice";
import { setNotificationCount } from "./app/notificationSlice";
import { fetchNotificationCounts } from "./api/alerts";
import { useEffect } from "react";

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  const FETCH_INTERVAL = 5*60*1000;
  const user = useSelector(getUserInfo);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch();

  let checkForNotification = async ()=> {
    if(!isAuthenticated) return;
    let res = await fetchNotificationCounts();
    if(res.success) {
       dispatch(setNotificationCount({
          alertsCount: res.data?.alertsCount || 0,
          accessRequestCount: res.data?.accessRequestCount || 0,
          sharedPromptCount: res.data?.sharedPromptCount || 0,
       }))
    }
  }

  useEffect(()=>{
    let countInterval = setInterval(checkForNotification ,FETCH_INTERVAL)
    return () => {
     clearInterval(countInterval);
    }

  },[])

  useEffect(()=>{
    checkForNotification();
  },[isAuthenticated])

  return (
    <ThemeProvider theme={productTheme}>
      <div className={styles.appContainer}>
        <Router>
          <MsalProvider instance={msalInstance}>
            <Header />
            <div className={styles.content}>
              <Routes>
                <Route path="/requestaccess" element={<RequestAccess />} />
                <Route path="/logout" element={<LogoutComponent />} />
                {isAuthenticated && (
                  <>
                    <Route path="/Projects" element={<Projects />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/createtopic" element={<CreateTopicPage />} />
                    <Route path="/edittopic" element={<EditTopicPage />} />
                    <Route path="/home" element={<MyProjects />} />
                    <Route path="/:user_id/Projects" element={<MyProjects />} />
                    <Route
                      path="/:project_id/detail"
                      element={<ProjectDetail />}
                    />
                    <Route path="/new_project" element={<NewProjects />} />
                    <Route
                      element={
                        <ProjectAuth
                          roles={{
                            projectRoles: ["user", "admin", "poweruser","creator"],
                            userRole: ["admin"],
                          }}
                        />
                      }
                    >
                      <Route
                        path="/:project_id/prompts"
                        element={<PromptsPage />}
                      />
                      <Route
                        path="/:project_id/prompts/:prompt_id/versions"
                        element={<Versions />}
                      />
                    </Route>
                    <Route
                      path="/:project_id/prompts/:prompt_id"
                      element={<PromptDetail />}
                    />
                    <Route
                      element={
                        <ProjectAuth
                          roles={{
                            projectRoles: ["admin", "poweruser"],
                            userRole: ["admin"],
                          }}
                        />
                      }
                    >
                      <Route path="/:project_id/new" element={<NewPrompt />} />
                    </Route>
                    <Route
                      element={
                        <ProjectAuth
                          roles={{
                            projectRoles: ["admin","creator","poweruser"],
                            userRole: ["admin"],
                          }}
                        />
                      }
                    >
                      <Route
                        path="/:project_id/edit"
                        element={<EditProjectPage />}
                      />
                      <Route
                        path="/:project_id/project_admin"
                        element={<ProjectAdminConsole />}
                      />
                    </Route>
                    {user?.role == "admin" && (
                      <Route
                        path="/global_admin"
                        element={<GlobalAdminConsole />}
                      />
                    )}
                  </>
                )}

              <Route path="*" element={<SignIn />} />
              </Routes>
            </div>
          </MsalProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
