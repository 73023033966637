import React, { useState, useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import { getProjectSuggestions } from "../../api/project";
import { Divider } from "@mui/material";


export default function ProjectSearchBar() {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        getProjectSuggestions(request.input,1,20).then((res) => {
          if (callback) {
            callback(res?.data?.projects || []);
          }

          setLoading(false);
        });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="search-prompts"
      sx={{ width: "50%", fontFamily: "Myriad Pro" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.projectName
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={value}
      freeSolo
      loading={loading}
      noOptionsText="Enter Project Title"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search Projects"
          fullWidth
          sx={{
            color: "#8e8e8e",
            bgcolor: "#e7e7e7",
            fontFamily: "Myriad Pro",
            borderRadius: "10px",
            "& .MuiInputBase-input": {
              padding: "6px 32px 6px 12px",
            },
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            onClick={() => {
              setValue("");
              navigate(`/${option._id}/detail`, { replace: true });
            }}
          >
            <Grid container alignItems="center">
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {option.projectName}
                <Typography variant="body2" color="text.secondary">
                  {option.projectDescription}
                </Typography>
                <Divider />
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
