import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  InputAdornment,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { useSelector } from "react-redux";
import CustomDialog from "../../utils/dialogbox";
import {
  NAME_MAX_CHAR_LEN,
  DESCRIPTION_MAX_CHAR_LEN,
} from "../../utils/constants";
import AddProjectUserTable from "../AddProjectUserTable";
import TopicAutoComplete from "../TopicAutoComplete";
import { getAllModelsForUser } from "../../api/model";
import { createProject, updateProject } from "../../api/project";
import "./style.css";
import Loader from "../Loader";

function NewProjectComponent(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [onDialogClose, setOnDialogClose] = useState(() => () => {});

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [onErrorDialogClose, setOnErrorDialogClose] = useState(() => () => {});

  let location = useLocation();

  const [projectName, setProjectName] = useState(props.projectName || "");
  const [projectDescription, setProjectDescription] = useState(
    props.projectDescription || ""
  );
  const [topic, setTopic] = useState(
    props.topic || (location.state && location.state.topic) || []
  );
  const [defaultModel, setDefaultModel] = useState(props.defaultModel || "");
  const [models, setModels] = useState([]);
  const [addUser, setAddUser] = useState(props.addUser || []);
  const originalUsers = props.addUser;

  const [projectNameError, setProjectNameError] = useState("");
  const [projectDescriptionError, setProjectDescriptionError] = useState("");
  const [topicError, setTopicError] = useState("");
  const [defaultModelError, setDefaultModelError] = useState("");

  const [loading, setLoading] = useState(props?.loading || false);

  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { project_id } = useParams();

  useEffect(() => {
    setLoading(true);
    fetchModelsAndTopics();
  }, []);

  const handleError = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setOnDialogClose(() => () => {
      navigate(-1);
    });
  };

  const fetchModelsAndTopics = async () => {
    try {
      // fetch models
      const modelsResponse = await getAllModelsForUser();
      if (!modelsResponse.success) {
        handleError(
          "Error occured while getting models. Please try again."
        );
      } else {
        setModels(modelsResponse.data);
      }
      // fetch topics
      // click on "Add a topic" and come back
      if (
        location.state.from &&
        location.state.from === "createTopicPage" &&
        location.state.projectCache
      ) {
        setProjectName(location.state.projectCache.projectName);
        setProjectDescription(location.state.projectCache.projectDescription);
        setTopic(location.state.projectCache.topic);
        setDefaultModel(location.state.projectCache.defaultModel);
        setAddUser(location.state.projectCache.addUser);
      }
    } catch (error) {
      console.error("error fetching the models", error);
      handleError("Error occured while getting models and topics. Please try again.")
    } finally {
      setLoading(false);
    }
  };

  const handleClickAddATopic = () => {
    navigate("/createtopic", {
      state: {
        from:
          props.createOrEdit === "Create"
            ? "createProjectPage"
            : "editProjectPage",
        rootFrom: location.state.from,
        projectId: props.createOrEdit === "Edit" ? project_id : null,
        projectCache: {
          projectName,
          projectDescription,
          topic,
          defaultModel,
          addUser: addUser.map((user) => ({
            ...user,
            role: user.role || "user",
          })),
        },
      },
    });
  };

  const handleProjectNameChange = (event) => {
    const newValue = event.target.value;
    setProjectName(newValue);
    if (!newValue) {
      setProjectNameError("Project name is required");
    } else {
      setProjectNameError("");
    }
  };

  const handleProjectDescriptionChange = (event) => {
    const newValue = event.target.value;
    setProjectDescription(newValue);
    if (!newValue) {
      setProjectDescriptionError("Project description is required");
    } else {
      setProjectDescriptionError("");
    }
  };

  const handleTopicChange = (newTopic) => {
    if (newTopic?.length < 1) {
      setTopicError("Please select or create a Topic.");
    } else {
      setTopicError("");
    }
    setTopic(newTopic);
  };

  const handleDefaultModelChange = (event, newValue) => {
    setDefaultModel(newValue);
    if (newValue) {
      setDefaultModelError("");
    }
  };

  const handleCancel = () => {
    if (location.state.from && location.state.from === "createTopicPage") {
      navigate(-2);
    } else if (location.state.from && location.state.from === "myProjectPage") {
      navigate("/home");
    } else if (location.state.from && location.state.from === "projectsPage") {
      navigate("/Projects");
    } else {
      navigate("/global_admin", { state: { from: "/editProject" } });
    }
  };

  const checkValid = () => {
    let isValid = true;
    if (!projectName) {
      setProjectNameError("Please enter Project Name.");
      isValid = false;
    } else {
      setProjectNameError("");
    }
    if (!projectDescription) {
      setProjectDescriptionError("Please enter Project Description.");
      isValid = false;
    } else {
      setProjectDescriptionError("");
    }
    if (topic?.length < 1) {
      setTopicError("Please select or create a Topic.");
      isValid = false;
    } else {
      setTopicError("");
    }
    if (!defaultModel) {
      setDefaultModelError("Please select default Model.");
      isValid = false;
    } else {
      setDefaultModelError("");
    }
    return isValid;
  };

  const handleCreateOrEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let isValid = checkValid();
      if (!isValid) return;

      let response;
      if (
        props.createOrEdit === "Edit" ||
        (location.state.origin && location.state.origin === "editProjectPage")
      ) {
        const userWithRoles = addUser.map((user) => ({
          user: user._id,
          role: user.role || "user",
        }));
        const originalMap = new Map(
          originalUsers.map((user) => [user._id, user])
        );
        let results = [];
        userWithRoles.forEach((user) => {
          if (originalMap.has(user.user)) {
            const originalUser = originalMap.get(user.user);
            if (user.role !== originalUser.role) {
              results.push({
                user: user.user,
                role: user.role,
                action: "update",
              });
            }
            originalMap.delete(user.user);
          } else {
            results.push({ user: user.user, role: user.role, action: "add" });
          }
        });

        originalMap.forEach((value, key) => {
          results.push({ user: key, role: value.role, action: "delete" });
        });

        const projectData = {
          projectName,
          projectDescription,
          topic: topic?.map((x) => x?._id),
          defaultModel: defaultModel._id,
          roles: results,
        };
        response = await updateProject(project_id, projectData);
        if (response.exists) {
          setProjectNameError("Project with the same name already exists.");
          return;
        } else if (response.success === false) {
          setErrorDialogMessage(
            "Error Occurred While Editing The Project, Please Try Again."
          );
          setErrorDialogOpen(true);
          setOnErrorDialogClose(() => () => {});
          return;
        }
      } else {
        const projectData = {
          projectName,
          projectDescription,
          topic: topic?.map((x) => x?._id),
          defaultModel: defaultModel._id,
          roles: addUser.map((user) => ({
            user: user._id,
            role: user.role || "user",
          })),
        };
        response = await createProject(projectData);
        if (response.exists) {
          setProjectNameError("Project with the same name already exists.");
          return;
        } else if (response.success === false) {
          setErrorDialogMessage(
            "Error Occurred While Creating The Project, Please Try Again."
          );
          setErrorDialogOpen(true);
          setOnErrorDialogClose(() => () => {});
          return;
        }
      }
      setProjectName("");
      setProjectDescription("");
      setTopic([]);
      setDefaultModel("");
      setAddUser([]);
      setDialogMessage(
        `Project has been ${
          props.createOrEdit === "Edit" ? "Edited" : "Created"
        } Successfully!`
      );
      setDialogOpen(true);
      setOnDialogClose(() => () => {
        if (
          props.from === "globalAdminConsolePage" ||
          (location.state.rootFrom &&
            location.state.rootFrom === "globalAdminConsolePage")
        ) {
          navigate("/global_admin", { state: { from: "/editProject" } });
        } else if (
          props.from === "projectsPage" ||
          props.from === "myProjectPage"
        ) {
          navigate(
            props.from === "myProjectPage"
              ? `/${user.id}/projects`
              : "/projects"
          );
        } else {
          navigate("/projects");
        }
      });
    } catch (error) {
      setDialogMessage(
        `Error occured while  ${
          props.createOrEdit === "Edit" ? "Editing" : "Creating"
        } projects. Please try again.`
      );
      setDialogOpen(true);
      setOnDialogClose(() => () => {});
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loader loading={loading}>
        <Card elevation={2}>
          <CardContent>
            <Grid container spacing={3}>
              {/* New Project Details */}
              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>
                      {props.createOrEdit === "Edit"
                        ? "Edit Project"
                        : "New Project"}
                    </b>
                  </Typography>
                </Grid>

                {/* Project Name */}
                <Grid item xs={12}>
                  <TextField
                    label="Project Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={projectName}
                    inputProps={{ maxLength: NAME_MAX_CHAR_LEN }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {`${projectName.length}/${NAME_MAX_CHAR_LEN}`}
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleProjectNameChange}
                    required
                    error={projectNameError !== ""}
                    helperText={projectNameError}
                  />
                </Grid>

                {/* Project Description */}
                <Grid item xs={12}>
                  <TextField
                    label="Project Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    value={projectDescription}
                    onChange={handleProjectDescriptionChange}
                    inputProps={{ maxLength: DESCRIPTION_MAX_CHAR_LEN }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="char-count">
                          {`${projectDescription.length}/${DESCRIPTION_MAX_CHAR_LEN}`}
                        </InputAdornment>
                      ),
                    }}
                    required
                    error={!!projectDescriptionError}
                    helperText={projectDescriptionError}
                  />
                </Grid>

                {/* Topic */}
                <Grid item xs={12}>
                  <TopicAutoComplete
                    from="createProjectPage"
                    value={topic}
                    setValue={handleTopicChange}
                    handleClickAddATopic={handleClickAddATopic}
                    inputTextFieldParams={{
                      error: !!topicError,
                      helperText: topicError,
                    }}
                  />
                </Grid>

                {/* Model */}
                <Grid item xs={12}>
                  <Autocomplete
                    options={models}
                    value={defaultModel}
                    onChange={handleDefaultModelChange}
                    required
                    defaultModelError
                    getOptionLabel={(option) =>
                      typeof option === "string"
                        ? option
                        : option?.modelDescription
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Default Model *"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={!!defaultModelError}
                        helperText={defaultModelError}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* User List */}
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6">Add Members to Project</Typography>
                </Grid>
                <Grid item xs={12}>
                  <AddProjectUserTable
                    data={addUser}
                    setData={setAddUser}
                    excludeIds={[user.id]}
                  />
                </Grid>
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12} container justifyContent={"space-between"}>
                <Grid item>
                  <Button
                    onClick={handleCancel}
                    variant="contained"
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={(e) => handleCreateOrEdit(e)}
                    variant="contained"
                    disabled={loading}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <CustomDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            onDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onDialogClose}
        >
          {dialogMessage}
        </CustomDialog>
        <CustomDialog
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false);
            onErrorDialogClose();
          }}
          title="Prompt Manager"
          onDialogClose={onErrorDialogClose}
        >
          {errorDialogMessage}
        </CustomDialog>
      </Loader>
    </>
  );
}

export default NewProjectComponent;
