import React, { useState, useEffect } from "react";
import NewTopicComponent from "../../components/NewTopicComponent";
import { useLocation } from "react-router-dom";
import { fetchTopicById } from "../../api/topics";
import Loader from "../../components/Loader";

export default function EditTopicPage({ topicId }) {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [topicData, setTopicData] = useState({});

  useEffect(() => {
    if (location.state && location.state.topicId) {
      fetchTopicById(location.state.topicId).then( async (data) => {
        console.log(data);
        setTopicData(data.data);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <>
    <Loader loading={isLoading}>
    {!isLoading && (
          <NewTopicComponent
            topicId={topicData._id}
            topicName={topicData.topicName}
            topicDescription={topicData.topicDescription}
            linkedProjects={topicData.linkedProjects}
          />
        )}
    </Loader>
    </>
  );
}
