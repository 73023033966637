import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/topics"

// Create Topic
export const createTopic = async (topicData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/`, topicData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,exists: false,success: true}
        } else if( status == responseConstants.ALREADY_EXIST) {
          return { data:data.data,exists:true,error: data?.message, success: true}
        } else {
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch Topics with Pagination
export const fetchTopics = async (page, limit,additionalParams) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/`, { params: { page, limit,...additionalParams } });
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch Topics by Name (for suggestions)
export const fetchTopicsByName = async (topicName, k) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/suggestions`, { params: { topicName, k } });
       
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch Topics for a Specific User
export const fetchUserTopics = async (page, limit) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/userTopics`, { params: { page, limit } });
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch Single Topic by ID
export const fetchTopicById = async (topicId) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/${topicId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data?.data?.topic,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Update Topic
export const updateTopic = async (topicId, topicData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${topicId}`, topicData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,exists: false,success: true}
        } else if( status == responseConstants.ALREADY_EXIST) {
          return { data:data.data,exists:true,error: data?.message, success: true}
        } else if (status == responseConstants.NOT_FOUND) {
          return {data,success: false,error:"Topic not found"}
        }else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Delete Topic
export const deleteTopic = async (topicId) => {
    try {
        const response = await webApiService.delete(`${BASE_URL}/${topicId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if([responseConstants.OK,responseConstants.NOT_FOUND].includes(status) ) {
          return {data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};
