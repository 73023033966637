import React, { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Typography,
  Button,
  Select,
  MenuItem,
  Stack,
  Tooltip,
  Grid,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setAccessRequestCount } from '../../app/notificationSlice'; // Correct path as needed
import { updateRequest, getAllRequests } from "../../api/projectAccess";
import Loader from "../../components/Loader";
import CustomDialog from "../../utils/dialogbox";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import InfoCard from "../../components/InfoCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import AlertDialogBox from "../../components/AlertDailogBox";
import SnackbarComponent from "../../components/SnackBar";

export default function AccessRequests() {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [declineRequestDialog, setDeclineRequestDialog] = useState(false);
  const [declineRequestInfo, setDeclineRequestInfo] = useState({});
  const [dialog, setDialog] = useState({ open: false, title: "", message: "" });
  const [savingRowId, setSavingRowId] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const dispatch = useDispatch();

  
    const fetchRequests = async () => {
      setIsLoading(true);
      try {
        const response = await getAllRequests();
        if (response.success) {
          setRequests(response.data);
          dispatch(setAccessRequestCount(response.data.length)); 
        } else {
          setDialog({
            open: true,
            title: "Error",
            message: response.error || "Failed to fetch requests",
          });
        }
      } catch (error) {
        setDialog({
          open: true,
          title: "Error",
          message: error.message || "An unexpected error occurred",
        });
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {    
      fetchRequests();
  }, [dispatch]);
 

  const toFormattedRole = (role) => {
    if (role === "admin") {
      return "Poject Admin";
    } else if (role === "poweruser") {
      return "Power User";
    } else if (role === "user") {
      return "User";
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "requestUser.name",
        header: "User",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip
            title={<InfoCard user={row?.original?.requestUser} />}
            placement="bottom-start"
            type="infocard"
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <Avatar
                src={row?.original?.requestUser?.picture || "/broken-image.jpg"}
                alt={renderedCellValue}
                aria-label="project"
                sx={{ cursor: "pointer" }}
              />
              <Typography variant="body1"> {renderedCellValue}</Typography>
            </Stack>
          </Tooltip>
        ),
      },
      {
        accessorKey: "description",
        header: "Description",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "project.projectName",
        header: "Requested Project",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "requestrole",
        header: "Requested Role",
        maxSize: 5,
        size: 5,
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">
            {toFormattedRole(renderedCellValue)}
          </Typography>
        ),
      },
      {
        header: "Actions",
        // minSize: 100,
        enableGlobalFilter: false,
        enableEditing: false,
        Cell: ({ row }) => {
          return (
            <Grid container alignItems="center" spacing={1}>
              <Grid item sx={{ width: "130px"}}>
                <FormControl fullWidth>
                  <Select
                    defaultValue=""
                    displayEmpty
                    onChange={(e) => handleRoleChange(e, row)}
                  >
                    <MenuItem value="" disabled>
                      Role
                    </MenuItem>
                    <MenuItem value="admin">Project Admin</MenuItem>
                    <MenuItem value="poweruser">Power User</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs="auto" justifyContent="center">
                {selectedRowId === row.original._id && (
                  <CheckCircleIcon
                    sx={{ color: "#597697", cursor: "pointer" }}
                    onClick={(e) => handleGrantAccess(e, row)}
                  />
                )}
                {savingRowId === row.original._id && (
                  <CircularProgress size={20} sx={{ color: "#597697" }} />
                )}
              </Grid>
              <Grid item xs="auto">
                <Tooltip title="Decline">
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    disabled={savingRowId === row.original._id}
                    onClick={() => handleOpenDeclineDialog(row)}
                  >
                    Decline
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          );
        },
      },
    ],
    [selectedRowId]
  );

  const table = useMaterialReactTable({
    columns,
    data: requests,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true,
    enableColumnActions: false,
    enableSorting: true,
    enableHiding: false,
    positionActionsColumn: "last",
    rowsPerPageOptions: [10, 20, 50, 100],
    icons: {
      SaveIcon: () => (
        <CheckCircleIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
      FilterListIcon: () => <SearchIcon />,
      FilterListOffIcon: () => <SearchOffIcon />,
      CancelIcon: () => (
        <CancelIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
    },
    getRowId: (row) => row?._id,
    initialState: { showColumnFilters: false },
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: {
      sx: {
        height: "50vh",
      },
    },
    state: {
      isLoading,
    },
  });

  const handleRoleChange = (e, row) => {
    setSelectedRowId(row?.original?._id);
  };

  const handleGrantAccess = async (e, row) => {
    setSelectedRowId(null);
    setSavingRowId(row.original._id);
    try {
      const requestData = {
        requestId: row.original._id,
        requestStatus: "accepted",
        role: e.target.value || "user",
      };

      const response = await updateRequest(
        row.original.project._id,
        requestData
      );
      if (response.success) {
        fetchRequests();
        setSnackBarMessage("User has the access to this project!");
        setSnackBarType("success");
        setShowSnackBar(true);

      } else {
        throw new Error(response.error || "Failed to update request");
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "Failed to update request",
      });
    } finally {
      setIsLoading(false);
      setSavingRowId(null);
    }
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
    setSnackBarMessage("");
  };

  const handleCloseDialog = () => setDialog({ ...dialog, open: false });

  const handleOpenDeclineDialog = (row) => {
    setDeclineRequestInfo(row);
    setDeclineRequestDialog(true);
  };

  const onDeclineRequest = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        requestId: declineRequestInfo.id,
        requestStatus: "denied",
      };
      const response = await updateRequest(
        declineRequestInfo.original.project._id,
        requestData
      );
      if (response.success) {
        fetchRequests();
        setSnackBarMessage("Access Denied!");
        setSnackBarType("success");
        setShowSnackBar(true);
      } else {
        throw new Error(response.error || "Failed to update request");
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "Failed to update request",
      });
    } finally {
      setIsLoading(false);
      onDeclineRequestDialogClose();
    }
  };

  const onDeclineRequestDialogClose = () => {
    setDeclineRequestDialog(false);
    setDeclineRequestInfo({});
  };

  return (
    <Loader loading={isLoading} text="">
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        onClose={handleCloseDialog}
      >
        {dialog.message}
      </CustomDialog>

      <MaterialReactTable table={table} />

      <SnackbarComponent
        severity={snackBarType}
        variant={"filled"}
        message={snackBarMessage}
        open={showSnackBar}
        handleClose={handleCloseSnackBar}
      />

      <AlertDialogBox
        openDialog={declineRequestDialog}
        title={"Decline Request"}
        body={`Are you sure you don't want to grant access ${declineRequestInfo?.original?.requestUser?.name} for ${declineRequestInfo?.original?.project?.projectName}?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        handleConfirm={onDeclineRequest}
        type={"info"}
        handleCancel={onDeclineRequestDialogClose}
      />
    </Loader>
  );
}
