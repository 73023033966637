/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { removeError, addError } from "./errorSlice";
import { getPromptsByProjectId } from "../services/prompts";

const initialState = {
  prompts: [],
  status: "idle",
};

export const getPromptsByProjectIdAction = createAsyncThunk(
  "application/getPromptsByProjectIdAction",
  async (data, thunkAPI) => {
    try {
      // console.log("data", data);
      const prompts = await getPromptsByProjectId(data);
      return prompts;
    } catch (error) {
      const { message } = error;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromptsByProjectIdAction.fulfilled, (state, action) => {
      state.status = "get prompts by project_id succeeded";
      state.prompts = action.payload;
    });
    builder.addCase(getPromptsByProjectIdAction.rejected, (state, action) => {
      state.status = "get prompts by project_id failed";
    });
    builder.addCase(getPromptsByProjectIdAction.pending, (state, action) => {
      state.status = "get prompts by project_id pending";
    });
  },
});

export default promptsSlice.reducer;
