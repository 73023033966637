import { createTheme } from "@mui/material/styles";

export const productTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#597697", // Navbar Color
    },
    secondary: {
      main: "#F6F4F3", // Cards color
    },
    divider: "#BDBDBD", //Divider lines in cards
    success: {
      main: "#4ddc8c",
    },
    info: {
      main: "#597697",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    text: {
      navbar: "#FFFFFF", //includes text color for all dialog title and navbar
      default: "#000000", //default text color is black
    },
    icon: "#FFFFFF", //Icon color in dialog box title
  },
  typography: {
    fontFamily: "Myriad Pro",
    fontWeightLight: 400,
    fontSize: 16,
    htmlFontSize: 16,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    

    h1: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.1,
    },
    h2: {
      fontSize: 25,
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 0.9,
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 0.9,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1,
    },
    h6: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.6,
    },
    h7: {
      fontSize: 18,
      color: "gray",
      fontFamily: "Myriad Pro",
    },
    h7: {
      fontSize: 18,
      color: "gray",
      fontFamily: "Myriad Pro",
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.52,
    },
    subtitle2: {
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1,
    },
    body1Bold: {
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 1,
    },
    body1DeepBlue: {
      fontSize: 16,
      lineHeight: 1,
      color: "rgba(7, 41, 80, 1)"
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.1,
    },
    body2Bold: {
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: 1.1,
    },
    body3Primary: {
      fontSize: 12,
      fontWeight: "bold",
      lineHeight: 1,
      color: "#2196F3",
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
      textTransform: "unset",
    },
    title: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 0.9,
      color: "#597697",
      fontFamily: "Myriad Pro",
    },
   statsTitle : {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  components: {
    MuiTypography : {
      styleOverrides:{

        root: {
          wordWrap: "break-word !important"
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.even-row": {
            backgroundColor: "#f0f0f0",
          },
          "&.odd-row": {
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.buttonType == "iconbuttonText" && {          
            padding: "2px",
            minWidth: "auto",
            color: "#597697",
            fontWeight: "bold",
            fontSize: "14px",
          }),
          ...(ownerState.buttonType == "iconbutton" && {          
            marginRight: 1,
            padding: "2px",
            minWidth: "auto",
            color: "#597697",
          }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ ownerState, theme }) => ({
          ...(ownerState.type == "infocard" && {
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "15px",
            backgroundColor: "#ffffff",
            color: "#000000",
            marginTop: 0,
            padding: "12px",
          }),
        }),
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { type: "readOnly" },
          style: {
            "& .Mui-disabled": {
              color: "black",
              "-webkit-text-fill-color": "black",
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...({
            fontSize: 18,
            color: "#9B9898",
            fontFamily: "Myriad Pro",
            // wordWrap: "break-word",
            textTransform: "none",
          }),
        }),
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.type == "infocard" && {
            backgroundColor: "#F6F6F6",
          }),
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.type == "infocard" && {
            cursor: "pointer",
            padding: "10px 10px 6px 16px",
            color: "rgba(0, 0, 0, 0.87)",
          }),
        }),
        content: ({ ownerState }) => ({
          ...(ownerState.type == "infocard" && {
            maxWidth: "90%",
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.type == "infocard" && {
            height: "8rem",
            overflow:"auto",
            flexGrow: 1,            
            borderTop: "1px solid #dcdcdc",
            borderBottom: "1px solid #dcdcdc",
          }),
        }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#597697",          
          color: "white",
        },
      },
    },
  
  
  
  
},
  background: {
    header: "#597697",
    footer: "#597697",
  },
  breakpoints: {
    values: {
      //min-width
      xs: 0, //Mobiles
      sm: 600, //Small Tablet|Large Mobile
      md: 960, //Big Tablet
      lg: 1024, //Full HD
      xl: 2048, //2K
      xxl: 2560, //4k
    },
  },
});
