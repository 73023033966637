import {
  Button,
  Typography,
  Avatar,
  IconButton,
  Stack,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { getProjectUsers, updateProjectRoles } from "../../api/project";
import CustomDialog from "../../utils/dialogbox";
import AddProjectUserTable from "../../components/AddProjectUserTable";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import AlertDialogBox from "../../components/AlertDailogBox";

const ProjectUsers = () => {
  const [data, setData] = useState([]);
  const [addUser, setAddUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const [addDialogBox, setAddDialogBox] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { project_id } = useParams();
  const [dialogMessage, setDialogMessage] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let res = await getProjectUsers(project_id);
      if (res.success) {
        setData(res.data || []);
      } else {
        setDialogMessage("Error occured while fetching users");
        setDialogOpen(true);
      }
    } catch (error) {
      setDialogMessage("Error occured while fetching users");
      setDialogOpen(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onDeleteUser = async () => {
    try {
      setIsLoading(true);
      if (deleteId) {
        let req = [
          {
            user: deleteId,
            action: "delete",
          },
        ];
        let response = await updateProjectRoles(project_id, req);
        if (response.success) {
          fetchData();
          setDeleteDialogBox(false);
          setDialogMessage("User has been removed successfully!");
          setDialogOpen(true);
        } else {
          setDialogMessage(
            "Error occured while removing user. Please try again later."
          );
          setDialogOpen(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setDialogMessage(
        "Error occured while removing user. Please try again later."
      );
      setDialogOpen(true);
      setIsLoading(false);
    }
  };
  // let userRoles = ["User", "Admin", "Power User", "Creator"]
  // const deriveUserRoles = (role) => {
  //   if(role == "user") return "User"
  //   if(role == "admin") return "Project Admin"
  //   if(role == "poweruser") return "Power User"
  //   if(role == "creator") return "Creator"
  // } 
  // const deriveUserRolesValues = (role) => {
  //   if(role == "User") return "user"
  //   if(role == "Project Admin") return "admin"
  //   if(role == "Power User") return "poweruser"
  //   if(role == "Creator") return "creator"
  // }
  let roles = [
    {
      label: "User",
      value: "user"
  },
  {
    label: "Project Admin",
    value: "admin"
},
{
  label: "Power User",
  value: "poweruser"
},
{
  label: "Creator",
  value: "creator"
},
  ]
  const columns = useMemo(
    () => [
      {
        accessorKey: "user.name",
        header: "User",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <Avatar
              src={row?.original?.user?.picture || "/broken-image.jpg"}
              alt={renderedCellValue}
              aria-label="project"
            />
            <Typography variant="body1"> {renderedCellValue}</Typography>
          </Stack>
        ),
      },
      {
        accessorKey: "user.email",
        header: "Email",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "role",
        header: "Role",
        enableGlobalFilter: true,
        editVariant: "select",
        editSelectOptions: roles,
        filterVariant: "select",
        filterSelectOptions:  roles.map(x=>x.label),
        muiEditTextFieldProps: {
          select: true,
          variant: "outlined",
        },
        Cell: ({ renderedCellValue }) => {          
          return <Typography variant="body1">{roles.filter(x => x.value == renderedCellValue).map(x =>x.label)?.[0] || renderedCellValue}</Typography>;
        },
      },
    ],
    []
  );

  const handleSaveUser = async ({ values, row, ...rest }) => {
    values._id = row.id;    
    let role =  values?.role || row.original?.role

    setIsUpdating(true);
    let rolesUpdates = [
      {
        user: row.id,
        action: "update",
        role,
      },
    ];
    let res = await updateProjectRoles(project_id, rolesUpdates);
    if (res.success) {
      setDialogMessage(
        `Role of ${row.original?.user?.name} updated successfully.`
      );
    } else {
      let updatedData = data.map(
        (x) => (x = x?.user?._id == row.id ? row.original : x)
      );
      setData(updatedData);
      setDialogMessage(
        `Error occured while updating role of ${row.original?.user?.name}`
      );
    }
    setDialogOpen(true);
    setIsUpdating(false);
    table.setEditingRow(null);
  };

  const handleDeleteUser = (row) => {
    setDeleteDialogBox(true);
    setDeleteId(row.id);
  };

  const closeDeleteAlert = () => {
    setDeleteDialogBox(false);
  };

  const handleAddUser = async () => {
    setIsUpdating(true);
    let rolesUpdates = await addUser.map((x) => {
      return {
        user: x._id,
        role: x?.role || "user",
        action: "add",
      };
    });
    let res = await updateProjectRoles(project_id, rolesUpdates);
    if (res.success) {
      fetchData();
      handleCloseAddUser();
      setDialogMessage(`User added successfully.`);
    } else {
      setDialogMessage(`Error occured while adding user`);
    }
    setIsUpdating(false);
    setDialogOpen(true);
  };
  const handleCloseAddUser = () => {
    setAddDialogBox(false);
    setAddUser([]);
  };
  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true,
    enableColumnActions: false,
    enableSorting: true,
    enableHiding: false,
    editDisplayMode: "row",
    enableEditing: true,
    positionActionsColumn: "last",
    rowsPerPageOptions: [10, 20, 50, 100],
    icons: {
      SaveIcon: () => (
        <CheckCircleIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
      FilterListIcon: () => <SearchIcon />,
      FilterListOffIcon: () => <SearchOffIcon />,
      CancelIcon: () => (
        <CancelIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
    },
    getRowId: (row) => row?.user?._id,
    initialState: { showColumnFilters: false },
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: {
      sx: {
        maxHeight: "70vh",
      },
    },
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Stack direction="row" gap={2}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => table.setEditingRow(row)}
            disabled={row?.original?.role == "creator"}
          >
            <EditIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={() => handleDeleteUser(row)}
            disabled={row?.original?.role == "creator"}
          >
            <DeleteIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
    renderTopToolbarCustomActions: () => (
      <>
        <Button
          variant="contained"
          onClick={() => {
            setAddDialogBox(true);
          }}
        >
          Add User
        </Button>
        <Dialog open={addDialogBox} maxWidth={"100vw"}>
          <DialogTitle>Add User</DialogTitle>
          <DialogContent dividers={true}>
            <AddProjectUserTable
              data={addUser}
              setData={setAddUser}
              excludeIds={data.map((x) => x?.user?._id)}
            />
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent={"space-between"}>
              <Grid item xs="auto">
                <Button
                  onClick={handleCloseAddUser}
                  color="primary"
                  variant="contained"
                  disabled={isUpdating}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                <Button
                  onClick={handleAddUser}
                  color="primary"
                  variant="contained"
                  disabled={addUser?.length == 0 || isUpdating}
                  autoFocus
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </>
    ),
    state: {
      isLoading,
      isSaving: isUpdating,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <AlertDialogBox
        openDialog={deleteDialogBox}
        title={"Delete User"}
        body={"Are you sure you want to remove this user?"}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        handleConfirm={onDeleteUser}
        type={"info"}
        handleCancel={closeDeleteAlert}
      />

      <CustomDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        title="Prompt Manager"
        onDialogClose={() => () => {}}
      >
        {dialogMessage}
      </CustomDialog>
    </>
  );
};
export default ProjectUsers;
