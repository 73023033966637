import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  Grid,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@emotion/react";
import ResolveTitleConflictPromptDialog from "../ResolveTitleConflictPromptDialog/index.js";
import { mergeByTitle } from "../../api/shareprompts";
import CustomDialog from "../../utils/dialogbox.js";
import Loader from "../../components/Loader/index.js";

const CustomIcon = (props) => {
  const theme = useTheme();
  switch (props.icon) {
    case "success":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <circle cx="9" cy="8.99609" r="9" fill={theme.palette.icon} />
          <path d="M3 8.99585L8 13L15 5" stroke="#1E4876" strokeWidth="2" />
        </svg>
      );
    case "warning":
    case "confirm":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
        >
          <path
            d="M10.083 14.7459H11.9163V16.5792H10.083V14.7459ZM10.083 7.41256H11.9163V12.9126H10.083V7.41256ZM10.9905 2.82922C5.93051 2.82922 1.83301 6.93589 1.83301 11.9959C1.83301 17.0559 5.93051 21.1626 10.9905 21.1626C16.0597 21.1626 20.1663 17.0559 20.1663 11.9959C20.1663 6.93589 16.0597 2.82922 10.9905 2.82922ZM10.9997 19.3292C6.94801 19.3292 3.66634 16.0476 3.66634 11.9959C3.66634 7.94422 6.94801 4.66256 10.9997 4.66256C15.0513 4.66256 18.333 7.94422 18.333 11.9959C18.333 16.0476 15.0513 19.3292 10.9997 19.3292Z"
            fill={theme.palette.icon}
          />
        </svg>
      );
    default:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
        >
          <path
            d="M10.083 14.7459H11.9163V16.5792H10.083V14.7459ZM10.083 7.41256H11.9163V12.9126H10.083V7.41256ZM10.9905 2.82922C5.93051 2.82922 1.83301 6.93589 1.83301 11.9959C1.83301 17.0559 5.93051 21.1626 10.9905 21.1626C16.0597 21.1626 20.1663 17.0559 20.1663 11.9959C20.1663 6.93589 16.0597 2.82922 10.9905 2.82922ZM10.9997 19.3292C6.94801 19.3292 3.66634 16.0476 3.66634 11.9959C3.66634 7.94422 6.94801 4.66256 10.9997 4.66256C15.0513 4.66256 18.333 7.94422 18.333 11.9959C18.333 16.0476 15.0513 19.3292 10.9997 19.3292Z"
            fill={theme.palette.icon}
          />
        </svg>
      );
  }
};

export default function ConflictTitleDialog(props) {
  const { body, open, onClose, promptRequestInfo, title, icon } = props;
  const [openCreatePromptDialog, setOpenCreatePromptDialog] = useState(false);
  const [dialog, setDialog] = useState({ open: false, title: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateNewPrompt = () => {
    setOpenCreatePromptDialog(true);
  };

  const handlePromptCreated = () => {
    setOpenCreatePromptDialog(false);

    if(props?.refreshRequest){
      props.refreshRequest();
    }
    if (props?.onClose) {
      props.onClose();
    }
  };

  const handleCloseDialog = () => setDialog({ ...dialog, open: false });

  const handleUpdateExistingPrompt = async () => {
    try {
      setIsLoading(true);
      const response = await mergeByTitle(promptRequestInfo._id);
      setIsLoading(false);
      if (response?.success) {
        if(props?.refreshRequest){
          props.refreshRequest();
        }
        setDialog({
          open: true,
          title: "Success",
          message: "Share Prompt was successful",
        });
        onClose();
      } else if (response?.titleExists) {
        setDialog({
          open: true,
          title: "Title Exists",
          message: "Prompt Title already exists" || response?.error,
        });
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "Failed to process share requests, Please try again",
      });
    }
  };


  return (
    <>
      <Loader loading={isLoading} text="">
        <CustomDialog
          open={dialog.open}
          title={dialog.title}
          onClose={handleCloseDialog}
        >
          {dialog.message}
        </CustomDialog>
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`alertDialogBox`}
        >
          <DialogTitle sx={{ bgcolor: "primary.main" }}>
            <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
              <div className="infoIcon">
                <CustomIcon icon={icon} />
              </div>
              <Typography variant="h6" sx={{ color: "text.navbar", flexGrow: 1 }}>{title}</Typography>
              <IconButton onClick={onClose} sx={{ color: "white" }}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>

          <DialogContent >
            <DialogContentText sx={{ mt: 2 }}>
              {body}
            </DialogContentText>
          </DialogContent>
          <Grid container justifyContent={"center"} sx={{ mb: 4 }}>
            <Grid item xs="auto" sx={{ mx: 2 }}>
              <Button
                onClick={handleCreateNewPrompt}
                color="primary"
                variant="contained"
                autoFocus
                disabled={isLoading}
              >
                Create Prompt with new title
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={handleUpdateExistingPrompt}
                color="primary"
                variant="contained"
                disabled={isLoading}
              >
                Update existing prompt
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        {openCreatePromptDialog && <ResolveTitleConflictPromptDialog
          open={openCreatePromptDialog}
          onClose={() => setOpenCreatePromptDialog(false)}
          onCreate={handlePromptCreated}
          promptContent={promptRequestInfo?.promptContent || ""}
          requestId={promptRequestInfo?._id}
        />}
      </Loader>
    </>
  );
}
