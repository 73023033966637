import React, { useState, useEffect,useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Loader from "../../components/Loader";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "@mui/material/utils";
import { Typography } from "@mui/material";
import { getProjectSuggestions } from "../../api/project";
import { fetchPromptById} from "../../api/prompt";

import { createShareRequest } from "../../api/shareprompts";
import { useParams } from "react-router-dom";
import CustomDialog from "../../utils/dialogbox";


export default function SharePrompt({open, handleClose, handleOpenSuccessDialog, handleOpenErrorDialog }) {
  const [dialogAlertOpen, setDialogAlertOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [promptData, setPromptData] = useState(null);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [promptsLoading, setPromptsLoading] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [selectionError, setSelectionError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);
  const [disableConfirmSharePrompts, setDisableConfirmSharePrompts] = useState(false);
  const { project_id, prompt_id } = useParams();

  const fetch = useMemo(
    () =>
    debounce((request, callback) => {
      setLoading(true);
      getProjectSuggestions(request.input, 1, 20).then((data) => {
        if (callback) {
          callback(data?.data?.projects || []);
        }
        setLoading(false);
        setProjects(data);
      });
    }, 400),
    [] 
  );

 
  useEffect(() => {
    if (project_id && prompt_id) {
      fetchPromptById(project_id, prompt_id).then((data) => {
        if (data.success) {
          setPromptData(data.data);
        } else {
          setDialogAlertOpen(true);
          setDialogMessage("Error while fetching Prompts, Please try again.")
          console.error("Error while fetching Prompts:", data.error);
        }
      })
      .catch((error) => {
        setDialogAlertOpen(true);
        setDialogMessage("Error while fetching Prompts, Please try again.")
        console.error("Error while fetching Prompts");
      });
    }
  }, [project_id, prompt_id]);
  

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);



  const handleRemoveOption = (option) => {
    setSelectedOptions((prev) => prev.filter((item) => item !== option));
  };
  const handleAutocompleteChange = (event, newValue) => {
    if (!newValue || newValue === null) return;
   
    setSelectionError('');
    setOptions((prevOptions) => [newValue, ...prevOptions]);
    setValue(null);
    setSelectedOptions((prevSelectedOptions) => [
      ...prevSelectedOptions,
      newValue,
    ]);
    setInputValue('');
  };


  const handleShareClick = async () => {
    try {
      setPromptsLoading(true);
      setDisableConfirmSharePrompts(true);
  
      const destProjectIds = selectedOptions.map((option) => option._id);
      const sharePromptsData = destProjectIds.map((destProjectId) => ({
        promptId: prompt_id, 
        promptVersion: promptData.version, 
        destProjectId: destProjectId,
      }));
  
      const requestBody = {
        prompts: sharePromptsData,
      };
  
      const response = await createShareRequest(requestBody);

      
    if (response.success) {
      handleOpenSuccessDialog(); 
      handleClose();
     
    } else {
    
      console.error("Error sharing prompts:", response.error);
      handleOpenErrorDialog();
      handleClose(); 
    }
  } catch (error) {
    
      console.error("Error sharing prompts:");
      handleOpenErrorDialog();
      handleClose();  
  } finally {
    
    setDisableConfirmSharePrompts(false);
    setPromptsLoading(false);
  }
  };
  
  const filteredOptions = options.filter(option => {
    if (option._id === project_id) {
      return false;
    }
    return !selectedOptions.find(selectedOption => selectedOption._id === option._id);
  });


  const isConfirmButtonDisabled = disableConfirmSharePrompts || selectedOptions.length === 0;

  return (
    <div>
       <Loader loading={promptsLoading}>
         
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      
      >
        
        <DialogTitle sx={{ backgroundColor: "#597697", color: "white" }}>
            Share Prompts to Projects
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} mt={2}>
              <Autocomplete
                id="share-prompts"
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option?.projectName
                }
                filterOptions={(x) => x}
                options={filteredOptions}
                autoComplete
                filterSelectedOptions
                value={value}
                freeSolo
                loading={loading}
                disabled={selectedOptions.length >= 5}
                onChange={handleAutocompleteChange}
                inputValue={inputValue} 
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Project Name" helperText="Select maximum of 5 projects" />
                )}
              />
             
              <Stack
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap={1}
                mt={1}
              >
                {selectedOptions.map((option) => (
                  <React.Fragment key={option._id}>
                    <Button
                      variant="outlined"
                      endIcon={<CloseIcon onClick={() => handleRemoveOption(option)} />}
                    >
                      {option.projectName}
                    </Button>
                  </React.Fragment>
                ))}
              </Stack>
            </Stack>
          </DialogContent>

          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Button
                onClick={handleClose}
               variant="contained"
              >
                Cancel
              </Button>
              <Button
                 disabled={isConfirmButtonDisabled}
                onClick={handleShareClick}
                variant="contained"
                
              >
                Confirm
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      
      </Loader>
    </div>
  );
};