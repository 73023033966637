import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { createNewPrompt } from "../../services/prompts";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./newprompt.module.css";

function NewPrompt() {
  const [promptContent, setPromptContent] = useState("");
  const [response, setResponse] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const { project_id } = useParams();
  const navigate = useNavigate();

  const handleRunClick = async () => {
    // Call your GPT API here with the promptContent.
    // For this example, we will just mock the response.
    const gptResponse =
      'This is a mock GPT response for "' + promptContent + '"';
    setResponse(gptResponse);
  };

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };

  const handleTagInputKeyDown = (event) => {
    if (event.key === "Enter" && tagInput) {
      setTags([...tags, tagInput]);
      setTagInput("");
    }
  };

  const handleTagDelete = (tagToDelete) => () => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const handleSaveClick = () => {
    const data = {
      prompt_content: promptContent,
      prompt_response: response,
      prompt_tag: tags,
    };
    console.log(data);
    createNewPrompt(project_id, promptContent, response, tags).then((data) => {
      console.log(data);
      alert("new prompt saved!");
      navigate(`/${project_id}/prompts`);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        {/* Input box for prompt content */}
        <TextField
          label="Prompt Content"
          fullWidth
          value={promptContent}
          onChange={(e) => setPromptContent(e.target.value)}
          margin="normal"
          variant="outlined"
        />

        {/* Run button */}
        <Button
          variant="contained"
          // color="primary"
          onClick={handleRunClick}
        >
          Run
        </Button>

        {/* Text field for GPT response */}
        <TextField
          label="AI model Response"
          fullWidth
          multiline
          minRows={10}
          value={response}
          margin="normal"
          variant="outlined"
          InputProps={{
            readOnly: true,
          }}
        />

        {/* Tag area */}
        <br />
        <div>
          <TextField
            label="Add Tag"
            fullWidth
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            margin="normal"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  Press Enter to add
                </InputAdornment>
              ),
            }}
          />
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={handleTagDelete(tag)}
              style={{ margin: "5px" }}
            />
          ))}
        </div>

        <Box mt={3}>
          {" "}
          {/* This adds margin to the top of the button */}
          <Button variant="contained" color="primary" onClick={handleSaveClick}>
            Save
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default NewPrompt;
