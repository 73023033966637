import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Stack,
  Button,
} from "@mui/material";
import { fetchTopicsByName } from "../../api/topics";
import { debounce } from "@mui/material/utils";

const TopicAutoComplete = (props) => {
  const {
    value,
    setValue,
    inputTextFieldParams = {},
    from,
    handleClickAddATopic,
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTopicSuggestions = useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        fetchTopicsByName(request.input, 20).then((res) => {
          if (callback) {
            callback(res?.data || []);
          }

          setLoading(false);
        });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetchTopicSuggestions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        let selectedIds = value?.map((x) => x._id) || [];
        if (results) {
          newOptions = results?.filter((x) => !selectedIds.includes(x._id));
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetchTopicSuggestions]);

  return (
    <Autocomplete
      id="topics"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option?.topicName
      }
      filterOptions={(x) => x}
      options={options}
      multiple
      value={value}
      freeSolo
      loading={loading}
      noOptionsText="Enter Topic"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option?.topicName}
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={async (event, newValue) => {
        let input = await newValue.filter((x) => typeof x === "object");
        setValue(input);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        if (from === "createProjectPage") {
          return (
            <TextField
              {...params}
              label="Search Topic *"
              fullWidth
              {...inputTextFieldParams}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Stack>
                    <Button
                      onClick={handleClickAddATopic}
                      className="topic-button"
                    >
                      Add New Topic
                    </Button>
                    {params.InputProps.endAdornment}
                  </Stack>
                ),
              }}
            />
          );
        } else {
          return <TextField {...params} fullWidth {...inputTextFieldParams} />;
        }
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Typography variant="body1">{option.topicName}</Typography>
          </li>
        );
      }}
    />
  );
};

export default TopicAutoComplete;
