import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Divider,
  Grid,
  Stack,
  Badge,
} from "@mui/material";
import ProjectsComponent from "./Projects";
import ProjectUsers from "./ProjectUsers";
import AccessRequests from "./AccessRequests";
import SharedPrompts from "./SharedPrompts";
import Topics from "./Topics";

const ProjectAdminConsole = () => {
  const [value, setValue] = useState(0);
  const [accessRequestCount, setAccessRequestCount] = useState(0);

  const handleAccessRequestCount = (count) => {
    setAccessRequestCount(count);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderProjectTabContent = () => {
    if (value === 0) {
      return <ProjectsComponent />;
    } else if (value === 1) {
      return <ProjectUsers />;
    } else if (value === 2) {
      return (
        <AccessRequests handleAccessRequestCount={handleAccessRequestCount} />
      );
    } else if (value === 3) {
      return <SharedPrompts />;
    } else if (value === 4) {
      return <Topics />;
    } else {
      return null;
    }
  };

  return (
    <Grid container p={4} pl={5} pr={5} spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h1">Project Admin Console</Typography>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            //   textColor="secondary"
            //   indicatorColor="secondary"
            scrollButtons="auto"
            variant="fullWidth"
          >
            <Tab label="Project Details" />
            <Tab label="Project Users" />
            <Tab
              label={
                <Badge badgeContent={accessRequestCount} color="error">
                  Access Requests
                </Badge>
              }
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} p={4}>
          {renderProjectTabContent()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectAdminConsole;
