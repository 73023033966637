import axios from 'axios';
import { SERVICE_URL,APP} from "./constants";
import store from '../app/store';
import { logOutUser } from '../app/userSlice';

const getHeaders = () => {

  const state = store.getState();
  const userId = state.user?.user?.id;
  const accessToken = state?.user?.user?.token;
  return {
    'user-id': userId,
    'Authorization': `Bearer ${accessToken}`,
    'app' : APP
  };
};

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: SERVICE_URL,
    validateStatus: (status) => status < 600,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const requestInterceptor = config => {
    const headers = getHeaders();
    config.headers = {...headers ,...config.headers};
    return config;
  };

  const responseInterceptor = response => {
    if (response?.status === 401) {
        store.dispatch(logOutUser());
        sessionStorage.clear();
        window.location.href = '/logout';
        return;
      }
    return response;
  };

  axiosInstance.interceptors.request.use(requestInterceptor);
  axiosInstance.interceptors.response.use(responseInterceptor);

  return axiosInstance;
};

export const webApiService = createAxiosInstance();
export default webApiService;
