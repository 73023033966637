import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/models"
const UNAUTHORIZED_MESSAGE = "You are not authorized to perform this action";
const MODEL_NOT_FOUND = "Model not found";

export const addModel = async (modelData) => {
    try {
        const response = await webApiService.post(`${BASE_URL}`, modelData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { data: data.data, exists: false, success: true }
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE }
        } else if (status === responseConstants.ALREADY_EXIST) {
            return { data: data.data, exists: true, error: data?.message, success: true }
        } else {
            return { success: false, error: data?.message }
        }
    } catch (error) {
        throw error;
    }
};

export const getAllModels = async () => {
    try {
        const response = await webApiService.get(`${BASE_URL}/all`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status == responseConstants.OK) {
            return { data: data.data, success: true };
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE }
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};

export const getAllModelsForUser = async () => {
    try {
        const response = await webApiService.get(`${BASE_URL}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status == responseConstants.OK) {
            return { data: data.data, success: true };
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};

export const getModelById = async (modelId) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/${modelId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { data: data.data, success: true };
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE }
        } else if (status == responseConstants.NOT_FOUND) {
            return { data, success: false, error: MODEL_NOT_FOUND}
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};

export const getResponse = async (modelId, inputText) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/callLLM`, { modelId, inputText });
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { data: data.data, success: true };
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE }
        } else if (status == responseConstants.NOT_FOUND) {
            return { data, success: false, error: MODEL_NOT_FOUND}
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};

export const updateModelById = async (modelId, modelData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${modelId}`, modelData);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status == responseConstants.OK) {
            return { data: data.data, success: true }
        } else if (status === responseConstants.ALREADY_EXIST) {
            return { data: data.data, exists: true, error: data?.message, success: true }
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE}
        } else if (status == responseConstants.NOT_FOUND) {
            return { data, success: false, error: MODEL_NOT_FOUND }
        } else {
            return { success: false, error: data?.message }
        }
    } catch (error) {
        throw error;
    }
};

export const deleteModel = async (modelId) => {
    try {
        const response = await webApiService.delete(`${BASE_URL}/${modelId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if (status === responseConstants.OK) {
            return { success: true };
        } else if (status === responseConstants.UNAUTHORIZED) {
            return { data, success: false, error: UNAUTHORIZED_MESSAGE }
        } else if (status == responseConstants.NOT_FOUND) {
            return { data, success: false, error: MODEL_NOT_FOUND }
        } else {
            return { success: false, error: data?.message };
        }
    } catch (error) {
        throw error;
    }
};
