import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/alerts"

export const fetchAlerts = async (page,limit) => {
    try {
        const response = await webApiService.get(`${BASE_URL}`,{ params: { page, limit}});
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};
export const deleteAlert = async (alertId) => {
    try {
        const response = await webApiService.delete(`${BASE_URL}/${alertId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if([responseConstants.OK,responseConstants.NOT_FOUND].includes(status) ) {
          return {data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};
export const updateAlert = async (alertId) => {
  try {
      const response = await webApiService.patch(`${BASE_URL}/read/${alertId}`);
      let status = response?.data?.code;
      let data = response?.data || {};
      if([responseConstants.OK, responseConstants.NOT_FOUND].includes(status) ) {
        return {data, success: true};
      } else {          
        return { success: false, error: data?.message};
      }
    } catch (error) {
        throw error;
    }
};


export const fetchNotificationCounts = async () => {

  try {
    const response = await webApiService.get(`${BASE_URL}/counts`);
    let status = response?.data?.code;
    let data = response?.data || {};
    if(responseConstants.OK == status ) {
      return {data: data?.data, success: true};
    } else {          
      return { success: false, error: data?.message};
    }
  } catch (error) {
      throw error;
  }
}