import { Box, CircularProgress } from "@mui/material";

export default function Loading(props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={props.height || "400px"} 
    >
      <CircularProgress />
    </Box>
  );
}
