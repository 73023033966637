import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  Add as AddIcon,
  FilterAlt,
  West as LeftIcon,
} from "@mui/icons-material";
import { useSearchParams, useNavigate } from "react-router-dom";
import ProjectCard from "../../components/ProjectCard/";

import { fetchTopics } from "../../api/topics";
import { getGlobalProjects } from "../../api/project";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import Loader from "../../components/Loader";
const ProjectsPage = (props) => {

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [projects, setProjects] = useState([]);
  const [myTopics, setMyTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [sortOption, setSortOption] = useState("");
  const [totalProjects, setTotalProjects] = useState(0);
  const [observerRef, setObserverRef] = useState(null);
  const [currentTopicPage, setCurrentTopicPage] = useState(1);
  const observer = useRef();
  
  const loadProjects = async (topicId,sortObject,sortOrder) => {
    try {
      if(itemsPerPage < 1) return 
      setLoadingProjects(true);
      let response = await getGlobalProjects(
        topicId || null,
        sortObject || "time",
        sortOrder || "desc",
        currentPage,
        itemsPerPage
      );
      setProjects(response?.data?.projects || []);
      setTotalProjects(response?.data.totalCount || projects.length);
    } catch (error) {
      setProjects([]);
      setTotalProjects(0);
    } finally {
      setLoadingProjects(false);
    }
  };
  const loadTopics = async () => {
    try {
      setLoadingTopics(true);
      let response = await fetchTopics(currentTopicPage);
      if(response?.data?.totalPages === currentTopicPage){
        if (observer.current) observer.current.disconnect();
      }
      setMyTopics(prevTopics => [...prevTopics, ...response.data?.topics || []]);
    } catch (error) {
      setMyTopics([]);
    } finally {
      setLoadingTopics(false);
    }
  };
 
  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setCurrentTopicPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver);
    if (observerRef) observer.current.observe(observerRef);
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [observerRef]);

  // get topics
  useEffect(() => {
    loadTopics();
  }, [currentTopicPage]);

  // get MyProjects
  useEffect(() => {
    const topicId = searchParams.get("topic");
    let sortObject = searchParams.get("sortObject");
    let sortOrder = searchParams.get("sortOrder");
    loadProjects(topicId,sortObject,sortOrder);
  }, [currentPage, itemsPerPage, sortOption]);
 
  useEffect(() => {
    let i =isSmScreen ? 6 : isMdScreen ? 9 : isLgScreen ? 12 : isXlScreen ? 15 : itemsPerPage
    setItemsPerPage(i)
  },[isSmScreen,isLgScreen,isMdScreen,isXlScreen])

  const handlePageChangeInternal = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (sortOption) => {
    let sortObject = "";
    let sortOrder = "";
    setSortOption(sortOption);
    switch (sortOption) {
      case "A-Z":
        sortObject = "alphabet";
        sortOrder = "asc";
        break;
      case "Time":
        sortObject = "time";
        sortOrder = "desc";
        break;
      case "Most Prompts":
        sortObject = "promptsCount";
        break;
      default:
        sortObject = "time";
        sortOrder = "desc";
        break;
    }
    const params = new URLSearchParams();
    if (selectedTopic) {
      params.append("topic", selectedTopic);
    }
    params.append("sortObject", sortObject);
    if (sortOrder !== "") {
      params.append("sortOrder", sortOrder);
    }
    navigate({
      search: `?${params.toString()}`,
    });
    setCurrentPage(1);
    handleMenuClose();
  };

  const handleCreateTopic = () => {
    navigate("/createtopic", { state: { from: "projectsPage" } });
  };

  const handleCreateProject = () => {
    navigate("/new_project", { state: { from: "projectsPage" } });
  };

  const handleGoToGlobalProjects = () => {
    navigate("/home");
  };

  const handleTopicClick = (topic, page) => {
    setLoadingProjects(true);
    
    const newSelectedTopic = selectedTopic === topic ? null : topic;
    setSelectedTopic(newSelectedTopic);
   
    loadProjects(newSelectedTopic,null,null)
    const params = new URLSearchParams();
    if (newSelectedTopic) {
      params.append("topic", topic);
    }
    navigate({
      search: `?${params.toString()}`,
    });
    handleMenuClose();
  };

  return (
    <>
    <Grid container p={4} pl={5} pr={5}>

      <Grid container item alignItems={"flex-start"} spacing={6}>
        <Grid container item xs={6} sm={4} md={3} lg={2} columnSpacing={2}>
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            item
          >
            <Grid item>
              <Typography variant="title">Topics</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Create a new topic" placement="bottom">
                <Button
                  variant="outlined"
                  buttonType="iconbutton"
                  onClick={handleCreateTopic}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            item
            container
            mt={1}
            sx={{ maxHeight: "80vh", overflow: "auto" }}
          >
            <Loader loading={loadingTopics}>
            {myTopics.length !== 0 ? (
              <>
              {myTopics.map((topic, index) => (
                <Grid
                  item
                  xs={12}
                  button
                  key={topic._id}
                  className={"topic-item-list"}
                  onClick={() => handleTopicClick(topic._id)}
                >
                  <TruncatedText
                    fontStyle={{
                      color:
                        selectedTopic === topic._id
                          ? "#597697"
                          : "rgba(0, 0, 0, 0.87)",
                    }}
                    text={topic.topicName}
                  />
                </Grid>
              ))}
              <div ref={setObserverRef}></div>
              </>
            ) : (
              !loadingTopics &&<Grid item xs={12}>
               <Typography variant="h6" className="helper-title">
                  No topics are created
                </Typography>
              </Grid>
            )}
            </Loader>
          </Grid>
        </Grid>
        <Grid container item xs={6} sm={8} md={9} lg={10}>
          <Grid
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            item
          >
            <Grid item xs={12} sm={6} lg={8}>
              <Typography variant="title">
                {selectedTopic
                  ? `Topic Name -  ${
                      myTopics.find((t) => t._id === selectedTopic)?.topicName
                    }`
                  : "Global Projects"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="stretch"
              justifyContent="flex-end"
              xs={12}
              sm={6}
              lg={4}
              spacing={1}
            >
              <Grid item>
                <Tooltip title="Create a new project" placement="bottom">
                  <Button
                    variant="outlined"
                    buttonType="iconbutton"
                    onClick={handleCreateProject}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  buttonType="iconbutton"
                  onClick={handleMenuOpen}
                >
                  <FilterAlt />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleSort("A-Z")}>
                    Sort A-Z
                  </MenuItem>
                  <MenuItem onClick={() => handleSort("Time")}>
                    Sort by Most Recent
                  </MenuItem>
                  <MenuItem onClick={() => handleSort("Most Prompts")}>
                    Sort by Most # of Prompts
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleGoToGlobalProjects}>
                  <LeftIcon sx={{ fontSize: "17px" }} />
                  <div style={{ marginLeft: "8px", textTransform: "none" }}>
                    Go To My Projects
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            <Grid item xs={12} alignContent={"center"} justifyItems={"center"}>
              <Loader loading={loadingTopics || loadingProjects}>
                {projects.length === 0 ? (
                  !loadingProjects && <Typography variant="h6" className="helper-title">
                    No projects are created 
                  </Typography>
                ) : (
                  <Stack rowGap={2}>
                    <Grid container spacing={2} className="projects-container">
                      {projects.map((project, index) => (
                        <Grid item xs={12} sm={6} md ={4} lg={3}>
                          <ProjectCard key={index} {...project} />
                        </Grid>
                      ))}
                    </Grid>

                    {Math.ceil(totalProjects / itemsPerPage) > 1 && (
                      <Pagination
                        count={Math.ceil(totalProjects / itemsPerPage)}
                        page={currentPage}
                        color="primary"
                        siblingCount={0}
                        className=""
                        onChange={handlePageChangeInternal}
                        disabled={loadingTopics || loadingProjects}
                      />
                    )}
                  </Stack>
                )}
              </Loader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </>
  );
};

export default ProjectsPage;
