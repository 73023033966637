import React, { useState, useEffect,useMemo} from "react";
import Delete from "@mui/icons-material/Delete";
import {
  Typography,
  Avatar,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import AddUserDailogBox from "../../components/AddUserDailogBox/AddUserDailogBox";
import {
  fetchUsers,
  updateUserById,
  deleteUser
} from "../../api/users";
import { useSelector } from "react-redux";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SnackbarComponent from "../../components/SnackBar";
import AlertDialogBox from "../../components/AlertDailogBox";

const Users = (props) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');

  const { user} = useSelector((state) => state.user);

  //table state
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {     
      let params = await populateSearchParams()
      let res = await fetchUsers(params);
      if(res.success) {
        setData(res.data?.users ||[]);
        setRowCount(res?.data?.count);
      } else {
        setSnackBarType('error');
        setSnackBarMessage("Error occured while fetching users");
        setShowSnackBar(true);
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while fetching users");
      setShowSnackBar(true);
    }
    setIsLoading(false);
    setIsRefetching(false);
  };


  
  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
    deleting
  ]);
 
  const populateSearchParams = async () => {    
    let params = {limit: pagination.pageSize, page: pagination.pageIndex+1, };
    await columnFilters.forEach(filter => {
        const { id, value } = filter;
        if(value) {
          params[id] = value;
        }        
    });
    return params;
  }

  const onDeleteUser = async () => {
    try {
      setIsLoading(true);
      if(deleteId) {
        let response = await deleteUser({userIds:[deleteId]} );
        if(response.success) {     
          fetchData();       
          setDeleteDialogBox(false);     
          setSnackBarMessage("User has been deleted successfully!" );
          setSnackBarType('success');
          setShowSnackBar(true);   
        } else {
          setSnackBarType('error');
          setSnackBarMessage("Error occured while deleting user. Please try again later.");
          setShowSnackBar(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while deleting user. Please try again later.");
      setShowSnackBar(true);
      setIsLoading(false);
    }
  };

  let roles = [
    {
      label: "User",
      value: "user"
  },
  {
    label: "Admin",
    value: "admin"
}
  ]
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'User',
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          
        <Stack spacing={2} direction="row" alignItems={"center"}>
          <Avatar
            src={row?.original?.picture || "/broken-image.jpg"}
            alt={renderedCellValue}
            aria-label="project"
          />
        <Typography variant="body1"> {renderedCellValue}</Typography>
    </Stack>)
      },
      {
        accessorKey: 'email',
        header: 'Email',
        enableGlobalFilter: true,
        enableEditing: false, 
        Cell: ({renderedCellValue}) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: 'role',
        header: 'Role',
        enableGlobalFilter: true,
        editVariant: 'select',
        editSelectOptions: roles,
        filterVariant: "select",
        filterSelectOptions:  roles.map(x=>x.label),
        muiEditTextFieldProps: {
          select: true,
          variant: "outlined",
        },
        Cell: ({ renderedCellValue }) => {          
          return <Typography variant="body1">{roles.filter(x => x.value == renderedCellValue).map(x =>x.label)?.[0] || renderedCellValue}</Typography>;
        },
      },
    ],
    [],
  );


//UPDATE function
const handleSaveUser = async ({values,row,...rest}) => {
  values._id = row.id;
  let role = values?.role || row.original?.role;
  setIsUpdating(true);
  let res = await updateUserById(row.id,{role});
  if(res.success) {
    setSnackBarMessage(`Role of ${row.original?.name} updated successfully.`)
    setSnackBarType('success');
  } else {
    let updatedData = data.map(x =>   x = x._id == row.id ? row.original : x)
    setData(updatedData);
    setSnackBarType('error');
    setSnackBarMessage(`Error occured while updating role of ${row.original?.name}`);
  }
  setShowSnackBar(true);
  setIsUpdating(false);
  table.setEditingRow(null);
};

const handleDeleteUser = (row) => {
    setDeleteDialogBox(true);
    setDeleteId(row.id);
};
 
  const closeDeleteAlert = ()=>{
    setDeleteDialogBox(false);
  }

  const handleCloseSnackBar = () =>{
    setShowSnackBar(false);
    setSnackBarMessage('');
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true, 
    enableColumnActions: false,
    enableSorting: false,
    enableHiding:false,
    editDisplayMode: 'row',
    enableEditing: true,
    positionActionsColumn: 'last',
    rowsPerPageOptions: [10, 20,50,100],
    icons: {
        SaveIcon: () => <CheckCircleIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />,
        FilterListIcon: () => <SearchIcon />,
        FilterListOffIcon : () => <SearchOffIcon />,
        CancelIcon: () => <CancelIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />
    }, 
    getRowId: (row) => row._id,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: {
        sx: {
            maxHeight: '70vh',
        },
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Stack direction="row" gap={2}>

        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)} disabled={user?.id == row?.original?._id } >
            <EditIcon sx={{fontSize: { xs: "medium", sm: "large" } }}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => handleDeleteUser(row)} disabled={user?.id == row?.original?._id } >
            <DeleteIcon sx={{fontSize: { xs: "medium", sm: "large" } }}/>
          </IconButton>
        </Tooltip>
      </Stack>
    ),
    renderTopToolbarCustomActions: () => (      
      <AddUserDailogBox onClose={handleAddUserClose}/>
    ),
    rowCount,
    state: {
      columnFilters,
      isLoading,
      pagination,
      isSaving : deleting || isUpdating,
      showProgressBars: isRefetching,
    },
  });

  const handleAddUserClose = () => {
    fetchData();
  }
  return (
    <>
        <MaterialReactTable table={table} />
        <SnackbarComponent severity={snackBarType} variant={"filled"} message={snackBarMessage} open={showSnackBar} handleClose={handleCloseSnackBar} />
        <AlertDialogBox openDialog={deleteDialogBox} title={'Delete User'} body={'Are you sure you want to delete this user?'} confirmText={'Confirm'} cancelText={'Cancel'} handleConfirm={onDeleteUser}  type={'info'} handleCancel={closeDeleteAlert}/>
    </>
  );


}
export default Users;
