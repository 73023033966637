import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, Tab, Grid } from "@mui/material";
import Users from "../GlobalAdminConsole/Users.js";
import Topics from "../GlobalAdminConsole/Topics.js";
import Projects from "../GlobalAdminConsole/Projects.js";
import Models from "../GlobalAdminConsole/Models.js";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TopicIcon from '@mui/icons-material/Topic';
import FolderIcon from '@mui/icons-material/Folder';
import HubIcon from '@mui/icons-material/Hub';

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth, 
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  position : 'relative',
  height: `calc(100vh - 76px)`,
  [theme.breakpoints.up('md')]: {
    height: `calc(100vh - 92px)`,
  },
  
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  position : 'relative',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
  
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const GlobalAdminConsole = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [value, setValue] = useState();
  const  rootRef = useRef();

  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  let location = useLocation();

  const toggleDrawer = () =>{
    if(open){
        setOpen(false);
    }
    else{
        setOpen(true);
    }
  }

  const toggleButtonColor = (index) =>{
    if(index === value){
        return {
            minHeight: 48, 
            justifyContent: 'initial',
            px: 2.5,
            color: "background.default",
            backgroundColor: "primary.main",
            ':hover': {
                backgroundColor: "primary.main" 
              },
        }
    }
    else{
        return {
            minHeight: 48, 
            justifyContent: 'initial',
            px: 2.5,
            color: "primary.main",
            backgroundColor: "background.default",
        }
    }
  }

  const options = [
    {
      title: "Users",
      icon: <AccountCircleIcon />,
    },
    {
      title: "Topics",
      icon: <TopicIcon />,
    },
    {
      title: "Projects",
      icon: <FolderIcon />,
    },
    {
      title: "Models",
      icon: <HubIcon />,
    },
  ]

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    } else if (user.role !== "admin") {
      navigate("/home");
    }
    else if (location.state && location.state.from === '/editProject') {
      setValue(2)
    }
    else if (location.state && location.state.from === '/editTopic') {
      setValue(1)
    }
    else if (location.state && location.state.from === '/editModel') {
      setValue(3)
    }
    else {
      setValue(0)
    }

  }, []);


  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const renderProjectTabContent = () => {
    console.log(rootRef)
    if (value === 0) {
      return <Users setTabValue = {setValue}/>;
    } else if (value === 1) {
      return <Topics setTabValue = {setValue} />;
    } else if (value === 2) {
      return <Projects setTabValue = {setValue} />;
    } else if (value === 3) {
      return <Models setTabValue = {setValue} />;
    } else {
      return <></>;
    }
  };

  return (
    <Grid sx={{ display: 'flex'}} >
      <Drawer variant="permanent" open={open} >
        <DrawerHeader sx={ {display: 'flex', justifyContent: 'space-between', alignItems:'center', color: 'primary.main', backgroundColor:'background.default'} }>
          {open ? <Typography variant="h1" sx={{pl:6}}>Global Admin</Typography>  : null}
          <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon sx={{color:"primary.main"}} />: <ChevronRightIcon sx={{color:"primary.main"}} />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ pt: 0 }}>
        {options.map((option, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                key={index}
                sx ={toggleButtonColor(index)}
                onClick={(e) =>{
                  handleChange(index);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: index === value ? "background.default" : "primary.main",
                  }}
                >
                  { option.icon}
                </ListItemIcon>
                <ListItemText primary={option.title} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Grid component="main" sx={{ flexGrow: 1, p: 3 }}>
      {renderProjectTabContent()}
      </Grid>
    </Grid>
  );
};

export default GlobalAdminConsole;