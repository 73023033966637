import React from "react";
import {
  Typography,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  IconButton,
  CardActions,
  Grid,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import {  useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";
import "./style.css";
import { TruncatedText } from "../TruncatedText/TruncatedText";

import InfoCard from "../InfoCard";
import { getUserInfo } from "../../app/userSlice";

const ProjectCard = ({
  _id,
  projectName,
  projectDescription,
  roles,
  createdBy,
  totalPrompts
}) => {
  const user  = useSelector(getUserInfo);

  const navigate = useNavigate();

  const handleClickProject = () => {
    navigate(`/${_id}/detail`);
  };

  const handleProjectAdminConsoleClick = () => {
    navigate(`/${_id}/project_admin`);
  };

  const isAdmin = (roles || []).some((u) => u?.user === user.id && ["admin","creator","poweruser"].includes(u.role));

  const notUserOfProject =  !((roles||[]).some((projectUser) => projectUser?.user === user.id));

  const handleGoToPromptsPage = () => {
    navigate(`/${_id}/prompts`, { state: { from: "projectsPage" } });
  };

  return (
    <>
      <Card
        type="infocard"
      >
        <CardHeader
          avatar={

            <Tooltip
              title={<InfoCard user={createdBy} role={"Project Creator"} />}
              placement="bottom-start"
              type="infocard"
            >
              <Avatar
                src={prompt?.createdBy?.picture || "/broken-image.jpg"}
                alt={prompt?.createdBy?.name}
                aria-label="project"
              />
            </Tooltip>
          }
          title={
            <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={10}>
              <TruncatedText
                variant={"body1"}
                text={projectName}
              />
            </Grid>
            </Grid>
        }
          titleTypographyProps={{
            noWrap: true,
          }}
          type="infocard"
          onClick={handleClickProject}
        > </CardHeader>
        <CardContent
          
          type="infocard"
          dividers
        >
            <Typography
              variant="body2"
              component="p"
             
            >
              {projectDescription}
            </Typography>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "space-between",
            height:"2em"
          }}
        >
          <Typography
            variant="body3Primary"
            onClick={notUserOfProject ? undefined : handleGoToPromptsPage}
            className={`prompts-caption ${!notUserOfProject ?  "cursor-pointer" : ""}`}
         
          >
            {totalPrompts|| 0} Prompts
          </Typography>
          {isAdmin && (
            <IconButton
              size="small"
              color="primary"
              onClick={handleProjectAdminConsoleClick}
              sx={{
                p: 0,
              }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default ProjectCard;
