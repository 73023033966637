import React, { useState, useEffect } from "react";
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";
// import { getProjectRoleForUser } from "../../services/projects";
import { getProjectRoleForUser } from "../../api/project";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../utils/Loading";
import { getUserInfo } from "../../app/userSlice";

export default function ProjectAuth(props) {
  const [projectRoles, setProjectRoles] = useState({
    isLoaded: false,
    roles: [],
  });

  const { project_id } = useParams();
  const location = useLocation();

  const  user  = useSelector(getUserInfo);
  useEffect(async () => {
    const roles = async () => {
      try {
        let res = await getProjectRoleForUser(project_id);
        console.log(res);
        if(res.success) {
          setProjectRoles({ roles: [res?.data?.role || ""], isLoaded: true });
        }
       
      } catch (err) {}
    };
    await roles();
  }, []);

  return (
    <>
      {projectRoles.isLoaded ? (
        projectRoles.roles.some((r) => props.roles.projectRoles.includes(r)) ||
        [user.role].some((r) => (props.roles?.userRole || []).includes(r)) ? (
          <Outlet />
        ) : (
          <Navigate
            to={`/${project_id}/detail`}
            state={{ from: location }}
            replace
          />
        )
      ) : (
        <Loading height={"50vh"} />
      )}
    </>
  );
}
