import React, { useState, useEffect, useCallback } from "react";
import { Container, Box, Divider, Typography, Button } from "@mui/material";
import styles from "./styles.module.css";

function RequestAccessPage() {

  const prompts = [
    "How do I automate my INDs",
    "What are the Biological Pathways for covid",
    "What is the mechanism of action for penicillin",
    "Draft an email to FDA",
  ];

  const typingSpeed = 100;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);

  const handleAdminClick = () => {
    const email = process.env.REACT_APP_ADMIN_EMAIL;
    const body = "I kindly request access to Prompt Manager.\nThank you for your assistance.";
    const subject = encodeURIComponent("Access Request for Prompt Manager");
    window.location.href = `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`;
};

  const handleTyping = useCallback(() => {
    if (isWaiting) return;

    if (isTyping) {
      if (currentPrompt.length < prompts[currentIndex].length) {
        setCurrentPrompt((prev) =>
          prompts[currentIndex].slice(0, prev.length + 1)
        );
      } else {
        setIsTyping(false);
        setIsWaiting(true);

        setTimeout(() => {
          setIsWaiting(false);
        }, 2000);
      }
    } else {
      if (currentPrompt.length > 0) {
        setCurrentPrompt((prev) => prev.slice(0, -1));
      } else {
        setIsTyping(true);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % prompts.length);
      }
    }
  }, [currentPrompt, isTyping, currentIndex, isWaiting, prompts]);

  useEffect(() => {
    const typingInterval = setInterval(handleTyping, typingSpeed);
    return () => {
      clearInterval(typingInterval);
    };
  }, [handleTyping, typingSpeed]);

  return (
    <Container
      component="main"
      maxWidth="lg"
      id={styles.container}
      style={{ display: "flex", alignItems: "center", height: "80vh" }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        height="70%"
      >
        <Box
          flex="0.9"
          sx={{
            paddingRight: "3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            className={styles.typewriterText}
            sx={{
              width: "90%",
              color: "rgba(39, 110, 138, 1)",
              fontWeight: "700 !important",
              lineHeight: "2 !important",
            }}
          >
            {currentPrompt}
          </Typography>
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{
            backgroundColor: "black",
            width: "6px",
            borderRadius: "5px",
          }}
        />

        <Box flex="1.1" pl={2} mt={2} textAlign="center">
          <Box mt={1} mb={3}>
            <img
              src="/userprofile.png"
              alt="User Profile"
              style={{ width: "30%", height: "30%", borderRadius: "50%" }}
            />
          </Box>
          <Typography variant="h6" color="error" gutterBottom>
            You are not authorized to access the application.
          </Typography>
          <div className={styles.request_container}>
            <button
              id="customBtn"
              className={styles.request_button}
              onClick={handleAdminClick}
            >
              <div className={styles.request_text}><Typography sx={{ fontSize: "18px" }}>Request Access</Typography></div>
            </button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

export default RequestAccessPage;
