import React, { useState } from "react";
import { ListItem, ListItemText } from "@mui/material";
import jsPDF from "jspdf";

export default function ExportPDF({promptTitle, promptDescription}) {

  const exportPDF = () => {
    const doc = new jsPDF();

    // Title
    doc.setFontSize(18);
    doc.setTextColor(79, 129, 189);
    doc.text("Prompt Manager", 10, 15);
    doc.setTextColor(0, 0, 0); // Resetting the color back to black

    // Heading for Question
    doc.setFontSize(14);
    doc.setTextColor(79, 129, 189);
    doc.text("Prompt Title", 10, 30);
    doc.setTextColor(0, 0, 0);

    // Content of Question
    doc.setFontSize(12);
    let lines = doc.splitTextToSize(promptTitle, 190); // 180 being the width in points of the wrapping box
    doc.text(lines, 10, 40);

    let yOffset = lines.length * 6 + 40; // Calculate the Y offset for the next section based on the lines of the question. Assuming a 6pt gap between lines.

    // Heading for Answer
    doc.setFontSize(14);
    doc.setTextColor(79, 129, 189);
    doc.text("Prompt:", 10, yOffset + 10);
    doc.setTextColor(0, 0, 0);

    // Content of Answer
    doc.setFontSize(12);
    lines = doc.splitTextToSize(promptDescription, 190);
    doc.text(lines, 10, yOffset + 20);

    doc.save("Prompt-Manager.pdf");
  };

  return (
    <ListItem button onClick={exportPDF}>
      <ListItemText primary="Export as pdf" />
    </ListItem>
  );
}
