import React, { useState, useEffect,useMemo} from "react";
import {
  Typography,
  Avatar,
  IconButton,
  Stack,
  Tooltip,
  Button,
} from "@mui/material";

import {
  fetchTopics,
  fetchTopicById,
  deleteTopic
} from "../../api/topics";
import { useSelector } from "react-redux";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SnackbarComponent from "../../components/SnackBar";
import AlertDialogBox from "../../components/AlertDailogBox";
import { useNavigate } from "react-router-dom";

const Topics = (props) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [deleteDialogBox, setDeleteDialogBox] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarType, setSnackBarType] = useState('success');
  const [topicInfo, setTopicInfo] = useState({});

  const navigate = useNavigate();
  const { user} = useSelector((state) => state.user);

  //table state
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {     
      let params = await populateSearchParams()
      let res = await fetchTopics(params.page,params.limit,params);
      if(res.success) {
        setData(res.data?.topics ||[]);
        setRowCount(res?.data?.totalCount);
      } else {
        setSnackBarType('error');
        setSnackBarMessage("Error occured while fetching topics");
        setShowSnackBar(true);
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while fetching topics");
      setShowSnackBar(true);
    }
    setIsLoading(false);
    setIsRefetching(false);
  };


  
  useEffect(() => {
    fetchData();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
  ]);
 
  const populateSearchParams = async () => {    
    let params = {limit: pagination.pageSize, page: pagination.pageIndex+1, };
    await columnFilters.forEach(filter => {
        const { id, value } = filter;
        if(value) {
          params[id] = value;
        }        
    });
    return params;
  }

  const onDeleteTopic = async () => {
    try {
      setIsLoading(true);
      if(deleteId) {
        let response = await deleteTopic(deleteId );
        if(response.success) {     
          fetchData();       
          setDeleteDialogBox(false);     
          setSnackBarMessage("Topic has been deleted successfully!" );
          setSnackBarType('success');
          setShowSnackBar(true);   
        } else {
          setSnackBarType('error');
          setSnackBarMessage("Error occured while deleting topic. Please try again later.");
          setShowSnackBar(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setSnackBarType('error');
      setSnackBarMessage("Error occured while deleting topic. Please try again later.");
      setShowSnackBar(true);
      setIsLoading(false);
    }
  };

  const handleEditTopic = async(row) => {
    let data = {
      topicId : row?.original?._id,
      topicName : row?.original?.topicName,
      topicDescription : row?.original?.topicDescription,
    }
    navigate("/edittopic", {
      state: data,
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'topicName',
        header: 'Name',
        enableGlobalFilter: true,
        enableEditing: false, 
        Cell: ({renderedCellValue}) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: 'topicDescription',
        header: 'Description',
        enableGlobalFilter: true,
        enableEditing: false, 
        Cell: ({renderedCellValue}) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: 'createdBy',
        header: 'Created By',
        enableColumnFilter: false,
        enableEditing: false,
        Cell: ({ renderedCellValue,row}) => (
         
          <Tooltip
          title={`Created at :- ${formatDate(row?.original?.createdAt)}`}
          placement="left"
        >
          
              <Stack spacing={2} direction="row" alignItems={"center"}>
                <Avatar
                  src={renderedCellValue?.picture || "/broken-image.jpg"}
                  alt={renderedCellValue?.name}
                  aria-label="project"
                />
              <Typography variant="body1"> {renderedCellValue?.name}</Typography>
          </Stack>
        </Tooltip>)
      },
      {
        accessorKey: 'createdBy',
        header: 'Actions',
        enableEditing: false,         
        enableColumnFilter: false,
        Cell: ({renderedCellValue,row}) => (
         
              <Stack direction="row" gap={2}>

              <Tooltip title="Edit">
                <IconButton  disabled={user?.id == row?.original?._id } onClick={ () => handleEditTopic(row)} >
                  <EditIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton color="error" onClick={() => handleDeleteTopic(row)} disabled={user?.id == row?.original?._id } >
                  <DeleteIcon sx={{fontSize: { xs: "medium", sm: "large" } }}/>
                </IconButton>
              </Tooltip>
            </Stack>
        ),
      },
    ],
    [],
  );


const handleDeleteTopic = async (row) => {
  try {
    setIsLoading(true);
    let results = await fetchTopicById(row.id);
    if(results.success) {
      setTopicInfo(results?.data || {});
    }
    setDeleteDialogBox(true);
    setDeleteId(row.id);
  } catch(err) {

  }finally {
    setIsLoading(false);
  }
};
 
  const closeDeleteAlert = ()=>{
    setDeleteDialogBox(false);
  }

  const handleCloseSnackBar = () =>{
    setShowSnackBar(false);
    setSnackBarMessage('');
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true, 
    enableColumnActions: false,
    enableSorting: false,
    enableHiding:false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    positionActionsColumn: 'last',
    rowsPerPageOptions: [10, 20,50,100],
    icons: {
        SaveIcon: () => <CheckCircleIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />,
        FilterListIcon: () => <SearchIcon />,
        FilterListOffIcon : () => <SearchOffIcon />,
        CancelIcon: () => <CancelIcon sx={{fontSize: { xs: "medium", sm: "large" } }} />
    }, 
    getRowId: (row) => row._id,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: {
        sx: {
            maxHeight: '70vh',
        },
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    renderTopToolbarCustomActions: () => (      
      <Button
        variant="contained"
        onClick={handleCreateTopic}
      >
        Add New Topic
      </Button>
    ),
    rowCount,
    state: {
      columnFilters,
      isLoading,
      pagination,
      showProgressBars: isRefetching,
    },
  });


  const handleCreateTopic = () => {
    navigate("/createtopic", { state: { from: "globalAdminConsolePage" } });
  };

  const getAlertBody = () => {
    return (
      <>
      {topicInfo?.linkedProjects?.length > 0 && 
      <>
       <Typography
             variant="body1Bold"
           >
             Note: Deleting these Topics will delete the following
             projects associated to these topic as well:
           </Typography>
           <br/>
           { topicInfo?.linkedProjects?.map((project, index) => (
               <Typography variant="body1Bold">{`${index+1}. ${project.projectName}`}<br/></Typography>
           ))
          }
          <br/>
          </>
      }
      <Typography variant="body1">Are you sure you want to delete this topic?</Typography>
          
      </>
    );
  }

  return (
    <>
        <MaterialReactTable table={table} />
        <SnackbarComponent severity={snackBarType} variant={"filled"} message={snackBarMessage} open={showSnackBar} handleClose={handleCloseSnackBar} />
        <AlertDialogBox openDialog={deleteDialogBox} title={'Delete Topic'} body={getAlertBody()} confirmText={'Confirm'} cancelText={'Cancel'} handleConfirm={onDeleteTopic}  type={'info'} handleCancel={closeDeleteAlert}/>

    </>
  );


}
export default Topics;
