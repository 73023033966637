import { useRef, useEffect, useState } from "react";
import { Typography, Tooltip } from "@mui/material";
const TruncatedText = ({ text, variant, fontStyle }) => {
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
  }, []);

  useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize);
    },
    []
  );

  const [hoverStatus, setHover] = useState(false);

  return (
    <Tooltip
      title={text}
      interactive
      disableHoverListener={!hoverStatus}
    >
      <Typography
        ref={textElementRef}
        noWrap
        variant={variant}
        sx={fontStyle}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export { TruncatedText };
