import React, { useState, useEffect, useMemo } from "react";
import {
  Avatar,
  Typography,
  Button,
  Stack,
  Tooltip,
} from "@mui/material";
import { fetchAllShareRequests, processShareRequest } from "../../api/shareprompts";
import Loader from "../../components/Loader/index.js";
import CustomDialog from "../../utils/dialogbox.js";
import ConflictTitleDialog from "../../components/ConflictTitleDialog/index.js";
import ConflictDescriptionDialog from "../../components/ConflictDescriptionDialog/index.js";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import AlertDialogBox from "../../components/AlertDailogBox";
import { useDispatch, useSelector } from 'react-redux';
import { setSharedPromptCount } from '../../app/notificationSlice';

export default function SharedPrompts() {
  const [requests, setRequests] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [declineRequestDialog, setDeclineRequestDialog] = useState(false);
  const [duplicateRequestDialog, setDuplicateRequestDialog] = useState(false);
  const [declineRequestInfo, setDeclineRequestInfo] = useState({});
  const [acceptRequestDialog, setAcceptRequestDialog] = useState(false);
  const [acceptRequestInfo, setAcceptRequestInfo] = useState({});
  const [dialog, setDialog] = useState({ open: false, title: "", message: "" });
  const [titleConflictExists, setTitleConflictExists] = useState(false);
  const [descriptionConflictExists, setDescriptionConflictExists] = useState(false);

  const fetchRequests = async () => {
    setIsLoading(true);
    try {
      const response = await fetchAllShareRequests();
      if (response.success) {
        setRequests(response?.data?.data || []);
        dispatch(setSharedPromptCount(response?.data?.data?.length|| 0));
      } else {
        setDialog({
          open: true,
          title: "Error",
          message: response.error || "Failed to fetch requests",
        });
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "An unexpected error occurred",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "promptTitle",
        header: "Prompt Title",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        accessorKey: "promptContent",
        header: "Description",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Tooltip
            title={renderedCellValue.length > 100 ? renderedCellValue : ""}
            enterDelay={500}
            interactive
          >
            <Typography variant="body1">
              {renderedCellValue.length > 100
                ? `${renderedCellValue.substring(0, 100)}...`
                : renderedCellValue}
            </Typography>
          </Tooltip>
        ),
      },
      {
        accessorKey: "sharedBy.name",
        header: "Shared By",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <Avatar
              src={row?.original?.sharedBy?.picture || "/broken-image.jpg"}
              alt={renderedCellValue}
              aria-label="project"
            />
            <Typography variant="body1"> {renderedCellValue}</Typography>
          </Stack>
        ),
      },
      {
        accessorKey: "destProjectId.projectName",
        header: "Request",
        enableGlobalFilter: true,
        enableEditing: false,
        Cell: ({ renderedCellValue }) => (
          <Typography variant="body1">{renderedCellValue}</Typography>
        ),
      },
      {
        header: "Actions",
        enableGlobalFilter: false,
        enableEditing: false,
        Cell: ({ row }) => (

          <Stack direction="row" gap={2}>
            <Tooltip title="Accept">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpenAcceptDialog(row)}
              >
                Accept
              </Button>
            </Tooltip>
            <Tooltip title="Decline">
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleOpenDeclineDialog(row)}
              >
                Decline
              </Button>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: requests,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    showFilters: true,
    enableColumnActions: false,
    enableSorting: true,
    enableHiding: false,
    positionActionsColumn: "last",
    rowsPerPageOptions: [10, 20, 50, 100],
    icons: {
      SaveIcon: () => (
        <CheckCircleIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
      FilterListIcon: () => <SearchIcon />,
      FilterListOffIcon: () => <SearchOffIcon />,
      CancelIcon: () => (
        <CancelIcon sx={{ fontSize: { xs: "medium", sm: "large" } }} />
      ),
    },
    getRowId: (row) => row?._id,
    initialState: { showColumnFilters: false },
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiTableContainerProps: {
      sx: {
        height: "50vh",
      },
    },
    state: {
      isLoading,
    },
  });

  const handleCloseDialog = () => setDialog({ ...dialog, open: false });

  const handleOpenDeclineDialog = (row) => {
    setDeclineRequestInfo(row?.original);
    setDeclineRequestDialog(true);
  };

  const onDeclineRequest = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        requestId: declineRequestInfo._id,
        action: "denied",
      };
      const response = await processShareRequest(requestData);
      if (response.success) {
        setDialog({
          open: true,
          title: "Success",
          message: `Share Request Has Been Declined Successfully`,
        });
        let req = await requests?.filter( (request) => request._id !== declineRequestInfo._id)
        setRequests(req);
        dispatch(setSharedPromptCount(req?.length));
        onDeclineRequestDialogClose();
      } else {
        throw new Error(response.error || "Error While declining the Share Request");
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "Error While declining the Share Request",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const onDeclineRequestDialogClose = () => {
    if(declineRequestDialog) {

      setDeclineRequestDialog(false);
    } 
    if(duplicateRequestDialog) {
      setDuplicateRequestDialog(false);
    }

    setDeclineRequestInfo({});
  };
  const handleOpenAcceptDialog = (row) => {
    setAcceptRequestInfo(row?.original);
    setAcceptRequestDialog(true);
  };

  const onAcceptRequest = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        requestId: acceptRequestInfo._id,
        action: "accepted",
      };
      const response = await processShareRequest(requestData);
      if (response?.success) {
        setDialog({
          open: true,
          title: "Success",
          message: `Prompt Has Been Shared Successfully!`,
        });
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request._id !== acceptRequestInfo._id)
        );
        onAcceptRequestDialogClose();
      } else if (response?.duplicate) {
        setDuplicateRequestDialog(true);
        setDeclineRequestInfo(acceptRequestInfo);
        onAcceptRequestDialogClose();
      } else if (response?.titleExists) {
        setTitleConflictExists(true);
      } else if (response?.contentExists) {
        setDescriptionConflictExists({ open: true });
      }
    } catch (error) {
      setDialog({
        open: true,
        title: "Error",
        message: error.message || "Error While approving the Share Request",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onAcceptRequestDialogClose = () => {
    setAcceptRequestDialog(false);
    setAcceptRequestInfo({});
  };

  return (
    <Loader loading={isLoading} text="">
      <CustomDialog
        open={dialog.open}
        title={dialog.title}
        onClose={handleCloseDialog}
      >
        {dialog.message}
      </CustomDialog>
      <MaterialReactTable table={table} />
      <AlertDialogBox
        openDialog={duplicateRequestDialog}
        title={"This Prompt already exists within this Project"}
        body={`You cannot accept the prompt because it has the same title & same prompt content as another prompt in this project`}
        confirmText={"Decline"}
        cancelText={"Cancel"}
        handleConfirm={onDeclineRequest}
        type={"info"}
        handleCancel={onDeclineRequestDialogClose}
      />
      <AlertDialogBox
        openDialog={declineRequestDialog}
        title={"Decline Request"}
        body={`Are you sure you want to decline the share request?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        handleConfirm={onDeclineRequest}
        type={"info"}
        handleCancel={onDeclineRequestDialogClose}
      />
      <AlertDialogBox
        openDialog={acceptRequestDialog}
        title={"Accept Request"}
        body={`Are you sure you want to accept the share request?`}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
        handleConfirm={onAcceptRequest}
        type={"info"}
        handleCancel={onAcceptRequestDialogClose}
      />
      <ConflictTitleDialog
        open={titleConflictExists}
        onClose={() => {
          setTitleConflictExists(false);
          setAcceptRequestDialog(false);
        }}
        refreshRequest={fetchRequests}
        title={"This Prompt Has Same Title as Another Prompt in this Project"}
        body={`Would you like to:`}
        promptRequestInfo={acceptRequestInfo}
        type={"info"}
        handleCancel={onAcceptRequestDialogClose}
      />

      <ConflictDescriptionDialog
        open={descriptionConflictExists}
        onClose={() => {
          setDescriptionConflictExists(false);
          setAcceptRequestDialog(false);
        }}
        title={"This Prompt is The Same Prompt Content as Another Prompt in this Project"}
        body={`Would you like to:`}
        promptRequestInfo={acceptRequestInfo}
        refreshRequest={fetchRequests}
        type={"info"}
        handleCancel={onAcceptRequestDialogClose}
      />

    </Loader>
  )
}

