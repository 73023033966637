import webApiService from "../utils/axiosClient";
import { responseConstants } from "../utils/constants";
const BASE_URL = "/requests"

// create an access request
export const createAccessRequest = async (accessRequestParams) => {
    try {
        const response = await webApiService.post(`${BASE_URL}/`, accessRequestParams);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,exists: false,success: true}
        } else if( status == responseConstants.ALREADY_EXIST) {
          return { data:data.data,exists:true,error: data?.message, success: true}
        } else {
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch Requests to a project
export const getRequestsByProjectId = async (projectId) => {
    try {
        const response = await webApiService.get(`${BASE_URL}/${projectId}`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Fetch All Requests to all projects
export const getAllRequests = async () => {
    try {
        const response = await webApiService.get(`${BASE_URL}/`);
        let status = response?.data?.code;
        let data = response?.data || {};
        if(status == responseConstants.OK) {
          return {data:data.data,success: true}
        } else {          
          return { success: false,error: data?.message}
        }
    } catch (error) {
        throw error;
    }
};

// Update a request
export const updateRequest = async (projectId, requestData) => {
    try {
        const response = await webApiService.put(`${BASE_URL}/${projectId}`, requestData);
        let status = response?.data?.code;
        let data = response?.data || {};        
        if(status == responseConstants.OK) {
            return {data:data.data,success: true}
          } else {          
            return { success: false,error: data?.message}
          }
    } catch (error) {
        throw error;
    }
};